import React, { createContext, useState, useContext, useCallback } from 'react';

const AuthRedirectContext = createContext(null);

export const AuthRedirectProvider = ({ children }) => {
    const [intendedDestination, setIntendedDestination] = useState(null);

    const saveIntendedDestination = useCallback((path) => {
        // Prevent saving login/signup pages as intended destinations
        const excludedPaths = ['/auth', '/signup', '/login'];
        if (!excludedPaths.includes(path)) {
            setIntendedDestination(path);
        }
    }, []);

    const clearIntendedDestination = useCallback(() => {
        setIntendedDestination(null);
    }, []);

    const getAndClearIntendedDestination = useCallback(() => {
        const destination = intendedDestination;
        clearIntendedDestination();
        return destination;
    }, [intendedDestination, clearIntendedDestination]);

    return (
        <AuthRedirectContext.Provider 
            value={{ 
                intendedDestination, 
                saveIntendedDestination, 
                clearIntendedDestination,
                getAndClearIntendedDestination
            }}
        >
            {children}
        </AuthRedirectContext.Provider>
    );
};

export const useAuthRedirect = () => {
    const context = useContext(AuthRedirectContext);
    if (!context) {
        throw new Error('useAuthRedirect must be used within an AuthRedirectProvider');
    }
    return context;
};
import React from 'react'
import './WishlistModal.css'
import CircularProgress from '../common/CircularProgress';

const WishlistModal = ({ showModal, onClose, onDelete, loading }) => {
    if (!showModal) {
        return null;
    }

    return (
        <div className="modal-backdrop">
            <div className="modal-container">
                <CircularProgress />

                {loading ? <CircularProgress /> : (
                    <>
                        <div className='modal-header'>
                            <h2 className='mx-auto font-bold'>Remove Item</h2>
                        </div>
                        <p>Are you sure you want to remove this item from your wishlist?</p>
                        <div className="flex items-center gap-6 justify-between modal-actions">
                            <button className="modal-cancel-btn w-full" onClick={onClose}>
                                Cancel
                            </button>
                            <button className="modal-delete-btn w-full" onClick={onDelete}>
                                Remove
                            </button>
                        </div>
                    </>
                )}

            </div>
        </div>
    )
}

export default WishlistModal;
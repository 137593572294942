import { Search, } from 'lucide-react';

const ChatItem = ({ chat, handleChatSelect }) => (
	<div
		key={chat?.id}
		onClick={() => handleChatSelect(chat)}
		className="flex items-center px-4 py-3 hover:bg-gray-50 cursor-pointer border-b border-gray-100"
	>
		<div className="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center">
			<span className="text-gray-600">{chat?.name[0]}</span>
		</div>
		<div className="ml-3 flex-1">
			<div className="flex justify-between items-center">
				<h3 className="text-sm font-medium">{chat?.name}</h3>
				<span className="text-xs text-gray-500">{chat?.date}</span>
			</div>
			<p className="text-sm text-gray-500 truncate">{chat?.lastMessage}</p>
		</div>
		{chat?.unread > 0 && (
			<div className="ml-2 bg-blue-600 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
				{chat?.unread}
			</div>
		)}
	</div>
);

export const ChatList = ({ chats, handleChatSelect, showChatRoom }) => {
	return (
		<div className={`w-full max-w-3xl mx-auto lg:w-96 bg-white ${showChatRoom ? 'hidden lg:block' : 'block'}`}>
			<div className="px-4 py-3">
				<div className="relative border border-kokorko-grey-400 rounded-lg">
					<input
						type="text"
						placeholder="Search store"
						className="w-full px-2 py-3 pl-8 rounded-lg bg-white text-sm outline-none focus:ring-0"
					/>
					<Search className="absolute left-2 top-2.5 h-5 w-5 text-gray-400" />
				</div>
			</div>

			<div className="overflow-y-auto">
				{chats.map((chat) => (
					<ChatItem
						chat={chat}
						key={chat?.id}
						handleChatSelect={handleChatSelect}
					/>
				))}
			</div>
		</div>
	)
};
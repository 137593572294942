import { X } from "lucide-react";
import React from "react";

export const SuccessModal = ({ children, setShowSuccess }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg px-6 py-10 md:py-20 max-w-md w-full mx-2 md:mx-4 relative">
        <button
          onClick={() => setShowSuccess(false)}
          className="absolute right-4 top-4"
        >
          <X className="size-6 text-black" />
        </button>
        <div className="text-center">
          <div className="size-16 bg-[#1BB66E] rounded-full flex items-center justify-center mx-auto mb-4">
            <svg
              className="size-8 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>

          {children}
        </div>
      </div>
    </div>
  );
};

import React from 'react'
import './ProductCard.css'
import { FiShoppingCart } from 'react-icons/fi';
import { HiOutlineHeart } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../lib/routes';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../store/slices/cartSlice';
import { addToWishlist } from '../../store/slices/wishlistSlice';
import { setCurrentStep } from '../../store/slices/checkoutSlice';


const findDiscountPercentage = (price, discount) => {
    if (price <= 0) return 0;
    return ((price - discount) / price) * 100;
};

const ProductCard = ({ 
    id, 
    name, 
    description, 
    image, 
    price, 
    rating, 
    review, 
    discount, 
    category, 
    seller,
    showName = true, 
    showDescription = true, 
    showPrice = true, 
    showRating = true, 
    showReview = true, 
    showDiscount = true, 
    descriptionStyle = '',
    showCategory = true, 
    showSeller = true, 
    showCartIcon = false, 
    showHeartIcon = false, 
    showDiscountPercentage = true, 
    showButton = true, 
    className = ''
}) => {

    const discountPercentage = findDiscountPercentage(price, discount);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleCardClick = () => {
        navigate(`${routes.product}/${id}`);
    };

    const handleAddToCart = (e) => {
        e.stopPropagation();
        const cartItem = {
            id,
            productName: name,
            price,
            productImage: image,
            quantity: 1,
            color: 'Default',
            store: seller
        };
        dispatch(addToCart(cartItem));
    }

    const handleAddToWishlist = (e) => {
        e.stopPropagation();
        const wishlistItem = {
            id,
            productName: name,
            price,
            productImage: image,
            seller
        };
        dispatch(addToWishlist(wishlistItem));
    };

    const handleBuyNow = (e) => {
        e.stopPropagation();
        const cartItem = {
            id,
            productName: name,
            price,
            productImage: image,
            quantity: 1,
            color: 'Default',
            store: seller
        };
        
        // Add to cart
        dispatch(addToCart(cartItem));
        
        // Reset to first step of checkout
        dispatch(setCurrentStep(1));

        // Navigate to checkout
        navigate(routes.checkout);
    };

    return (
        <div className={`product-card ${className}`} onClick={handleCardClick}>
            <div className='product-card-image'>
                {showHeartIcon && (
                    <div className='product-card-icon-heart' onClick={(e) => handleAddToWishlist(e)}>
                        <HiOutlineHeart size='1.3rem' />
                    </div>
                )}
                <img src={image} alt={name} className="product-image" />
            </div>
            {showCartIcon && (
                <div className='product-card-icon-cart' onClick={(e) => handleAddToCart(e)}>
                    <FiShoppingCart size='1.2rem' />
                </div>
            )}
            <div className='product-card-info'>
                {showName && <h3 className="product-name">{name}</h3>}
                {showDescription && <h3 className={`${descriptionStyle} product-description`}>{description}</h3>}
                {showDiscount && <p className="product-discount">GH₵ {discount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>}
                <div className='product-price-container'>
                    {showPrice && <p className="product-price">GH₵ {price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>}
                    {showDiscountPercentage && discountPercentage > 0 && (<p className="product-discount-percentage">-{discountPercentage.toFixed(0)}%</p>)}
                </div>
                {showReview && <p className="product-review">{review}</p>}
                {showRating && <p className="product-rating">{rating}/5</p>}
                {showCategory && <p className="product-category">{category}</p>}
                {showSeller && <p className="product-seller">{seller}</p>}
                {showButton && (
                    <div className='product-card-actions'>
                        <button className='product-card-button' onClick={handleBuyNow}>Buy Now</button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ProductCard

import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchData = (url, dataKey = null) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(url);
                let result = response?.data;

                if (dataKey) {
                    result = result?.[dataKey]; // Dynamically extract the specified key
                }

                if (Array.isArray(result)) {
                    setData(result);
                } else if (!dataKey && result) {
                    setData(result); // Handle cases where the whole response is the data
                } else {
                    setError('Unexpected data format');
                }
            } catch (err) {
                setError('Error loading data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [url, dataKey]);

    return { data, error, loading };
};

export default useFetchData;
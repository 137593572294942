import React, { useState, useEffect } from 'react';

export const Carousel = ({ data, autoplay = true, interval = 3000 }) => {
	const [currentIndex, setCurrentIndex] = useState(0);

	const handleNext = () => {
		setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
	};

	const handleIndicatorClick = (index) => {
		setCurrentIndex(index);
	};

	// Autoplay Effect
	useEffect(() => {
		if (!autoplay) return;

		const timer = setInterval(() => {
			handleNext();
		}, interval);

		return () => clearInterval(timer); // Cleanup the timer on unmount or props change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autoplay, interval, currentIndex, data.length]);

	return (
		<div className="relative flex flex-col w-full overflow-hidden gap-4">
			<div
				className="flex transition-all duration-300 ease-in-out transform"
				style={{ transform: `translateX(-${currentIndex * 100}%)` }}
			>
				{data.map((item, index) => (
					<div key={index} className="w-full flex-shrink-0">
						{item.comp}
					</div>
				))}
			</div>

			{/* Indicators */}
			<div className="flex items-center mx-auto space-x-2">
				{data.map((_, index) => (
					<button
						key={index}
						onClick={() => handleIndicatorClick(index)}
						className={`p-1.5 rounded-full ${currentIndex === index ? 'bg-[#44546F]' : 'bg-[#091E420F]'
							}`}
					/>
				))}
			</div>
		</div>
	);
};

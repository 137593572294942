import api from '../utils/api';

const wishlistService = {
    getWishlist: () => api.get('/api/wishlist'),

    addToWishlist: (productId) => api.post('/api/wishlist', { productId }),

    removeFromWishlist: (productId) => api.delete(`/api/wishlist/${productId}`),

    clearWishlist: () => api.delete('/api/wishlist')
};

export default wishlistService;
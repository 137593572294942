import React from 'react';
import PropTypes from 'prop-types';

const TableSection = ({
    title,
    columns,
    data = [],
    containerClassName = '',
    tableClassName = '',
    headerClassName = '',
    rowClassName = '',
    cellClassNames = [], // For custom classes in individual cells
    cellStyles = [], // For custom styles in individual cells
    tableStyles = {}, // Styles for the table itself
    rowStyles = {}, // Styles for rows
    cellStylesGlobal = {}, // Global styles for all cells
    titleStyles = {}, // Style for title
    renderCell = () => {}, // Function to render the content of each cell
    styles = {}, // Additional styles for the section container
}) => (
    <div className={`table-section-container ${containerClassName}`} style={styles}>
        <h3 style={titleStyles}>{title}</h3>
        <div className="table-container">
            <table className={tableClassName} style={tableStyles}>
                <thead>
                    <tr className={headerClassName}>
                        {columns.map((column, index) => (
                            <th key={index}>{column.header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 ? (
                        data.map((row, rowIndex) => (
                            <tr
                                key={rowIndex}
                                className={rowClassName}
                                style={rowStyles}
                            >
                                {columns.map((column, colIndex) => (
                                    <td
                                        key={colIndex}
                                        className={cellClassNames[colIndex]}
                                        style={{
                                            ...cellStylesGlobal, // Global cell styles
                                            ...(cellStyles[colIndex] || {}), // Custom cell styles passed in
                                        }}
                                    >
                                        {/* Customizable cell item rendering */}
                                        {renderCell(row, column, rowIndex, colIndex)}
                                    </td>
                                ))}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={columns.length} className="no-data">
                                No data available
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    </div>
);

TableSection.propTypes = {
    title: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string.isRequired,
            key: PropTypes.string.isRequired,
            className: PropTypes.string,
            style: PropTypes.object,
        })
    ).isRequired,
    data: PropTypes.arrayOf(PropTypes.object),
    containerClassName: PropTypes.string,
    tableClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    rowClassName: PropTypes.string,
    cellClassNames: PropTypes.arrayOf(PropTypes.string),
    cellStyles: PropTypes.arrayOf(PropTypes.object),
    tableStyles: PropTypes.object,
    rowStyles: PropTypes.object,
    cellStylesGlobal: PropTypes.object,
    titleStyles: PropTypes.object,
    renderCell: PropTypes.func.isRequired, // The custom render function for cells
    styles: PropTypes.object,
};

export default TableSection;

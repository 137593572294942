import React from 'react'
import './HeroTop.css'
import HeroCatsMenu from '../../components/heroCatsMenu/HeroCatsMenu'
import HeroNav from '../../components/heroNav/HeroNav'

const HeroTop = () => {
    return (
        <div className='md:mt-[1.9rem] mb-[1.2rem] heroTop'>
            <HeroCatsMenu />
            <HeroNav />
        </div>
    )
}

export default HeroTop

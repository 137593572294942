import React, { useEffect, useState } from 'react'
import './OrderDetails.css'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { OrderItem } from '../orders/OrderItem'
import { FaChevronLeft } from 'react-icons/fa6'

const OrderDetails = () => {
    const [order, setOrder] = useState({});
    const [loading, setLoading] = useState(false);
    const { orderId } = useParams();

    const API_URL = '/data.json';

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1); // Goes back to the previous page
    };

    // Fetch order details from API
    useEffect(() => {
        const fetchOrderDetails = async () => {
            setLoading(true);
            try {
                const response = await axios.get(API_URL);
                const data = await response.data;

                // filter the order by id
                const orderDetails = data.orders.filter((order) => order.id === parseInt(orderId, 10));
                setOrder(orderDetails[0]);

            } catch (error) {
                console.error('Error fetching order details:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchOrderDetails();
    }, [orderId]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!order) {
        return <p>Order not found.</p>;
    }

    return (
        <div className="py-5 md:p-5 order-details-container">
            <div className='flex items-center gap-2 mb-4'>
                <FaChevronLeft className='block md:hidden cursor-pointer' onClick={handleGoBack} />
                <h1 className='text-kokorko-black-900 font-bold text-lg'>Order Detail</h1>
            </div>
            <OrderItem isOrderDetails={true} handleOrderClick={() => ('')} order={order} />

            {/* Second Row: Delivery & Order Information */}
            <div className="flex flex-col md:flex-row gap-5">
                {/* Left Column: Delivery Information */}
                <div className='flex flex-col w-full'>
                    <h3 className='font-semibold'>Delivery Information</h3>
                    <div className="delivery-info">
                        <p>Estimated Delivery Date: <span className='font-semibold'>{order.estimatedDeliveryDate}</span></p>
                        <p>Delivery Method: <span className='font-semibold'>{order.deliveryMethod}</span></p>
                        <p>Buyer's Name: <span className='font-semibold'>{order.buyerName}</span></p>
                    </div>
                </div>

                {/* Right Column: Order Information */}
                <div className='flex flex-col w-full'>
                    <h3 className='font-semibold'>Order Information</h3>
                    <div className="order-info">
                        <div>Order Number: <span className='font-semibold'>{order.orderNumber}</span></div>
                        <div>Order Date: <span className='font-semibold'>{new Date(order.date).toLocaleDateString()}</span></div>
                        <div>Total Amount: <span className='font-semibold'>GH₵{order.total}</span></div>
                    </div>
                </div>
            </div>

            {/* Third Row: Payment Information */}
            <div className='flex flex-col w-full'>
                <h3 className='font-semibold'>Payment Information</h3>
                <div className="payment-info">
                    <p>Payment Method: <span className='font-semibold'>{order.paymentMethod}</span></p>

                    <div className="payment-details">
                        <div className='flex justify-between items-center'>Item Price:<span>GH₵{order.unitPrice}</span></div>
                        <div className='flex justify-between items-center'>Delivery Fee: <span className='flex justify-start'> GH₵{order.deliveryFee}</span></div>
                        <div className='flex justify-between items-center'>Total Amount: <span className='font-bold'>GH₵{order.total}</span></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderDetails

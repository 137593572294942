import React from 'react';
import { NotificationItem } from './NotificationItem';
import useFetchData from '../../hooks/useFetchData';
import { X } from 'lucide-react';
import { Link } from 'react-router-dom';

export const NotificationModal = ({ isOpen, onClose, isMobile, modalRef }) => {
	const { data: notifications } = useFetchData('/data.json', 'notifications');

	return (
		<div
			ref={modalRef}
			className={` fixed ${isMobile ? 'inset-0' : 'top-[90px] right-4'}  ${isMobile ? 'w-full h-full' : 'w-[400px] h-auto'}  bg-white  ${!isMobile && 'rounded-lg shadow-lg border border-gray-200'}  z-[100000]  transition-transform duration-300 ${isOpen ? 'translate-y-0' : 'translate-y-full md:translate-y-0 md:hidden'}`}
		>
			<div className="p-4 border-b border-gray-100 flex items-center">
				<h2 className="text-lg font-semibold">Notifications</h2>
				<button
					className="ml-auto text-gray-500"
					onClick={onClose}
				>
					<X className='size-5' />
				</button>
			</div>

			<div className={`${isMobile ? 'h-full' : 'max-h-[400px]'} overflow-y-auto hideScrollbar`}>
				{(isMobile ? notifications : notifications?.slice(0, 4))?.map((notification, index) => (
					<div className="px-4" key={index}>
						<NotificationItem {...notification} />
					</div>
				))}
			</div>

			{!isMobile && (
				<div className="flex items-center justify-center w-full p-4 border-t border-gray-100">
					<Link to='/notifications'
						onClick={onClose}
						className="w-full text-center bg-kokorko-blue-50 rounded-md py-1 text-blue-600 hover:underline"
					>
						View all
					</Link>
				</div>
			)}
		</div>
	);
};
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { 
    fetchCartItems, 
    updateItemQuantity, 
    removeFromCart, 
    syncGuestCartWithBackend,
    clearRateLimitError
} from '../../store/slices/cartSlice'
import './CartPage.css'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import TrashIcon from '../../assets/icons/trash-01.svg'
import HeroTop from '../../containers/heroTop/HeroTop'
import VbuyIcon from '../../assets/icons/vbuy-icon.png'
import BestSellers from '../../containers/bestSeller/BestSeller'
import { Link } from 'react-router-dom'
import { MdOutlineInfo } from "react-icons/md"
import { TbShoppingCartExclamation } from "react-icons/tb"
import { routes } from '../../lib/routes'

const CartPage = () => {
    const dispatch = useDispatch();
    const { 
        items, 
        total, 
        loading, 
        error, 
        rateLimitError 
    } = useSelector((state) => state.cart);
    const { isAuthenticated } = useSelector((state) => state.auth);

    // State to manage rate limit error toast
    const [showRateLimitToast, setShowRateLimitToast] = useState(false);

    useEffect(() => {
        // Fetch cart items
        dispatch(fetchCartItems());

        // if user logs in, sync guest cart
        if(isAuthenticated) {
            dispatch(syncGuestCartWithBackend());
        }
    }, [dispatch, isAuthenticated]);

    // Handle rate limit error
    useEffect(() => {
        if (rateLimitError) {
            setShowRateLimitToast(true);
            
            // Automatically hide toast after 3 seconds
            const timer = setTimeout(() => {
                setShowRateLimitToast(false);
                dispatch(clearRateLimitError());
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [rateLimitError, dispatch]);

    const handleUpdateQuantity = (cartItemId, quantity) => {
        if(quantity > 0) {
            dispatch(updateItemQuantity({ cartItemId, quantity }));
        }
    };

    const handleRemoveItem = (cartItemId) => {
        dispatch(removeFromCart(cartItemId));
    };

     // Render rate limit error toast
    const RateLimitToast = () => (
        <div className="fixed top-4 right-4 bg-red-500 text-white p-4 rounded-lg z-50">
            {rateLimitError}
        </div>
    );

     // Render empty cart message
    const EmptyCart = () => (
        <div className='flex flex-col justify-center items-center gap-3 mt-[5rem] mb-[3rem]'>
            <TbShoppingCartExclamation size='7rem' color='#E82F05'/>
            <h2 className='text-xl text-gray-600 mt-[2rem]'>Your cart is empty</h2> 
            <Link to={routes.products} className='py-4 px-6 rounded-md hover:bg-blue-700 bg-blue-800 text-white'>
                Continue Shopping
            </Link>
        </div>
    );

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className='cart-page-container'>
            {showRateLimitToast && <RateLimitToast />}
            <HeroTop />
            <Breadcrumb />
            {items.length === 0 ? (
                <EmptyCart />
            ) : (
                <div className='cart-page-details'>
                    <div className='cart-page-items'>
                        {
                            items.map(item => (
                                <div key={item.cartItemId} className='cart-page-item'>
                                    <div className='cart-page-item-img'>
                                        <img src={item.productImage} alt={item.productName} />
                                    </div>
                                    <div className='cart-page-item-info'>
                                        <div className='cart-page-itemInfo-left'>
                                            <div>
                                                <h1 className='cart-page-item-name'>{item.productName}</h1>
                                                <p className='cart-page-item-color'>{item.color}</p>
                                                <p className='cart-page-item-price'>GH₵{item.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                            </div>
                                            <div className='cart-page-item-quantity'>
                                                <button onClick={() => handleUpdateQuantity(item.cartItemId, item.quantity - 1)}>-</button>
                                                <p>{item.quantity}</p>
                                                <button onClick={() => handleUpdateQuantity(item.cartItemId, item.quantity + 1)}>+</button>
                                            </div>
                                        </div>
                                        <div className='cart-page-itemInfo-right'>
                                            <img 
                                                src={TrashIcon} 
                                                alt='trash-icon' 
                                                onClick={() => handleRemoveItem(item.cartItemId)} 
                                                className='cursor-pointer'
                                            />
                                            <p>Sub total: <span>GHS {(item.price * item.quantity).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                {items.length > 0 && (
                    <div className='cart-page-checkout-section'>
                        <div className='cart-page-total'>
                            <h2>Cart Summary</h2>
                            <div className='cart-page-delivery'>
                                <MdOutlineInfo size='1.5rem' color='#222222' />
                                <p>Delivery fee not included yet</p>
                            </div>
                            <div className='cartPage-products-total'>
                                <p className='cartPage-products-title'>Products</p>
                                <p className='cartPage-products-price'>GH₵{total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            </div>
                            <div className='cartPage-sub-total'>
                                <p className='cartPage-products-title'>Sub total</p>
                                <p className='cartPage-sub-price'>GH₵{total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            </div>
                            <Link to='/checkout' className='cart-page-checkout'>
                                Checkout
                            </Link>
                        </div>
                        <div className='cart-payment-info'>
                            <MdOutlineInfo size='1rem' />
                            <p>Secure payment via </p>
                            <img src={VbuyIcon} alt='Vbuy Icon' />
                        </div>
                    </div>
                )}
            </div>
            )}
            {items.length > 0 ? 
            (
                <div className='cart-page-similarProducts'>
                    <div className='product-similar-title'>
                        <h2>Similar Products</h2>
                        <p>View All</p>
                    </div>
                    <div className='product-similar-container'>
                        <BestSellers />
                    </div>
                </div>
            ) : (
                <div className='cart-page-similarProducts'>
                    <div className='product-similar-title'>
                        <h2>Top Selling Products</h2>
                        <p>View All</p>
                    </div>
                    <div className='product-similar-container'>
                        <BestSellers />
                    </div>
                </div>
            )}
        </div>
    )
}

export default CartPage;
import React, { useEffect, useState } from 'react';
import { ChatRoom } from './ChatRoom';
import { ChatList } from './ChatList';
import { FaChevronLeft } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

const MessagesPage = () => {
	const [chats, setChats] = useState([]);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [newMessage, setNewMessage] = useState("");
	const [selectedChat, setSelectedChat] = useState(null);
	const [showChatRoom, setShowChatRoom] = useState(false);

	const navigate = useNavigate();

	const handleGoBackToPrevPage = () => {
		navigate(-1); // Goes back to the previous page
	};

	// A helper function to handle selecting a chat from the chat list
	const handleChatSelect = (chat) => {
		setSelectedChat(chat);
		setShowChatRoom(true);
	};

	// A helper function to handle navigating back to the chat list
	const handleGoBack = () => {
		setShowChatRoom(false);
	};

	// A helper function to handle sending a new message in the selected chat
	const handleSendMessage = () => {
		// Prevent sending if there is no message or no chat is selected
		if (!newMessage.trim() || !selectedChat) return;

		// Create a new message object
		const newMsg = {
			id: selectedChat.messages.length + 1, // Incremental message ID
			message: newMessage,
			timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
			isSentByMe: true // Indicates the message is sent by the user
		};

		// Update the selected chat with the new message and update the last message
		setSelectedChat(prev => ({
			...prev,
			messages: [...prev.messages, newMsg],
			lastMessage: newMessage
		}));

		// Clear the input field after sending the message
		setNewMessage("");
	};


	useEffect(() => {
		const getProduct = async () => {
			setLoading(true);

			try {
				// const response = await axios.get(`${BASE_URL}api/products/`);
				const response = await fetch('/data.json');
				const data = await response.json();

				if (Array.isArray(data.products)) {
					setChats(data.messages);
				} else {
					setError('Unexpected data format');
				}
			} catch (error) {
				setError('Error loading data');
			}
			setLoading(false);
		};

		getProduct();
	}, []);

	if (loading) {
		return <div>Loading...</div>
	}

	if (!chats.length) {
		return (
			<div>
				{error &&
					<p>Sorry, an error occured loading data. Please reload page.</p>
				}
			</div>
		)
	}

	return (
		<div className="w-full bg-white min-h-screen">
			<div className={` ${showChatRoom ? "hidden" : "flex"} items-center md:px-4 py-3 border-b border-gray-100 mt-5 md:mt-0 gap-2`}>
				<FaChevronLeft
					className='block cursor-pointer'
					onClick={handleGoBackToPrevPage}
				/>

				<h1 className={` md:flex text-xl font-bold`}>Messages</h1>
			</div>
			<div className="relative flex h-full">
				<ChatList
					chats={chats}
					showChatRoom={showChatRoom}
					handleChatSelect={handleChatSelect}
				/>
				<ChatRoom
					newMessage={newMessage}
					handleGoBack={handleGoBack}
					selectedChat={selectedChat}
					showChatRoom={showChatRoom}
					setNewMessage={setNewMessage}
					setSelectedChat={setSelectedChat}
					handleSendMessage={handleSendMessage}
				/>
			</div>
		</div>
	);
};

export default MessagesPage;
// src/utils/rateLimiter.js
const rateLimitStore = {};

export const getRateLimitKey = (action) => {
    return `${action}_${Date.now()}`;
};

export const checkRateLimit = (key, limit = 10, window = 60000) => {
    const now = Date.now();
    
    // Clean up old entries
    Object.keys(rateLimitStore).forEach(k => {
        if (now - rateLimitStore[k].timestamp > window) {
            delete rateLimitStore[k];
        }
    });

    // Check if limit is exceeded
    const actionCounts = Object.values(rateLimitStore)
        .filter(entry => entry.action === key.split('_')[0])
        .length;

    if (actionCounts >= limit) {
        return false;
    }

    // Add new entry
    rateLimitStore[key] = {
        action: key.split('_')[0],
        timestamp: now
    };

    return true;
};
import React, { useState, useEffect } from 'react';
import { ChevronRight, ChevronDown, X, ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

const CategoriesModal = ({ toggleModal, isModalOpen, isFilter = false, isAccordion = false }) => {
	const [navigationStack, setNavigationStack] = useState([]);
	const [currentView, setCurrentView] = useState('main');
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [selectedSubcategory, setSelectedSubcategory] = useState(null);
	const [categoriesData, setCategoriesData] = useState([]);
	const [isMobile, setIsMobile] = useState(false);
	const [expandedCategories, setExpandedCategories] = useState({});
	const [expandedSubcategories, setExpandedSubcategories] = useState({});

	// Fetch data from data.json
	useEffect(() => {
		const fetchCategoriesData = async () => {
			try {
				const response = await fetch('/data.json');
				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}
				const data = await response.json();
				setCategoriesData(data.productCategories || []);
			} catch (error) {
				console.error('Error fetching categories data:', error);
			}
		};

		fetchCategoriesData();

		// Set up resize listener for mobile check
		const checkIsMobile = () => {
			setIsMobile(window.innerWidth <= 768);
		};
		checkIsMobile();
		window.addEventListener('resize', checkIsMobile);

		return () => window.removeEventListener('resize', checkIsMobile);
	}, []);

	// Function to get the title of the header based on the current view
	const getHeaderTitle = () => {
		if (currentView === 'main') return 'Categories';
		if (currentView === 'subcategories') return selectedCategory?.name;
		if (currentView === 'children') return selectedSubcategory?.name;
		return 'Categories';
	};

	// Handle click event on a category
	const handleCategoryClick = (category) => {
		if (isAccordion && !isMobile) {
			setExpandedCategories((prev) => ({
				...prev,
				[category.id]: !prev[category.id]
			}));
		} else {
			if (isMobile) {
				setNavigationStack([...navigationStack, { view: currentView, title: getHeaderTitle() }]);
				setCurrentView('subcategories');
				setSelectedCategory(category);
			} else {
				setSelectedCategory(category);
			}
		}
	};

	// Handle click event on a subcategory
	const handleSubcategoryClick = (subcategory) => {
		if (isAccordion && !isMobile) {
			setExpandedSubcategories((prev) => ({
				...prev,
				[subcategory.id]: !prev[subcategory.id]
			}));
		} else if (isMobile) {
			setNavigationStack([...navigationStack, { view: currentView, title: getHeaderTitle() }]);
			setCurrentView('children');
			setSelectedSubcategory(subcategory);
		}
	};

	// Handle back navigation in mobile view
	const handleBack = () => {
		const previousState = navigationStack.pop();
		setNavigationStack([...navigationStack]);
		setCurrentView(previousState.view);
		if (previousState.view === 'main') {
			setSelectedSubcategory(null);
		}
	};

	// Render children items (nested level)
	const renderChildren = (children) => {
		return children.map((group, index) => (
			<div key={index} className="flex items-center mb-4 text-kokorko-black-300 w-full">
				<p className="text-sm font-medium text-kokorko-black-400 md:text-kokorko-black-300">{group.label}:</p>
				<div className="flex flex-wrap gap-2 ml-1">
					{group.items.map((item, itemIndex) => (
						<Link
							to={`/products/${selectedCategory?.id}/${selectedSubcategory?.id}/${item.toLowerCase()}`}
							key={itemIndex}
							className="text-sm text-kokorko-black-300 hover:text-blue-600"
						>
							{item}{itemIndex < group.items.length - 1 ? ',' : ''}
						</Link>
					))}
				</div>
			</div>
		));
	};

	return (
		<div>
			{isModalOpen && (
				<div className={`${isFilter ? "relative h-fit" : "fixed h-[calc(100vh-8rem)] top-44 left-0"} w-full  z-50`}>
					<div className={`w-full ${isFilter ? "w-full" : "md:w-4/5"} mx-auto bg-[#F4F6FA] h-full`}>
						{!isFilter && (<div className="px-4 py-3 border-b flex items-center justify-between bg-[#F4F6FA]">
							<div className="flex items-center">
								{navigationStack.length > 0 && (
									<button
										onClick={handleBack}
										className="p-2 hover:bg-gray-100 rounded-full mr-2"
									>
										<ArrowLeft size={20} />
									</button>
								)}
								<h2 className="text-lg font-semibold">{getHeaderTitle()}</h2>
							</div>
							<button
								onClick={toggleModal}
								className="p-2 hover:bg-gray-100 rounded-full"
							>
								<X size={20} />
							</button>
						</div>
						)}

						<div className="flex h-full">
							<div className="hidden md:flex w-full">
								<div className={`${isFilter ? "w-full h-fit" : "w-1/4 h-[calc(100vh-15rem)]"} border-r overflow-y-auto hideScrollbar bg-gray-50`}>
									{categoriesData.main?.map((category) => (
										<div key={category.id}>
											<button
												onClick={() => handleCategoryClick(category)}
												className={`w-full px-4 py-3 text-left flex items-center justify-between hover:bg-gray-100 ${selectedCategory?.id === category.id ? 'bg-[#F4F6FA] border-l-4 border-blue-600' : ''}`}
											>
												<span>{category.name}</span>
												{category.subcategories?.length > 0 && (
													isAccordion && !isMobile ? (
														expandedCategories[category.id] ? <ChevronDown size={20} /> : <ChevronRight size={20} />
													) : (
														<ChevronRight size={20} />
													)
												)}
											</button>
											{isAccordion && expandedCategories[category.id] && (
												<div className="pl-4">
													{category.subcategories?.map((subcategory) => (
														<div key={subcategory.id}>
															<button
																onClick={() => handleSubcategoryClick(subcategory)}
																className="w-full px-4 py-3 text-left flex items-center justify-between hover:bg-gray-100"
															>
																<span>{subcategory.name}</span>
																{expandedSubcategories[subcategory.id] ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
															</button>
															{expandedSubcategories[subcategory.id] && (
																<div className="pl-4">
																	{renderChildren(subcategory.children)}
																</div>
															)}
														</div>
													))}
												</div>
											)}
										</div>
									))}
								</div>

								{selectedCategory && selectedCategory.subcategories && !isAccordion && (
									<div className="grid grid-cols-2 gap-6 flex-1 p-6 overflow-y-auto hideScrollbar h-[calc(100vh-15rem)]">
										{selectedCategory.subcategories.map((subcategory) => (
											<div key={subcategory.id} className="mb-8">
												<h3 className="text-lg font-semibold mb-4 border-b pb-2">{subcategory.name}</h3>
												{renderChildren(subcategory.children)}
											</div>
										))}
									</div>
								)}
							</div>

							<div className="md:hidden w-full overflow-y-auto hideScrollbar h-[calc(100vh-15rem)]">
								{currentView === 'main' && (
									categoriesData.main?.map((category) => (
										<button
											key={category.id}
											onClick={() => handleCategoryClick(category)}
											className="w-full px-4 py-3 text-left flex items-center justify-between hover:bg-gray-100"
										>
											<span>{category.name}</span>
											{category.subcategories?.length > 0 && <ChevronRight size={20} />}
										</button>
									))
								)}

								{currentView === 'subcategories' && selectedCategory && (
									selectedCategory.subcategories.map((subcategory) => (
										<button
											key={subcategory.id}
											onClick={() => handleSubcategoryClick(subcategory)}
											className="w-full px-4 py-3 text-left flex items-center justify-between hover:bg-gray-100"
										>
											<span>{subcategory.name}</span>
											<ChevronRight size={20} />
										</button>
									))
								)}

								{currentView === 'children' && selectedSubcategory && (
									<div className="p-4">
										{renderChildren(selectedSubcategory.children)}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default CategoriesModal;
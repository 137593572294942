import React, { useEffect} from 'react';
import './ProfileOverview.css';
import { FiUser } from 'react-icons/fi';
import EmailIcon from '../../assets/icons/email.svg';
import PhoneIcon from '../../assets/icons/phone.svg';
import LocationIcon from '../../assets/icons/location_on.svg';
import ProfilePicture from '../../assets/icons/Frame 88.png';
import { GrCart } from 'react-icons/gr';
import { HiOutlineHeart } from 'react-icons/hi';
import { PiBellBold } from 'react-icons/pi';
import { HiOutlineBuildingStorefront } from 'react-icons/hi2';
import TableSection from '../Table/Table';
import { Divider } from '../common/Divider';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserProfile } from '../../store/slices/userProfileSlice';

const ProfileOverview = () => {
    const dispatch = useDispatch();
    const { userInfo, loading, error, userRole } = useSelector((state) => state.userProfile);
    const { user, isAuthenticated } = useSelector((state) => state.auth);

   // Fetch profile if not already loaded and role is buyer
    useEffect(() => {
        if (isAuthenticated && userRole === 'buyer' && !userInfo) {
            dispatch(fetchUserProfile());
        }
    }, [dispatch, isAuthenticated, userRole, userInfo]);

    // Debug logging
    useEffect(() => {
        console.group('ProfileOverview Debug');
        console.log('User Info:*************************', userInfo);
        console.log('User Role:', userRole);
        console.log('Current User:', user);
        console.log('Loading:', loading);
        console.log('Error:', error);
        console.groupEnd();
    }, [userInfo, user, loading, error, userRole]);

    if (loading) return <div>Loading profile...</div>;
    // if (error) return <div>Error loading profile: {error}</div>;
    // if (!userInfo) return <div>No profile data available</div>;

    // return (
    //     <div className="profile-overview">
    //         <h2>Buyer Profile Overview</h2>
    //         <div className="profile-details">
    //             <div className="profile-avatar">
    //                 {userInfo.avatar ? (
    //                     <img 
    //                         src={userInfo.avatar} 
    //                         alt={`${userInfo.first_name}'s avatar`} 
    //                         className="avatar-image"
    //                     />
    //                 ) : (
    //                     <div className="avatar-placeholder">
    //                         {userInfo.first_name[0]}
    //                     </div>
    //                 )}
    //             </div>
    //             <div className="profile-info">
    //                 <h3>{userInfo.first_name} {userInfo.last_name}</h3>
    //                 <p><strong>Email:</strong> {userInfo.email}</p>
    //                 <p><strong>Phone:</strong> {userInfo.phone || 'Not provided'}</p>
    //                 <p><strong>Address:</strong> {userInfo.address || 'Not provided'}</p>
    //             </div>
    //         </div>
    //     </div>
    // );

    return (
        <div className="md:px-4 profile-overview-container">
            <div className="hidden md:flex profile-overview-title">
                <h2>Overview</h2>
            </div>
            {/* Header section with profile picture and user info */}
            <div className="flex flex-col md:flex-row items-center profile-overview-header">
                <div className='w-full md:w-fit flex items-center justify-between'>
                    <div className="w-[100px] md:w-[120px] profile-picture">
                        <img src={ProfilePicture} alt="profile-picture" />
                    </div>
                    <button className="flex md:hidden edit-profile-button">Edit Profile</button>
                </div>
                <div className="relative flex justify-between gap-6 md:gap-3 w-full mt-6 md:mt-0 md:w-4/5 md:ml-10">
                    {/* Left section */}
                    <div className="profile-info-left">
                        <ProfileInfoItem icon={<FiUser />} label="John Doe" />
                        <ProfileInfoItem icon={<img src={EmailIcon} alt="email-icon" />} label="john.doe@gmail.com" />
                    </div>
                    {/* Right section */}
                    <div className="profile-info-right">
                        <ProfileInfoItem icon={<img src={PhoneIcon} alt="phone-icon" />} label="+1 123 456 7890" />
                        <ProfileInfoItem icon={<img src={LocationIcon} alt="location-icon" />} label="123 Main St, New York, NY" />
                    </div>
                    <button className="hidden md:flex edit-profile-button">Edit Profile</button>
                </div>
            </div>

            <Divider otherStyles='hidden md:block' />

            {/* Quick action buttons */}
            <div className="hidden md:flex profile-overview-buttons">
                <QuickActionButton icon={<GrCart className='size-6 text-kokorko-blue-600' />} label="Cart" path='/cart' />
                <QuickActionButton icon={<HiOutlineHeart className='size-6 text-kokorko-blue-600' />} label="Wishlist" path='/profile/wishlist' />
                <QuickActionButton icon={<PiBellBold className='size-6 text-kokorko-blue-600' />} label="Notifications" path='/notifications' />
                <QuickActionButton icon={<HiOutlineBuildingStorefront className='size-6 text-kokorko-blue-600' />} label="Following (12)" path='/following' />
            </div>

            <Divider otherStyles='hidden md:block' />

            {/* Recent orders section */}
            <RecentOrders />

            <Divider otherStyles='hidden md:block' />

            {/* Coupons section */}
            <div className="hidden md:flex coupons-container">
                <h3>Coupons</h3>
                <div className="coupons">
                    <p>
                        <span>Oops!</span>
                        <span>You have no coupons</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

// profile information items
const ProfileInfoItem = ({ icon, label }) => (
    <div className="flex items-center gap-2">
        {icon}
        <h3 className='text-ellipsis line-clamp-1'>{label}</h3>
    </div>
);

// quick action buttons
const QuickActionButton = ({ icon, label, path }) => (
    <Link to={path} className="link-button">
        {icon}
        <p>{label}</p>
    </Link>
);

const RecentOrders = () => {
    const columns = [
        { header: 'Name', key: 'name' },
        { header: 'Order ID', key: 'orderId' },
        { header: 'Amount', key: 'amount' },
        { header: 'Status', key: 'status' },
    ];

    const data = [
        { name: 'Product A', orderId: '#12345', amount: 'GHS 100', status: 'Delivered' },
        { name: 'Product B', orderId: '#12346', amount: 'GHS 200', status: 'Delivered' },
        { name: 'Product C', orderId: '#12347', amount: 'GHS 300', status: 'In transit' },
    ]

    return (
        <div className='hidden md:flex w-full'>
            <TableSection
                title="Recent Orders"
                columns={columns}
                data={data}
                headerClassName='text-[#B52504] bg-blue-500'
                styles={{ backgroundColor: '', width: '100%' }} // Section container styles
                tableStyles={{}} // Table specific styles
                rowStyles={{ backgroundColor: '', borderBottom: '1px solid #EAECF0' }} // Row styles
                cellStylesGlobal={{ padding: '10px', border: 'none', }} // Global cell styles
                renderCell={(row, column,) => {
                    if (column.key === 'amount') {
                        return <span>{row[column.key]}</span>;
                    }
                    if (column.key === 'status') {
                        return <span className='bg-global-green-800 py-1 px-2 w-full text-white rounded-xl'>{row[column.key]}</span>;
                    }
                    return row[column.key]; // Default rendering
                }}
                titleStyles={{ fontSize: '1.5em', color: '#000000' }} // Title styles
            />
        </div>
    );
};

export default ProfileOverview;
import api from '../utils/api';


const authService = {
    login: async (credentials) => {
        try {
            console.log('Login Attempt:', credentials);
            const response = await api.post('/api/login/', credentials);
            
            console.log('Full Login Response:', response);
            console.log('Login Response Data:', response.data);
            
            // token and user extraction
            const { access, refresh, profile, role } = response.data;
            
            if (!access || !refresh) {
                throw new Error('Access or refresh token missing');
            }

            // Ensure user object exists
            if (!profile) {
                throw new Error('User data not found in response');
            }

            // Fallback to role from credentials if not in response
            const userRole = role || credentials.role || 'user';

            // Store tokens and user role
            localStorage.setItem('accessToken', access);
            localStorage.setItem('refreshToken', refresh);
            localStorage.setItem('userRole', userRole);
            
            console.log('Tokens and user role stored successfully');

            return {
                access,
                refresh,
                user: {
                    ...profile,
                    role: userRole
                }
            };
        } catch (error) {
            console.error('Login Service Error:', {
            message: error.message,
            response: error.response?.data,
            status: error.response?.status
            });
            throw new Error(
                error.response?.data?.detail || 
                error.message || 
                'Login failed'
            );
        }
    },

    socialLogin: async (socialCredentials) => {
        const response = await api.post('/api/social-login/', socialCredentials);
        
        if (response.data.access && response.data.refresh) {
            localStorage.setItem('accessToken', response.data.access);
            localStorage.setItem('refreshToken', response.data.refresh);
            localStorage.setItem('userRole', response.data.user.role);
        }
    
        return response.data;
    },

    signup: async (userData) => {
        try {
            console.log('Signup Attempt:', userData);
            const response = await api.post('/api/signup/', userData);
            console.log('Signup Response:', response.data);
            
            return response.data;
        } catch (error) {
            console.error('Signup Service Error:', {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status
            });
            throw error;
        }
    },

    logout: () => {
        // Optional: Call backend logout
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    },

    getCurrentUser: async (role) => {
        try {
            let response;
            console.group('Get Current User Debug');
            console.log('Attempting to fetch profile with role:', role);

            //validate role
            if (!role || !['buyer', 'seller'].includes(role)) {
                throw new Error('Invalid user role');
            }

            // Select appropriate endpoint based on role
            if (role === 'buyer') {
                response = await api.get('/api/buyer-profile/');
            } else if (role === 'seller') {
                response = await api.get('/api/seller/profile/');
            } 

            console.log('Profile Response:', response.data);
            console.groupEnd();

            // Ensure response has data
        if (!response || !response.data) {
            throw new Error('No profile data received');
        }

            return {
                ...response.data,
                role: role
            };
        } catch (error) {
            console.error('Get Current User Error:', {
                message: error.message,
                response: error.response?.data
            });
            throw error;
        }
    },
};

export default authService;
import React from 'react';
import { StoreCard } from '../../containers/stores/StoreCard';

// Helper function to fill the stores array
const generateStores = (baseName, isFollowing) => {
	const images = [
		require("../../assets/stores-images/store-img-sneakers.jpeg"),
		require("../../assets/stores-images/store-img-watch.jpeg"),
		require("../../assets/stores-images/store-img-shoe.jpeg"),
	];
	return Array.from({ length: 12 }, (_, i) => ({
		name: `${baseName} ${i + 1}`,
		images,
		isFollowing,
	}));
};

const FollowingStores = () => {
	// Generate stores data
	const followingStores = generateStores("Following Store", true);
	const recommendedStores = generateStores("Recommended Store", false);

	return (
		<div className="flex flex-col w-[93%] md:w-4/5 mx-auto mt-24">
			{/* Following Stores Section */}
			<div className="mb-12">
				<h2 className="text-lg font-semibold mb-6">Following Stores</h2>
				<div className="grid grid-cols-2 md:grid-cols-4 gap-4">
					{followingStores.map((store, index) => (
						<StoreCard
							key={`following-${store.name}-${index}`}
							store={store}
						/>
					))}
				</div>
			</div>

			{/* Recommended Stores Section */}
			<div className="mb-12">
				<h2 className="text-lg font-semibold mb-6">Recommended Stores to Follow</h2>
				<div className="grid grid-cols-2 md:grid-cols-4 gap-4">
					{recommendedStores.map((store, index) => (
						<StoreCard
							key={`recommended-${store.name}-${index}`}
							store={store}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default FollowingStores;
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Analytics from "./components/analytics/Analytics";
import CatMenuModal from "./components/catMenuModal/CatMenuModal";
import Customers from "./components/customers/Customers";
import Footer from "./components/footer/Footer";
import Help from "./components/help/Help";
// import Messages from './components/messages/Messages';
import OrderDetails from "./components/orderDetails/OrderDetails";
import Orders from "./components/orders/Orders";
import ProdMgmt from "./components/prodManagement/ProdMgmt";
import ProfileOverview from "./components/profileOverview/ProfileOverview";
import Reviews from "./components/reviews/Reviews";
import SellerOnboarding from "./components/SellerOnboarding/OnboardingSeller";
import SellerOrderDetails from "./components/sellerOrderDetails/SellerOrderDetails";
import SellerOrders from "./components/sellerOrders/SellerOrders";
import SellerOverview from "./components/sellerOverview/SellerOverview";
import Settings from "./components/settings/Settings";
import Suggestions from "./components/suggestions/Suggestions";
import Wallet from "./components/wallet/Wallet";
import Header from "./containers/header/Header";
import TermsAndConditions from "./pages/(info)/TermsAndConditions";
import AuthPage from "./pages/AuthPage/AuthPage";
import BestDealsPage from "./pages/bestDealsPage/BestDealsPage";
import BestSellersPage from "./pages/bestSellersPage/BestSellersPage";
import CartPage from "./pages/cartPage/CartPage";
import CategoryPage from "./pages/categoryPage/CategoryPage";
import CheckoutPage from "./pages/checkoutPage/CheckoutPage";
import DiscoveriesPage from "./pages/discoveriesPage/DiscoveriesPage";
import Feed from "./pages/feedPage/Feed";
import FlashDealsPage from "./pages/flashDealsPage/FlashDealsPage";
import Main from "./pages/Main";
import ProductDetails from "./pages/productDetailsPage/ProductDetails";
import Products from "./pages/products/Products";
import ProfilePage from "./pages/profilePage/ProfilePage";
import StoresPage from "./pages/storesPage/StoresPage";
import TopPicksPage from "./pages/topPicksPage/TopPicksPage";
import Wishlists from "./pages/wishlist/Wishlists";
import ProtectedRoute from "./lib/ProtectedRoute";
import { AuthRedirectProvider } from "./context/AuthRedirectContext";
import MessagesPage from "./components/messages/MessagesPage";
import Notifications from "./components/notifications/Notifications";
import FollowingStores from "./pages/followingStores/FollowingStores";
import UnauthorizedPage from "./pages/UnauthorizedPage";

function App() {
  return (
    <BrowserRouter>
      <AuthRedirectProvider>
        <AppContent />
      </AuthRedirectProvider>
    </BrowserRouter>
  );
}

/**
 * This function is responsible for rendering the main content of the application, including the Header, Footer, and various routes.
 * It conditionally renders the Header and Footer based on the current route.
 *
 * @returns {JSX.Element} - The main content of the application.
 */
function AppContent() {
  const location = useLocation();

  // Define routes where you don't want to show Header and Footer
  const hideHeaderFooterRoutes = [
    "/auth",
    "/profile/seller-overview",
    "/profile/product-mgmt",
    "/profile/customers",
    "/profile/wallet",
    "/profile/analytics",
    "/profile/seller-orders",
    "/profile/seller-orders/:orderId",
    "/profile/s/onboarding",
  ];

  return (
    <div className="app">
      {/* Conditionally render Header */}
      {!hideHeaderFooterRoutes.includes(location.pathname) && <Header />}
      <Routes>
        <Route index path="/" element={<Main />} />
        <Route path="/discoveries" element={<DiscoveriesPage />} />
        <Route
          path="/my-feed"
          element={
            <ProtectedRoute requiredRoles={["buyer"]}>
              <Feed />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="/profile/overview" />} />
          <Route
            path="/profile/overview"
            element={
              <ProtectedRoute requiredRoles={["buyer"]}>
                <ProfileOverview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile/seller-overview"
            element={
              <ProtectedRoute requiredRoles={["seller"]}>
                <SellerOverview />
              </ProtectedRoute>
            }
          />
          <Route path="/profile/s/onboarding" element={<SellerOnboarding />} />
          <Route path="/profile/orders" element={<Orders />} />
          <Route path="/profile/orders/:orderId" element={<OrderDetails />} />
          <Route path="/profile/messages" element={<MessagesPage />} />
          <Route path="/profile/wishlist" element={<Wishlists />} />
          <Route path="/profile/reviews" element={<Reviews />} />
          <Route path="/profile/help" element={<Help />} />
          <Route path="/profile/suggestions" element={<Suggestions />} />
          <Route path="/profile/settings" element={<Settings />} />
          <Route path="/profile/product-mgmt" element={<ProdMgmt />} />
          <Route path="/profile/customers" element={<Customers />} />
          <Route path="/profile/wallet" element={<Wallet />} />
          <Route path="/profile/analytics" element={<Analytics />} />
          <Route path="/profile/seller-orders" element={<SellerOrders />} />
          <Route
            path="/profile/seller-orders/:orderId"
            element={<SellerOrderDetails />}
          />
        </Route>


        <Route path="/notifications" element={<Notifications />} />
        <Route path="/best-deals-all" element={<BestDealsPage />} />
        <Route path="/stores" element={<StoresPage />} />
        <Route path="/following" element={<FollowingStores />} />
        <Route path="/top-picks" element={<TopPicksPage />} />
        <Route path="/best-sellers" element={<BestSellersPage />} />
        <Route path="/flash-deals" element={<FlashDealsPage />} />
        <Route path="/categories" element={<CatMenuModal />} />
        <Route path="/products" element={<Products />} />
        <Route path="/product/:id" element={<ProductDetails />} />
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route
          path="/checkout"
          element={
            <ProtectedRoute requiredRoles={["buyer"]}>
              <CheckoutPage />
            </ProtectedRoute>
          }
        />
        <Route path="/wishlist" element={<Wishlists />} />
        <Route
          path="/category/:category"
          element={<CategoryPage products={Products} />}
        />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="*" element={<h1>Not Found</h1>} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
      </Routes>
      {/* Conditionally render Footer */}
      {!hideHeaderFooterRoutes.includes(location.pathname) && <Footer />}
    </div>
  );
}

export default App;

import { createSlice } from '@reduxjs/toolkit';

const heroNavSlice = createSlice({
    name: 'heroNav',
    initialState: {
        selectedButton: 'home'
    },
    reducers: {
        setSelectedButton: (state, action) => {
            state.selectedButton = action.payload;
        }
    }
});

export const { setSelectedButton } = heroNavSlice.actions;
export default heroNavSlice.reducer;
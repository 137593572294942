import { ArrowLeft, SendHorizonal } from 'lucide-react';
import { GrAttachment } from 'react-icons/gr';

const MessageCard = ({ message }) => (
	<div
		key={message.id}
		className={`flex ${message.isSentByMe ? 'justify-end' : 'justify-start'}`}
	>
		<div className={`flex flex-col max-w-[70%] `}>
			<p className={`whitespace-pre-line text-sm px-4 py-2 ${message.isSentByMe ? 'bg-kokorko-blue-50 text-kokorko-black-400 rounded-l-full rounded-t-full' : 'bg-kokorko-blue-primary-500 text-white rounded-r-full rounded-t-full'}`}>{message.message}</p>
			<span className={`flex text-xs text-kokorko-black-300 mt-1 opacity-70 w-full ${message.isSentByMe ? 'justify-end' : 'justify-start'}`}>
				{message.timestamp}
			</span>
		</div>
	</div>
);

export const ChatRoom = ({ handleGoBack, handleSendMessage, selectedChat, showChatRoom, newMessage, setNewMessage }) => {
	const attachFile = () => { };

	return (
		<div className={`w-full md:max-w-5xl mx-auto md:border border-[#E9E9E9] rounded-xl bg-white lg:flex-1 ${showChatRoom ? 'block mt-6 md:mt-0' : 'hidden lg:block'}`}>
			{selectedChat ? (
				<>
					<div className="border-b border-gray-100 py-2 md:p-4">
						<div className="flex items-center">
							<button
								onClick={handleGoBack}
								className="flex lg:hidden mr-3 hover:bg-gray-100 p-1 rounded-full"
							>
								<ArrowLeft className="h-6 w-6" />
							</button>
							<div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center mr-3">
								<span className="text-gray-600 text-xs">{selectedChat?.name[0]}</span>
							</div>
							<h2 className="text-sm font-medium">{selectedChat?.name}</h2>
						</div>
					</div>

					<div className="flex-1 overflow-y-auto p-4 space-y-4 h-full md:h-[calc(100vh-140px)]">
						<div className="text-xs text-gray-500 text-center mb-4">Today</div>
						{selectedChat.messages.map((message) => (
							<MessageCard
								key={message?.id}
								message={message}
							/>
						))}
					</div>

					<div className="relative border-t border-gray-100 p-4 bg-white">
						<div className="fixed md:absolute bottom-4 flex items-center w-[87%] md:w-11/12 space-x-2">
							<div className="flex items-center justify-between flex-1 relative gap-2">
								<button
									onClick={attachFile}
									className="absolute left-2 top-1/2 transform -translate-y-1/2 p-1  text-kokorko-blue-900 rounded-full"
								>
									<GrAttachment className="h-4 w-4" />
								</button>
								<input
									type="text"
									value={newMessage}
									onChange={(e) => setNewMessage(e.target.value)}
									placeholder="Type a message"
									className="w-full pl-10 pr-4 py-3 bg-[#EBEAEA] rounded-full text-sm outline-none focus:ring-0"
								/>
								<button
									onClick={handleSendMessage}
									className=" p-2 bg-kokorko-blue-primary-500 text-white rounded-full"
								>
									<SendHorizonal className="h-4 w-4" />
								</button>
							</div>
						</div>
					</div>
				</>
			) : (
				<div className="hidden lg:flex h-full items-center justify-center text-gray-500 font-semibold">
					Select a chat to start messaging
				</div>
			)}
		</div>
	)
};
import React from 'react'
import './ReviewsModal.css'
import { X } from 'lucide-react'

const ReviewsModal = ({ onClose, children, isSubmitted }) => {
    return (
        <div className="fixed top-0 left-0 bottom-0 w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center z-[1000]">
            <div className='h-fit bg-white p-5 text-center rounded-xl w-11/12 md:w-4/5 lg:w-3/5 xl:w-1/2'>
                <div className='review-modal-header'>
                    {!isSubmitted && <h3>Rate Product</h3>}
                    <button className="flex ml-auto justify-end items-end text-kokorko-black-900" onClick={onClose}>
                        <X className='size-5' />
                    </button>
                </div>
                {children}
            </div>
        </div>
    )
}

export default ReviewsModal

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FaRegUser } from "react-icons/fa6";
import { IoBusinessSharp } from "react-icons/io5";
import { useNavigate, useLocation } from "react-router-dom";

const interests = [
	"Electronics & Gadgets",
	"Furniture",
	"Clothing & Fashion",
	"Beauty & Personal Care",
	"Health & Wellness",
	"Food & Beverages",
	"Sports & Outdoors",
	"Arts & Crafts",
	"Books & Stationery",
	"Toys & Games",
	"Automotive",
	"Babies & Kids",
];

export const Onboarding = ({ isBuyer }) => {
	const navigate = useNavigate();
    const location = useLocation();
    const redirectTo = location.state?.redirectTo;

    const handleOnboardingComplete = () => {
        // If there's a saved redirect destination, go there
        if (redirectTo) {
            navigate(redirectTo);
        } else {
            // Default redirect based on user type
            navigate(isBuyer ? '/profile' : '/profile/seller-overview');
        }
    };

    return (
        <div className="mt-20">
            {isBuyer ? (
                <PersonalizeExperience onComplete={handleOnboardingComplete} />
            ) : (
                <StoreInfoForm onComplete={handleOnboardingComplete} />
            )}
        </div>
    );
};

const StoreInfoForm = ({ onComplete }) => {
	const [selectedType, setSelectedType] = useState("business");
	const isBusiness = selectedType === 'business';

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const [apiErrors, setApiErrors] = useState({
		storeName: "",
		storeType: "",
		businessType: "",
		general: "",
	});

	const onSubmit = (data) => {
		setApiErrors({ storeName: '', storeType: '', businessType: '', general: '' });
		//Form submission will be handled here
		console.log("Form submission")
		
		// Call onComplete to redirect
        onComplete();
	};

	return (
		<div className="max-w-xl mx-auto p-6 bg-white">
			<h2 className="text-2xl text-kokorko-black-900 font-semibold text-center mb-6">Tell us about your Store</h2>

			{/* Business/Individual Selection */}
			<div className="flex justify-center space-x-4 sm:space-x-8 mb-6">
				<label
					className={`flex flex-col items-center space-y-1 cursor-pointer py-4 px-10 sm:px-16 rounded-lg border-2 ${isBusiness ? "border-kokorko-blue-primary-500 text-kokorko-black-900" : "border-kokorko-grey-200 text-kokorko-black-900"}`}
					onClick={() => setSelectedType("business")}
				>
					<div className={`flex items-center justify-center rounded-md size-10 border ${isBusiness ? 'text-kokorko-blue-primary-500 border-kokorko-blue-100' : 'text-kokorko-black-900 border-kokorko-grey-200'}`}>
						<IoBusinessSharp />
					</div>
					<span>Business</span>
				</label>
				<label
					className={`flex flex-col items-center space-y-1 cursor-pointer py-4 px-10 sm:px-16 rounded-lg border-2 ${!isBusiness ? "border-kokorko-blue-primary-500 text-kokorko-black-900" : "border-kokorko-grey-200 text-kokorko-black-900"}`}
					onClick={() => setSelectedType("individual")}
				>
					<div className={`flex items-center justify-center rounded-md size-10 border ${!isBusiness ? 'text-kokorko-blue-primary-500 border-kokorko-blue-100' : 'text-kokorko-black-900 border-kokorko-grey-200'}`}>
						<FaRegUser />
					</div>
					<span>Individual</span>
				</label>

			</div>

			<form onSubmit={handleSubmit(onSubmit)}>
				{/* Store Name Input */}
				<div className={`input-field ${errors.storeName || apiErrors.storeName ? 'error' : ''} mb-3`}>
					<label>Store Name</label>
					<div className="email-field">
						<input
							{...register("storeName", {
								required: "Store name is required",
								pattern: {
									value: /^(?!\s*$)[^\s]+$/,
									message: "Store name cannot be empty or contain spaces",
								},
							})}
							type="text"
							placeholder="Luminar Threads"
						/>
					</div>
					{errors.storeName && <p className="error-message">{errors.storeName.message}</p>}
					{apiErrors.storeName && <p className="error-message">{apiErrors.storeName}</p>}
				</div>

				{/* Store Type Dropdown */}
				<div className={`input-field ${errors.storeType || apiErrors.storeType ? 'error' : ''} mb-6`}>
					<label>What type of store do you operate?</label>
					<div className=" email-field h-12 ">
						<select
							{...register("storeType", { required: "Store type is required" })}
							className="flex items-center justify-between w-full h-full cursor-pointer focus:outline-none"
						>
							<option value="">Select Store Type</option>
							<option value="physical">Physical Store Only</option>
							<option value="online">Online Store Only</option>
							<option value="both">Both Physical and Online</option>
						</select>
					</div>
					{errors.storeType && <p className="error-message">{errors.storeType.message}</p>}
					{apiErrors.storeType && <p className="error-message">{apiErrors.storeType}</p>}
				</div>

				{/* Business Type Dropdown */}
				<div className={`input-field ${errors.businessType || apiErrors.businessType ? 'error' : ''} mb-6`}>
					<label>Business Type</label>
					<div className=" email-field h-12 ">
						<select
							{...register("businessType", { required: "Business type is required" })}
							className="flex items-center justify-between w-full h-full cursor-pointer focus:outline-none"
						>
							<option value="" className="">Select Business Type</option>
							<option value="clothing">Clothing & Accessories</option>
							<option value="electronics">Electronics & Gadgets</option>
							<option value="beauty">Beauty & Personal Care</option>
							<option value="health">Health & Wellness</option>
						</select>
					</div>
					{errors.businessType && <p className="error-message">{errors.businessType.message}</p>}
					{apiErrors.businessType && <p className="error-message">{apiErrors.businessType}</p>}
				</div>

			</form>
		</div>
	);
};


const PersonalizeExperience = ({ onComplete }) => {
	const [selectedInterests, setSelectedInterests] = useState([]);

	// const handleContinue = () => {
    //     // Perform any necessary logic with selected interests
    //     console.log("Selected Interests:", selectedInterests);
        
    //     // Call onComplete to redirect
    //     onComplete();
    // };

	const toggleInterest = (interest) => {
		if (selectedInterests.includes(interest)) {
			setSelectedInterests(selectedInterests.filter((item) => item !== interest));
		} else {
			setSelectedInterests([...selectedInterests, interest]);
		}
	};

	return (
		<div className="flex flex-col items-center justify-center bg-white">
			<div className="py-6 rounded-lg">
				<h2 className="text-lg font-semibold mb-4 text-center">
					Personalize Your Experience
				</h2>
				<p className="text-base text-kokorko-black-900 text-start mb-6">
					Choose your interests to get personalized store suggestions.
				</p>
				<div className="grid grid-cols-2 gap-3 pt-6">
					{interests.map((interest, index) => (
						<button
							key={index}
							onClick={() => toggleInterest(interest)}
							className={`py-2 px-3 text-sm rounded-md shrink-0 transition-all duration-200 ring-2 text-kokorko-black-900 ${selectedInterests.includes(interest)
								? "bg-kokorko-blue-50 ring-kokorko-blue-primary-500"
								: "bg-white ring-kokorko-grey-200 border-gray-400"
								}`}
						>
							{interest}
						</button>
					))}
				</div>
			</div>
			{/* <button 
                onClick={handleContinue}
                className="mt-6 bg-kokorko-blue-primary-500 text-white py-2 px-4 rounded-md"
            >
                Continue
            </button> */}
		</div>
	);
};
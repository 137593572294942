import React, { useState } from "react";

const BusinessStep5 = ({ onDone, onBack, resendCode }) => {
  const [code, setCode] = useState(["", "", "", ""]);

  const handleChange = (value, index) => {
    const newCode = [...code];
    newCode[index] = value.slice(0, 1);
    setCode(newCode);

    if (value && index < 3) {
      document.getElementById(`code-${index + 1}`).focus();
    }
  };

  return (
    <div className="flex justify-center items-center">
      <div className="p-4 shadow-none rounded-none w-full max-w-md">
        <h2 className="text-2xl font-semibold mb-2">Verify Your Phone Number</h2>
        <p className="text-gray-600 mb-6 text-sm">
          We have sent you a code on <span className="font-semibold">+233 123 456 789</span>. Enter it below to verify your number.
        </p>
        <div className="flex justify-between mb-6 space-x-1 pt-3">
          {code.map((digit, index) => (
            <input
              key={index}
              id={`code-${index}`}
              type="text"
              value={digit}
              onChange={(e) => handleChange(e.target.value, index)}
              className={`w-14 h-14 text-center text-xl border rounded-lg focus:ring-2 focus:ring-blue-500 ${
                digit ? "border-black" : "border-gray-300"
              }`}
            />
          ))}
        </div>
        <div className="flex gap-4 pt-4 pb-5">
          <button
            className="flex-1 py-4 px-4 text-gray-800 bg-gray-100 border border-gray-300 rounded-md hover:bg-gray-200"
            onClick={onBack}
          >
            Back
          </button>
          <button
            type="submit"
            className="flex-1 py-2 px-4 text-white bg-[#2752A5] rounded-md hover:bg-blue-600"
            onClick={onDone}
          >
            Done
          </button>
        </div>
        <p className="text-gray-600">
          Didn’t receive code?{" "}
          <button
            type="button"
            onClick={resendCode}
            className="text-blue-600 hover:underline"
          >
            Resend code
          </button>
        </p>
      </div>
    </div>
  );
};

export default BusinessStep5;

import { X } from 'lucide-react'
import React from 'react'

export const SelectedFilterItem = ({ selectedFilters, getFilterLabel, handleRemoveFilter, selectedFilterClassName}) => {
	
	return (
		<div className="flex overflow-x-scroll hideScrollbar gap-2 mb-4">
			{selectedFilters.map(filterId => (
				<button
					key={filterId}
					className={`inline-flex flex-shrink-0 items-center gap-2 px-4 py-2 bg-gray-100 rounded-full text-sm ${selectedFilterClassName}`}
				>
					{getFilterLabel(filterId)}
					<X
						className="w-4 h-4 cursor-pointer"
						onClick={() => handleRemoveFilter(filterId)}
					/>
				</button>
			))}
		</div>
	)
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loginUser, socialLogin, logout } from './authSlice';
import authService from '../../services/authService';

// Add a thunk to fetch user profile
export const fetchUserProfile = createAsyncThunk(
    'userProfile/fetchProfile',
    async (_, { getState, rejectWithValue }) => {
        try {
            // Get role from auth state
            const userRole  = getState().auth.userRole || localStorage.getItem('userRole');

            console.group('Fetch User Profile Debug');
            console.log('Attempting to fetch profile with role:', userRole);
            
            if (!userRole) {
                throw new Error('User role not found');
            }

            // Explicitly pass the role to getCurrentUser
            const profile = await authService.getCurrentUser(userRole);

            console.log('Fetching Profile for Role:', userRole);
            console.log('Fetched User Profile:', profile);
            console.groupEnd();

            return profile;
        } catch (error) {
            console.error('Fetch Profile Error:', error);
            console.groupEnd();
            return rejectWithValue(error.message || 'Failed to fetch profile');
        }
    }
);

const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState: {
        userInfo: null,
        userRole: null,
        isLoggedIn: false,
        loading: false,
        error: null
    },
    reducers: {
        setUserProfile: (state, action) => {
            console.log('Setting User Profile:', action.payload);
            state.userInfo = action.payload;
            state.userRole = action.payload?.role;
            state.isLoggedIn = !!action.payload;
        },
        clearUserProfile: (state) => {
            state.userInfo = null;
            state.userRole = null;
            state.isLoggedIn = false;
            state.loading = false;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(loginUser.fulfilled, (state, action) => {
            console.log('Login Payload in UserProfile:', action.payload);
            
            // Use profile from login response
            const profile = action.payload?.profile || action.payload?.user;
            const role = action.payload?.role;

            state.userInfo = profile;
            state.userRole = role;
            state.isLoggedIn = true;
            state.loading = false;
            state.error = null;
        })
        .addCase(fetchUserProfile.pending, (state) => {
            console.log('Fetch User Profile Pending');
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchUserProfile.fulfilled, (state, action) => {
            console.log('Fetch Profile Payload:', action.payload);
            state.userInfo = action.payload;
            state.userRole = action.payload?.role;
            state.loading = false;
            state.error = null;
        })
        .addCase(fetchUserProfile.rejected, (state, action) => {
            console.error('Fetch User Profile Rejected:', action.payload);
            state.loading = false;
            state.error = action.payload || 'Failed to fetch profile';
        })
        .addCase(socialLogin.fulfilled, (state, action) => {
            const profile = action.payload;
            const role = action.payload?.role;

            state.userInfo = profile;
            state.userRole = role;
            state.isLoggedIn = true;
            state.loading = false;
            state.error = null;
        })
        .addCase(logout, (state) => {
            state.userInfo = null;
            state.userRole = null;
            state.isLoggedIn = false;
            state.error = null;
            state.loading = false;
        });
    }
});

export const { 
    setUserProfile, 
    clearUserProfile, 
    // updateUserProfile 
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
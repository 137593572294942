import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FiCheckCircle, FiCreditCard } from 'react-icons/fi';
import { MdOutlineInfo } from "react-icons/md";
import ArrowRightIcon from '../../assets/icons/chevron-right.svg';
import TruckIcon from '../../assets/icons/truck.svg';
import VbuyIcon from '../../assets/icons/vbuy-icon.png';
import CheckoutModal from '../../components/checkoutModal/checkoutModal';

const PaymentInformation = ({ onContinue, cartTotal, cartItems }) => {
    const [paymentMethod, setPaymentMethod] = useState("mobileMoney")
    const { total } = useSelector((state) => state.cart);

    const deliveryFee = 30.00;
    const subTotal = total + deliveryFee;
  
    return (
      <>
        <div className='checkout-main-container align-center'>
          <div className="w-full md:w-1/2">
            <h2 className="text-sm font-normal flex items-center gap-2 mb-4">
              <span className="icon"><img src={TruckIcon} alt='truck-icon' /></span> Payment Method
            </h2>
            <p className="text-sm text-gray-500 mb-4">
              Kindly choose your payment method
            </p>
            <div className="flex gap-4 mb-6">
              <div
                onClick={() => setPaymentMethod("mobileMoney")}
                className={`w-1/2 flex items-center justify-center border-2 rounded-lg ${
                  paymentMethod === "mobileMoney"
                    ? "border-[#2857b0]"
                    : "border-gray-300"
                }`}
              >
            <div className="flex flex-col items-center justify-center border rounded-lg p-0 w-72  h-24 shadow-sm border-gray-300">
              <div className={`flex items-center justify-center w-10 h-10 border rounded-lg border-gray-300 ${
                paymentMethod==="mobileMoney" ? "border-kokorko-blue-primary-500" : ""
                }`}>
                <FiCreditCard className={` w-5 h-5 ${ paymentMethod === "mobileMoney" ? "text-kokorko-blue-primary-500" : "text-gray-800"}`} />
              </div>
              <p className={`mt-2  font-medium ${ paymentMethod === "mobileMoney" ? "text-kokorko-blue-primary-500" : "text-gray-500"}` }>Mobile Money</p>
            </div>
          </div>
          <div
            onClick={() => setPaymentMethod("card")}
            className={`w-1/2 flex items-center justify-center border-2 rounded-lg ${
              paymentMethod === "card"
              ? "border-[#2857b0]"
              : "border-gray-300"
            }`}
          >
            <div className="flex flex-col items-center justify-center border rounded-lg p-0 w-72  h-24 shadow-sm border-gray-300">
            <div className={`flex items-center justify-center w-10 h-10 border rounded-lg border-gray-300 ${
                paymentMethod==="card" ? "border-kokorko-blue-primary-500" : ""
                }`}>
                <FiCreditCard className={` w-5 h-5 ${ paymentMethod === "card" ? "text-kokorko-blue-primary-500" : "text-gray-800"}`} />
              </div>
              <p className={`mt-2  font-medium ${ paymentMethod === "card" ? "text-kokorko-blue-primary-500" : "text-gray-500"}` }>Card</p>
            </div>
          </div>
        </div>


       {paymentMethod === "mobileMoney" && 
                
        <div className="space-y-4">
        <div>
          <label className="block text-sm text-gray-700 mb-2">
            Account Name
          </label>
          <input
            type="text"
            className="w-full border border-gray-300 rounded-lg px-4 py-2"
            placeholder="John Doe"
          />
        </div>
        <div>
          <label className="block text-sm text-gray-700 mb-2">Number</label>
          <input
            type="text"
            className="w-full border border-gray-300 rounded-lg px-4 py-2"
            placeholder="0551234458"
          />
        </div>
      </div>}

        { paymentMethod==="card" && <AddCardForm />}
      </div>

      <div className='checkout-summary-container'>
                <div className='checkout-order-summary'>
                    <div className='checkoutOrder-summary-details'>
                        <p className='checkoutOrder-summary-title'>Summary</p>
                        <p className='checkoutOrder-summary-titleDetails'>See details
                            <img src={ArrowRightIcon} alt='arrow right icon' />
                        </p>
                    </div>
                    <div className='checkoutOrder-summary-details'>
                        <p className='checkoutOrder-summary-label'>Products</p>
                        <p className='checkoutOrder-summary-amount'>GH₵ {total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    </div>
                    <div className='checkoutOrder-summary-details'>
                        <p className='checkoutOrder-summary-label'>Delivery</p>
                        <p className='checkoutOrder-summary-amount'>GH₵ {deliveryFee.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    </div>
                    <div className='checkoutOrder-summary-details'>
                        <p className='checkoutOrder-summary-label'>Sub Total</p>
                        <p className='checkoutOrder-summary-total'>GH₵ {subTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    </div>
                    <button 
                        className='checkoutOrder-summary-button'
                        onClick={() => onContinue()}
                    >
                        Continue to Payment
                    </button>
                </div>
                <div className='vbuy-payment-info'>
                    <MdOutlineInfo size='1rem' />
                    <p>Secure payment via </p>
                    <img src={VbuyIcon} alt='Vbuy Icon' />
                </div>
            </div>
        </div>
        </>
    );
};

export default PaymentInformation;




const AddCardForm = () => {
    const [showModal, setShowModal] = useState(false);


    const handleCloseModal = () => {
        setShowModal(false);    
    };


    const handleOpenModal = () => {
        setShowModal(true);
    };

    // const handleNewCard = () => {
    //     console.log("Add new card")
    // }

  return (
    <div className="max-w-xl ">
    <div className='p-6 mb-5 border rounded-lg shadow-sm border-gray-300'>
      {/* Header */}
      <div className="flex items-center mb-4">
        <FiCheckCircle className="text-green-500 w-5 h-5 mr-2" />
        <h2 className="text-gray-800 font-medium" onClick={()=>handleOpenModal()}>Add new card</h2>
        <div className="ml-auto flex items-center gap-2">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/0/04/Mastercard-logo.png" // Replace with the actual logo image
            alt="Mastercard"
            className="h-5"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_2021.svg" // Replace with the actual logo image
            alt="Visa"
            className="h-5"
          />
        </div>
      </div>

      {/* Card Number */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Card number
        </label>
        <input
          type="text"
          placeholder="3431 2201 8931 855"
          className="w-full border rounded-md px-3 py-2 text-gray-800 shadow-sm focus:ring focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      {/* Card Owner */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Card owner
        </label>
        <input
          type="text"
          placeholder="John Doe"
          className="w-full border rounded-md px-3 py-2 text-gray-800 shadow-sm focus:ring focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      {/* Expiry Date and CVV */}
      <div className="flex items-center gap-4 mb-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Expiry date
          </label>
          <div className="flex items-center gap-2">
            <input
              type="text"
              placeholder="05"
              className="w-12 border rounded-md px-3 py-2 text-gray-800 shadow-sm focus:ring focus:ring-blue-500 focus:border-blue-500"
            />
            <span>/</span>
            <input
              type="text"
              placeholder="23"
              className="w-12 border rounded-md px-3 py-2 text-gray-800 shadow-sm focus:ring focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            CVV
          </label>
          <input
            type="text"
            placeholder="012"
            className="w-20 border rounded-md px-3 py-2 text-gray-800 shadow-sm focus:ring focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      </div>
      </div>
       {/* Default Checkbox */}
       <div className="flex items-center float-end">
        <input
          type="checkbox"
          id="defaultCard"
          className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring focus:ring-blue-500"
        />
        <label
          htmlFor="defaultCard"
          className="ml-2 text-sm text-gray-700 font-medium"
        >
          Set us default
        </label>
      </div>


      <CheckoutModal
        showModal={showModal} 
        onClose={handleCloseModal} 
        title="Enter Card Details" 
        description=""
        size="lg"
      >
        <div className="mb-4">
        <div className='p-6 mb-5 border rounded-lg shadow-sm border-gray-300'>
      {/* Header */}
      <div className="flex items-center mb-4">
        <FiCheckCircle className="text-green-500 w-5 h-5 mr-2" />
        <h2 className="text-gray-800 font-medium" onClick={()=>handleOpenModal()}>Add new card</h2>
        <div className="ml-auto flex items-center gap-2">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/0/04/Mastercard-logo.png" // Replace with the actual logo image
            alt="Mastercard"
            className="h-5"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_2021.svg" // Replace with the actual logo image
            alt="Visa"
            className="h-5"
          />
        </div>
      </div>

      {/* Card Number */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Card number
        </label>
        <input
          type="text"
          placeholder="3431 2201 8931 855"
          className="w-full border rounded-md px-3 py-2 text-gray-800 shadow-sm focus:ring focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      {/* Card Owner */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Card owner
        </label>
        <input
          type="text"
          placeholder="John Doe"
          className="w-full border rounded-md px-3 py-2 text-gray-800 shadow-sm focus:ring focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      {/* Expiry Date and CVV */}
      <div className="flex items-center gap-4 mb-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Expiry date
          </label>
          <div className="flex items-center gap-2">
            <input
              type="text"
              placeholder="05"
              className="w-12 border rounded-md px-3 py-2 text-gray-800 shadow-sm focus:ring focus:ring-blue-500 focus:border-blue-500"
            />
            <span>/</span>
            <input
              type="text"
              placeholder="23"
              className="w-12 border rounded-md px-3 py-2 text-gray-800 shadow-sm focus:ring focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            CVV
          </label>
          <input
            type="text"
            placeholder="012"
            className="w-20 border rounded-md px-3 py-2 text-gray-800 shadow-sm focus:ring focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      </div>
      
      </div>

             {/* Default Checkbox */}
             <div className=" mb-4 items-center float-end">
        <input
          type="checkbox"
          id="defaultCard"
          className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring focus:ring-blue-500"
        />
        <label
          htmlFor="defaultCard"
          className="ml-2 text-sm text-gray-700 font-medium"
        >
          Set us default
        </label>
      </div>
      
      <div className=" pb-10 pt-0 bg-white">
          <button className="w-full py-2 text-white bg-[#2752A5] rounded hover:bg-blue-600" onClick={()=>{}}>
            Continue
          </button>
        </div>
        </div>
    </CheckoutModal>
    </div>
  );
};

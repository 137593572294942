import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// import { MdOutlineInfo } from "react-icons/md";
import ArrowRightIcon from '../../assets/icons/chevron-right.svg';
// import VbuyIcon from '../../assets/icons/vbuy-icon.png';
import OrderSuccess from './ReviewCompleted';

const ReviewOrder = ({ onBack, onContinue, onFinish }) => {

    const [orderFinished, setOrderedSuccess] = useState(false);
    const { items, total } = useSelector((state) => state.cart);
    const deliveryFee = 30.00;
    const subTotal = total + deliveryFee;

    const onFinishOrder = () => {
        setOrderedSuccess(true)
    };
    
    return (
        <>
            {orderFinished? (
              <div className='checkout-main-container'>
                <OrderSuccess />
              </div>
            ):(
                <div className='checkout-main-container w-full'>
                  <div className="w-full max-w-screen-lg flex justify-cente">
                        <div className="flex justify-center items-center">
                      <div className="w-full">
                        <div className="p-6">
                          {/* Product Info */}
                          {items.map(item => (
                                        <div key={item.cartItemId} className="flex items-center justify-between mb-6 rounded-lg border-2 p-5">
                                            <div className="flex items-center space-x-4">
                                                <img
                                                    src={item.productImage} 
                                                    alt={item.productName}
                                                    className="w-20 h-20 object-cover rounded-md"
                                                />
                                                <div>
                                                    <h2 className="text-lg font-medium">{item.productName}</h2>
                                                    <p className="text-gray-900 font-bold">
                                                        GH₵ {item.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} 
                                                        <span className='rounded-3xl bg-gray-200 p-1 text-gray-900 font-thin text-center text-sm border'> x{item.quantity}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='rounded-full text-sm bg-gray-200 border-1 pl-3 pr-3'>
                                                <p className="text-sm font-medium">
                                                    Sub total: GH₵ {(item.price * item.quantity).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                          {/* Delivery Info */}
                          <div className="mb-6">
                            <h3 className="text-lg font-medium mb-2">Delivery</h3>
                            <div className="text-gray-600">
                              <p>
                                <span className="font-medium">Delivery Method:</span> Door Deliver
                              </p>
                              <p>
                                <span className="font-medium">Estimated Delivery Date:</span>{' '}
                                25th August, 2024
                              </p>
                              <p>
                                <span className="font-medium">Address:</span> Tema Comm 1, House 5
                              </p>
                            </div>
                          </div>
                
                          {/* Payment Info */}
                          <div className="mb-6">
                            <h3 className="text-lg font-medium mb-2">Payment Information</h3>
                            <div className="text-gray-600">
                              <p>
                                <span className="font-medium">Payment Method:</span> Mobile Money
                              </p>
                              <p>
                                <span className="font-medium">Account Name:</span> John Doe
                              </p>
                              <p>
                                <span className="font-medium">Mobile Number:</span> +233 123 456 7890
                              </p>
                            </div>
                          </div>
                
                          {/* Escrow Info */}
                          <div className="mb-6">
                            <h3 className="text-lg font-medium mb-2">Escrow Information</h3>
                            <div className="text-gray-600">
                              <p>
                                <span className="font-medium">Amount Held in Escrow:</span> GH₵
                                24,030.00
                              </p>
                              <p>Funds will be released upon delivery confirmation.</p>
                            </div>
                          </div>
                
                          <button
                            className="checkoutOrder-summary-button w-full hover:bg-blue-700 transition relative"
                            onClick={()=>onFinishOrder()}
                                    >
                                        Confirm Order
                                    </button>
                                </div>
                            </div>
                        </div>
                    
                        <div className='checkout-summary-container'>
                            <div className='checkout-order-summary'>
                                <div className='checkoutOrder-summary-details'>
                                    <p className='checkoutOrder-summary-title'>Summary</p>
                                    <p className='checkoutOrder-summary-titleDetails'>See details
                                        <img src={ArrowRightIcon} alt='arrow right icon' />
                                    </p>
                                </div>
                                <div className='checkoutOrder-summary-details'>
                                    <p className='checkoutOrder-summary-label'>Products</p>
                                    <p className='checkoutOrder-summary-amount'>
                                        GH₵ {total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </p>
                                </div>
                                <div className='checkoutOrder-summary-details'>
                                    <p className='checkoutOrder-summary-label'>Delivery</p>
                                    <p className='checkoutOrder-summary-amount'>
                                        GH₵ {deliveryFee.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </p>
                                </div>
                                <div className='checkoutOrder-summary-details'>
                                    <p className='checkoutOrder-summary-label'>Total</p>
                                    <p className='checkoutOrder-summary-total'>
                                        GH₵ {subTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                )}
        </>

   
    );
};
export default ReviewOrder;

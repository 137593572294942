import { combineReducers } from 'redux';
import authReducer from './slices/authSlice';
import cartReducer from './slices/cartSlice';
import wishlistReducer from './slices/wishlistSlice';
import userReducer from './slices/userSlice';
import productReducer from './slices/productSlice';
import signupReducer from './slices/signupSlice';
import userProfileReducer from './slices/userProfileSlice';
import sellerOverviewReducer from './slices/sellerOverviewSlice';
import heroNavReducer from './slices/heroNavSlice';
import checkoutReducer from './slices/checkoutSlice';

const rootReducer = combineReducers({
    auth: authReducer,
    cart: cartReducer,
    wishlist: wishlistReducer,
    user: userReducer,
    product: productReducer,
    signup: signupReducer,
    userProfile: userProfileReducer,
    sellerOverview: sellerOverviewReducer,
    heroNav: heroNavReducer,
    checkout: checkoutReducer
});

export default rootReducer;
import React, { useState } from 'react'
import { GrCart } from "react-icons/gr"
import { useNavigate } from "react-router"
import { Link } from 'react-router-dom'
import authLogo from '../../assets/Footer Logo.svg'
import ChevronLeftIcon from '../../assets/icons/chevron-left.svg'
import authStoreIcon from '../../assets/icons/hugeicons_store-01.svg'
import buyerImage from '../../assets/images/buyer-login-image.png'
import sellerImage from '../../assets/images/seller-login-image.png'
import { Verification } from '../../components/Verification'
import { Onboarding } from '../../components/Onboarding'
import { Stepper } from '../../components/common/Stepper'
import LoginForm from '../../components/loginForm/LoginForm'
import SignupForm from '../../components/signupForm/SignupForm'
import './AuthPage.css'

const AuthPage = () => {
    const [isLogin, setIsLogin] = useState(true);
    const [role, setRole] = useState('');
    const [currentStep, setCurrentStep] = useState(1); // Tracks the current step
    const [formCompletion, setFormCompletion] = useState({}); // Tracks if forms are completed for each step

    const totalSteps = 4; // Total steps in the signup process

    const isBuyer = role === 'buyer';
    let router = useNavigate();


    // Function to handle step navigation
    const goToStep = (step) => {
        if (step <= currentStep || (formCompletion[currentStep] && step > currentStep)) {
            setCurrentStep(step);
        }
    };

    // Handle role selection
    const handleRoleSelection = (selectedRole) => {
        setRole(selectedRole);
        setFormCompletion((prev) => ({ ...prev, 1: true })); // Mark step 1 as completed
    };

    // Mark current step as completed
    const markCurrentStepCompleted = () => {
        setFormCompletion((prev) => ({ ...prev, [currentStep]: true }));
        if (currentStep < totalSteps) {
            setCurrentStep(currentStep + 1);
        }

        //open onboarding screen
        if (currentStep === totalSteps) {
            router('/profile/s/onboarding');
        }
    };

    // Toggle between login and signup views
    const toggleAuthView = () => {
        setIsLogin(!isLogin);
        setRole(''); // Reset role selection
        setCurrentStep(1); // Reset steps
        setFormCompletion({}); // Reset completion tracking
    };

    // Function to handle going back to role selection (Login form)
    const goBackToRoleSelection = () => {
        setIsLogin(true); // Switch to signup view
        setRole(''); // Reset role selection
        setCurrentStep(1); // Go back to the initial step
    };


    // Render components based on the current state
    const renderCurrentStage = () => {
        if (currentStep === 1) {
            return (
                <>
                    <RoleOptions
                        role={role}
                        isLogin={isLogin}
                        handleRoleSelection={handleRoleSelection}
                    />
                    <button
                        className={`mt-8 mb-10 py-2 px-4 rounded w-full bg-kokorko-blue-primary-500 text-white ${!role ? 'cursor-not-allowed' : ''} }`}
                        onClick={markCurrentStepCompleted}
                        disabled={!role}
                    >
                        Continue
                    </button>
                    {isLogin ? (
                        <div className='flex md:hidden mx-auto'>
                            <ToggleForm />
                        </div>
                    ) : null}
                </>
            );
        }

        if (isLogin) {
            return (
                <>
                    <LoginForm role={role} />
                    <button className="back-button w-fit mt-4 flex justify-start items-center" onClick={goBackToRoleSelection}>
                        <img src={ChevronLeftIcon} alt="Back" className="mr-2" />
                        Back
                    </button>
                </>
            );
        } else {
            switch (currentStep) {
                case 2:
                    return <SignupForm role={role} />;
                case 3:
                    return <Verification contactInfo={'veri12@verifibuy.com'} />;
                case 4:
                    return <Onboarding isBuyer={isBuyer} />;
                default:
                    return null;
            }
        }
    };

    // Render role image dynamically based on role
    const renderRoleImage = () => {
        return (
            <img
                src={role === 'seller' ? sellerImage : buyerImage}
                alt={`${role || 'buyer'}-image`}
                className="role-image"
            />
        );
    };

    const ToggleForm = () => {
        return (
            <div className="flex">
                <div className="flex items-center">
                    <h1>{isLogin ? "Don’t have an account?" : 'Already have an account?'}</h1>
                    <button
                        className=" ml-2 bg-transparent text-kokorko-blue-primary-500"
                        onClick={toggleAuthView}
                    >
                        {isLogin ? 'Sign Up' : 'Login'}
                    </button>
                </div>
            </div>
        )
    }

    return (
        <div className="overflow-hidden w-full md:w-11/12 auth-page-container">
            <div className="flex flex-col items-center justify-between w-full h-full px-0 sm:p-5 lg:w-1/2 auth-left-column">
                <div className="flex items-center justify-center py-10 md:p-0 md:justify-between w-full auth-header">
                    <Link to={'/'} className="flex justify-center md:justify-start mx-auto md:mx-0 auth-logo">
                        <img src={authLogo} alt="AuthLogo" />
                    </Link>
                    {isLogin || currentStep < 2 ? (
                        <div className='hidden md:flex '>
                            <ToggleForm />
                        </div>
                    ) : null}
                </div>

                <div className="relative w-full flex flex-col items-center justify-center flex-1 md:px-16 lg:px-0 overflow-y-auto hideScrollbar">
                    <div className="flex flex-col justify-normal md:justify-center h-full mx-auto w-[90%] sm:w-4/5">
                        {renderCurrentStage()}

                        {!isLogin && (
                            <div className="flex flex-col items-center gap-10 pb-10">
                                {currentStep > 1 && (
                                    <button
                                        className={`bg-kokorko-blue-primary-500 text-white py-2 px-4 rounded w-full`}
                                        onClick={markCurrentStepCompleted}
                                    >
                                        {currentStep === totalSteps ? 'Submit' : 'Continue'}
                                    </button>
                                )}

                                {isLogin || currentStep < 3 ? (
                                    <div className='flex md:hidden'>
                                        <ToggleForm />
                                    </div>
                                ) : null}
                                <Stepper
                                    goToStep={goToStep}
                                    totalSteps={totalSteps}
                                    currentStep={currentStep}
                                    activeStyle="bg-kokorko-blue-primary-500"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="hidden w-1/2 lg:flex auth-right-column">
                {renderRoleImage()} {/* Display dynamic image based on role */}
            </div>
        </div>
    );
};

export default AuthPage;

const RoleOptions = ({ role, isLogin, handleRoleSelection }) => {

    return (
        <div className="role-selection">
            <div className='mb-8 role-selection-header'>
                <h1>{isLogin ? 'Welcome back' : 'How do you plan to use Kokorko?'}</h1>
                <h2>{isLogin ? 'I am logging in as a...' : 'Select the option that best describes your goals'}:</h2>
            </div>
            <div className={isLogin ? 'role-buttons' : 'role-buttons-signup'}>
                <button
                    className={`relative ${role === 'buyer' ? 'active' : ''}`}
                    onClick={() => handleRoleSelection('buyer')}>

                    <div className='buyer-icon-text-container'>
                        <div className={`buyer-icon-container ${role === 'buyer' ? 'active' : ''} `}>
                            <GrCart className={`buyer-icon ${role === 'buyer' ? 'active' : ''}`} color='#222222' />
                        </div>
                        <div>
                            <p className='buyer-icon-text'>Buyer</p>
                            <p className='buyer-icon-text-desc'>{!isLogin ? 'Search, Discover, and Purchase' : ' '}</p>
                        </div>
                    </div>
                    <input className='absolute top-4 right-6' type="radio" checked={role === 'buyer'} readOnly />
                </button>
                <button
                    className={`relative ${role === 'seller' ? 'active' : ''}`}
                    onClick={() => handleRoleSelection('seller')}>

                    <div className='buyer-icon-text-container'>
                        <div className={`buyer-icon-container ${role === 'seller' ? 'active' : ''} `}>
                            <img src={authStoreIcon} alt="AuthStoreIcon" className={`buyer-icon ${role === 'seller' ? 'active' : ''}`} />
                        </div>
                        <div>
                            <p className='seller-icon-text'>Seller</p>
                            <p className='buyer-icon-text-desc'>{!isLogin ? 'Grow Your Business' : ' '}</p>
                        </div>
                    </div>
                    <input className='absolute top-4 right-6' type="radio" checked={role === 'seller'} readOnly />
                </button>
            </div>
        </div>
    )
};
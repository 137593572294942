// src/store/slices/sellerOverviewSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchSellerData = createAsyncThunk(
    'sellerOverview/fetchSellerData',
    async (userId, { rejectWithValue }) => {
        try {
        const response = await axios.get('/data.json');
        const users = response.data.users;
        const stores = response.data.stores;
        const orders = response.data.orders;

        // Find the logged-in seller's details
        const loggedInSeller = users.find(user => user.id === userId && user.role === 'seller');
        if (!loggedInSeller) {
            return rejectWithValue('Seller not found');
        }

        // Find the seller's store using the store ID
        const sellerStore = stores.find(store => store.id === loggedInSeller.store);
        if (!sellerStore) {
            return rejectWithValue('No store found for this seller');
        }

        return {
            seller: loggedInSeller,
            store: sellerStore,
            recentOrders: orders
        };
        } catch (error) {
        return rejectWithValue(error.message);
        }
    }
);

const sellerOverviewSlice = createSlice({
    name: 'sellerOverview',
    initialState: {
        seller: null,
        store: null,
        recentOrders: [],
        loading: false,
        error: null
    },
    reducers: {
        resetSellerOverview: (state) => {
        state.seller = null;
        state.store = null;
        state.recentOrders = [];
        state.loading = false;
        state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchSellerData.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchSellerData.fulfilled, (state, action) => {
            state.loading = false;
            state.seller = action.payload.seller;
            state.store = action.payload.store;
            state.recentOrders = action.payload.recentOrders;
        })
        .addCase(fetchSellerData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
});

export const { resetSellerOverview } = sellerOverviewSlice.actions;
export default sellerOverviewSlice.reducer;
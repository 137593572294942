import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../services/authService";
import { getRateLimitKey, checkRateLimit } from "../../utils/rateLimiter";


// Login Thunk
export const loginAsync = createAsyncThunk(
    'auth/login',
    async (credentials, { rejectWithValue }) => {
        // Rate limit check
        const rateLimitKey = getRateLimitKey('login');
        if (!checkRateLimit(rateLimitKey)) {
            return rejectWithValue('Too many login attempts');
        }
    
        try {
            const userData = await authService.login(credentials);

            console.log('Login Thunk Response:', userData);
            
            return userData;
        } catch (error) {
            console.error('Login Thunk Error:', error);
            return rejectWithValue(error.response?.data || 'Login failed');
        }
    }
);

// Add loginUser as an alias for loginAsync
export const loginUser = loginAsync;

  // Signup Thunk
export const signupAsync = createAsyncThunk(
    'auth/signup',
    async (userData, { rejectWithValue }) => {
        // Rate limit check
        const rateLimitKey = getRateLimitKey('signup');
        if (!checkRateLimit(rateLimitKey)) {
            return rejectWithValue('Too many signup attempts');
        }
    
        try {
            const response = await authService.signup(userData);

            console.log('Signup Thunk Response:', response);
            
            return response;
        } catch (error) {
            console.error('Signup Thunk Error:', error);
            return rejectWithValue(error.response?.data || 'Signup failed');
        }
    }
);

  // Logout Thunk
export const logoutAsync = createAsyncThunk(
    'auth/logout',
    async (_, { rejectWithValue }) => {
        try {
            await authService.logout();
            return null;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Logout failed');
        }
    }
);

  // Fetch Current User Thunk
export const fetchCurrentUserAsync = createAsyncThunk(
    'auth/fetchCurrentUser',
    async (_, { rejectWithValue }) => {
        try {
            // Retrieve role from localStorage or state
            const storedRole = localStorage.getItem('userRole');
            
            if (!storedRole) {
                throw new Error('No user role found');
            }

            console.log('Fetching Current User with Role:', storedRole);
            
            const userData = await authService.getCurrentUser(storedRole);
            
            console.log('Fetch Current User Response:', userData);
            
            return {
                ...userData,
                role: storedRole
            };
        } catch (error) {
            console.error('Fetch Current User Thunk Error:', error);
            return rejectWithValue(error.response?.data || 'Failed to fetch user');
        }
    }
);

// Async thunk for social logins
export const socialLogin = createAsyncThunk(
    'auth/socialLogin',
    async (socialData, { rejectWithValue }) => {
        try {
            const response = await authService.socialLogin(socialData);
            return response;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Social login failed');
        }
    }
);

const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: null,
        isAuthenticated: false,
        userRole: localStorage.getItem('userRole') || null,
        status: 'idle',
        isLoading: false,
        error: null
    },
    reducers: {
        logout: (state) => {
            state.user = null;
            state.token = null;
            state.isAuthenticated = false;
        }
    },
    extraReducers: (builder) => {

    // Login Cases
    builder
        .addCase(loginAsync.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
        state.error = null;
        })
        .addCase(loginAsync.fulfilled, (state, action) => {
        console.log('Login Fulfilled Action:', action);
        console.log('Full Action Payload:', action.payload);

        // user extraction
        const user = action.payload?.user || action.payload?.profile || {};
        const role = action.payload?.user?.role || action.payload?.role || user.role || localStorage.getItem('userRole') || 'user';

        console.log('Extracted User:', user);
        console.log('Extracted Role:', role);

        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = user;
        state.userRole = role;
        state.isLoading = false;
        state.error = null;
        })
        .addCase(loginAsync.rejected, (state, action) => {
        console.error('Login Rejected:', action);
        state.status = 'failed';
        state.error = action.payload || 'Login failed';
        state.isAuthenticated = false;
        state.isLoading = false;
        state.user = null;
        state.userRole = null;
        })
        .addCase(fetchCurrentUserAsync.fulfilled, (state, action) => {
            console.log('Fetch Current User Fulfilled:', action.payload);
            
            state.user = action.payload;
            state.userRole = action.payload.role || localStorage.getItem('userRole') || 'user';
            state.isAuthenticated = true;
        })
        .addCase(fetchCurrentUserAsync.rejected, (state, action) => {
            console.error('Fetch Current User Rejected:', action.payload);
            
            state.user = null;
            state.userRole = null;
            state.isAuthenticated = false;
        });

    // Signup Cases
    builder
        .addCase(signupAsync.pending, (state) => {
        state.status = 'loading';
        })
        .addCase(signupAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload.user;
        })
        .addCase(signupAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        state.isAuthenticated = false;
        });

    // Logout Cases
    builder
        .addCase(logoutAsync.fulfilled, (state) => {
        state.user = null;
        state.userRole = null;
        state.isAuthenticated = false;
        state.status = 'idle';
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userRole');
        });

    // social login reducers
    builder
        .addCase(socialLogin.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        })
        .addCase(socialLogin.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isAuthenticated = true;
            state.user = action.payload;
            state.error = null;
        })
        .addCase(socialLogin.rejected, (state, action) => {
            state.isLoading = false;
            state.isAuthenticated = false;
            state.user = null;
            state.error = action.payload;
        });
    }
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
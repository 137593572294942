import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/Logo-white.svg'
import Nav from '../../components/navBar/Nav'
import Search from '../../components/search/Search'
import './Header.css'

const Header = () => {
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getSearchProducts = async () => {
            try {
                const response = await fetch('/data.json');
                const data = await response.json();

                setProducts(data);
                if (Array.isArray(data.products)) {
                    setProducts(data.products);
                } else {
                    setError('Unexpected data format');
                }
            } catch (error) {
                console.error('Error fetching search products:', error);
            }
        };
        getSearchProducts();
    }, []);

    if (!products) {
        return <div>
            {error &&
                <p>No result matches your search term</p>
            }
        </div>
    }
    return (
        <div className='h-20 md:h-[6rem] header z-50'>
            <div className='w-full px-4 sm:px-0 md:w-[93%] lg:w-4/5 itemsContainer'>
                {/* Logo on tablets and desktops */}
                <Link to={'/'} className='hidden md:block logo'>
                    <img src={logo} alt='logo' />
                </Link>

                {/* Logo on mobile devices */}
                <Link to={'/'} className='block md:hidden '>
                    <img src='/smallLogo.png' alt='logo' />
                </Link>
                <div className='hidden sm:flex md:mx-10 items-center h-full lg:w-1/2'>
                    <Search products={products} />
                </div>
                <Nav />
            </div>
        </div>
    )
}

export default Header

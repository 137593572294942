import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from '../../services/authService';

// Async thunk for signup
export const signupUser = createAsyncThunk(
    'signup/signupUser',
    async (userData, { rejectWithValue }) => {
        try {
            const response = await authService.signup(userData);
            return response;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Signup failed');
        }
    }
);


const signupSlice = createSlice({
    name: 'signup',
    initialState: {
        loading: false,
        success: false,
        error: null,
        apiErrors: {
            email: '',
            password: '',
            confirmPassword: '',
            general: '',
            firstName: '',
            lastName: '',
            phoneNumber: ''
        }
    },
    reducers: {
        resetSignupState: (state) => {
            state.loading = false;
            state.success = false;
            state.error = null;
            state.apiErrors = {
                email: '',
                password: '',
                confirmPassword: '',
                general: '',
                firstName: '',
                lastName: '',
                phoneNumber: ''
            };
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(signupUser.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(signupUser.fulfilled, (state, action) => {
            state.loading = false;
            state.success = true;
            state.error = null;
            console.log('Signup successful:', action.payload);
        })
        .addCase(signupUser.rejected, (state, action) => {
            state.loading = false;
            state.success = false;
            state.error = action.payload;
            
            // Handle specific error cases
            if (typeof action.payload === 'object') {
                state.apiErrors = {
                    email: action.payload.email?.[0] || '',
                    password: action.payload.password?.[0] || '',
                    firstName: action.payload.first_name?.[0] || '',
                    lastName: action.payload.last_name?.[0] || '',
                    phoneNumber: action.payload.phone_number?.[0] || '',
                    general: action.payload.detail || ''
                };
            } else {
                state.apiErrors.general = action.payload;
            }
        });
    }
});

export const { resetSignupState } = signupSlice.actions;
export default signupSlice.reducer;
import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

export const Pagination = ({ currentPage = 1, totalPages = 13, onPageChange }) => {
	const handlePageChange = (page) => {
		if (page < 1 || page > totalPages) return;
		onPageChange(page);
	};

	return (
		<div className="flex items-center justify-center gap-2">
			{/* Previous button */}
			<button
				onClick={() => handlePageChange(currentPage - 1)}
				className="flex items-center gap-2 text-gray-500 hover:text-gray-700"
			>
				<ChevronLeft className="w-4 h-4" />
				<span>Previous</span>
			</button>

			{/* Page numbers */}
			{[1, 2, 3].map((number) => (
				<button
					key={number}
					onClick={() => handlePageChange(number)}
					className={`w-8 h-8 rounded-md flex items-center justify-center ${currentPage === number
						? 'bg-kokorko-blue-600 text-white'
						: 'text-gray-600 hover:bg-gray-100'
						}`}
				>
					{number}
				</button>
			))}

			{/* Ellipsis */}
			<span className="text-gray-400">...</span>

			{/* Last pages */}
			{[12, 13].map((number) => (
				<button
					key={number}
					onClick={() => handlePageChange(number)}
					className={`w-8 h-8 rounded-md flex items-center justify-center ${currentPage === number
						? 'bg-blue-700 text-white'
						: 'text-gray-600 hover:bg-gray-100'
						}`}
				>
					{number}
				</button>
			))}

			{/* Next button */}
			<button
				onClick={() => handlePageChange(currentPage + 1)}
				className="flex items-center gap-2 text-gray-500 hover:text-gray-700"
			>
				<span>Next</span>
				<ChevronRight className="size-4" />
			</button>
		</div>
	);
};
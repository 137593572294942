import React from "react";

const BusinessStep1 = ({ onNext }) => {
  return (
    <div className="flex justify-center items-center mb-6">
    <div className="p-2 rounded-lg shadow-none max-w-md w-full">
      <h1 className="text-2xl font-semibold text-gray-800 mb-4">
        Tell Us About Your Business
      </h1>
      <p className="text-gray-600 mb-6">
        Share a bit about what you do so we can set up your store just right
      </p>
      
      <form>
        <div className="mb-4">
          <label
            htmlFor="storeName"
            className="block text-sm font-medium text-gray-700"
          >
            Store Name
          </label>
          <input
            type="text"
            placeholder="Enter your store name"
            className="w-full px-4 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="mb-6">
          <label
            htmlFor="businessDescription"
            className="block text-sm font-medium text-gray-700"
          >
            Business Description
          </label>
          <textarea
            id="businessDescription"
            name="businessDescription"
            rows={8}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
            placeholder="Briefly describe your business"
          ></textarea>
          <p className="text-sm text-gray-500 mt-1">Not more than 300 words</p>
        </div>

        <div className="flex gap-4 pt-4 pb-10">
<button
  className="flex-1 py-4 px-4 text-gray-800 bg-gray-100 border border-gray-300 rounded-md hover:bg-gray-200"
  onClick={()=>{}}
>
  Cancel
</button>
<button type='submit'
  className="flex-1 py-2 px-4 text-white bg-[#2752A5] rounded-md hover:bg-blue-600"
  onClick={onNext}>
  Next
</button>
</div>
      </form>
    </div>
  </div>
  );
};

export default BusinessStep1;

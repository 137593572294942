import {
  HeartIcon,
  HomeIcon,
  ShoppingBagIcon,
  MessageCircleIcon,
  StarIcon,
  HelpCircleIcon,
  SettingsIcon,
  LightbulbIcon,
  BarChartIcon,
  UsersIcon,
  WalletIcon,
} from "lucide-react";
import { HiBuildingStorefront } from "react-icons/hi2";

export const statusColors = {
  "in transit": "#F8C43E",
  cancelled: "#E82F05",
  delivered: "#00EB53",
  pending: "#2752a5",
};

export const buyerLinks = [
  {
    items: [
      {
        label: "Overview",
        path: "/profile/overview",
        icon: <HomeIcon className="block md:hidden size-5" />,
      },
    ],
  },
  {
    items: [
      {
        label: "Orders",
        path: "/profile/orders",
        icon: <ShoppingBagIcon className="block md:hidden size-5" />,
      },
      {
        label: "Messages",
        path: "/profile/messages",
        icon: <MessageCircleIcon className="block md:hidden size-5" />,
      },
    ],
  },
  {
    items: [
      {
        label: "Wishlist",
        path: "/profile/wishlist",
        icon: <HeartIcon className="block md:hidden size-5" />,
      },
      {
        label: "Review Purchases",
        path: "/profile/reviews",
        icon: <StarIcon className="block md:hidden size-5" />,
      },
    ],
  },
  {
    items: [
      {
        label: "Help",
        path: "/profile/help",
        icon: <HelpCircleIcon className="block md:hidden size-5" />,
      },
      {
        label: "Suggestions",
        path: "/profile/suggestions",
        icon: <LightbulbIcon className="block md:hidden size-5" />,
      },
      {
        label: "Settings",
        path: "/profile/settings",
        icon: <SettingsIcon className="block md:hidden size-5" />,
      },
    ],
  },
];

export const sellerLinks = [
  {
    items: [
      {
        label: "My Shop",
        path: "/profile/seller-overview",
        icon: <HiBuildingStorefront className="block md:hidden size-5" />,
      },
    ],
  },
  {
    items: [
      {
        label: "Orders",
        path: "/profile/seller-orders",
        icon: <ShoppingBagIcon className="block md:hidden size-5" />,
      },
      {
        label: "Product Management",
        path: "/profile/product-mgmt",
        icon: <SettingsIcon className="block md:hidden size-5" />,
      },
    ],
  },
  {
    items: [
      {
        label: "Messages",
        path: "/profile/messages",
        icon: <MessageCircleIcon className="block md:hidden size-5" />,
      },
      {
        label: "Customers",
        path: "/profile/customers",
        icon: <UsersIcon className="block md:hidden size-5" />,
      },
      {
        label: "Wallet",
        path: "/profile/wallet",
        icon: <WalletIcon className="block md:hidden size-5" />,
      },
      {
        label: "Analytics",
        path: "/profile/analytics",
        icon: <BarChartIcon className="block md:hidden size-5" />,
      },
    ],
  },
  {
    items: [
      {
        label: "Help Center",
        path: "/profile/help",
        icon: <HelpCircleIcon className="block md:hidden size-5" />,
      },
      {
        label: "Settings",
        path: "/profile/settings",
        icon: <SettingsIcon className="block md:hidden size-5" />,
      },
    ],
  },
];

export const tabsConfig = {
  "Profile Details": [
    {
      name: "firstName",
      label: "First name",
      type: "text",
      defaultValue: "John",
    },
    {
      name: "lastName",
      label: "Last name",
      type: "text",
      defaultValue: "Doe",
    },
    {
      name: "email",
      label: "Email",
      type: "text",
      defaultValue: "Johndoe@gmail.com",
    },
    {
      name: "dateOfBirth",
      label: "Date of birth",
      type: "date",
      defaultValue: "1990-01-01",
    },
    {
      name: "gender",
      label: "Gender",
      type: "select",
      defaultValue: "Male",
      options: ["Male", "Female", "Other", "Prefer not to say"],
    },
  ],
  Address: [
    {
      name: "streetAddress",
      label: "Street Address",
      type: "text",
      placeholder: "House Number 5, Nii Ashidam Loop",
      defaultValue: "",
    },
    {
      name: "city",
      label: "City",
      type: "text",
      placeholder: "Teshie, Atta-Odoi",
      defaultValue: "",
    },
    {
      name: "region",
      label: "Region",
      type: "select",
      defaultValue: "Greater Accra",
      options: [
        "Greater Accra",
        "Ashanti",
        "Western",
        "Eastern",
        "Central",
        "Northern",
      ],
    },
    {
      name: "gps",
      label: "GPS (optional)",
      type: "text",
      placeholder: "GZ- 101 232 31 - 0",
      defaultValue: "",
    },
  ],
  "Phone number": [],
  "Change Password": [
    {
      name: "currentPassword",
      label: "Current password",
      type: "password",
      placeholder: "Enter your current password",
      defaultValue: "",
    },
    {
      name: "newPassword",
      label: "New password",
      type: "password",
      placeholder: "Enter your new password",
      defaultValue: "",
    },
    {
      name: "confirmPassword",
      label: "Confirm password",
      type: "password",
      placeholder: "Confirm your password",
      defaultValue: "",
    },
  ],
};

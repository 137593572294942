import { Link } from 'react-router-dom';

export const NotificationItem = ({ title, message, time }) => (
	<div className="py-4 border-b border-gray-100">
		<h3 className="font-semibold text-kokorko-black-400 mb-1">{title}</h3>
		<p className="text-sm text-[#333333] mb-2">{message}</p>
		<div className="flex justify-between items-center">
			<Link to="/notifications" className="border border-[#E6E6E6] rounded text-kokorko-blue-600 px-2 py-1 text-sm">View Order</Link>
			<span className="text-sm text-kokorko-black-400">{time}</span>
		</div>
	</div>
);
import React from 'react';
import { ChevronLeft, Phone, HelpCircle, ChevronRight, } from 'lucide-react';
import { HiOutlineChatBubbleLeftRight } from 'react-icons/hi2';
import { PiChatCircleText } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';

const helpItems = [
    {
        icon: <HiOutlineChatBubbleLeftRight className="size-6 text-kokorko-black-400" />,
        title: 'Frequently Asked Questions',
        path: '/faq'
    },
    {
        icon: <HelpCircle className="size-6 text-kokorko-black-400" />,
        title: 'Visit Help Center',
        path: '/help-center'
    },
    {
        icon: <PiChatCircleText className="size-6 text-kokorko-black-400" />,
        title: 'Chat with Us',
        path: '/chat'
    },
    {
        icon: <Phone className="size-6 text-kokorko-black-400" />,
        title: 'Call Us',
        path: '/call'
    }
];

const Help = () => {
      const navigate = useNavigate();

    return (
        <div className="w-full max-w-xl mx-auto md:mx-5 p-4 mt-5 md:mt-0 lg:-mt-4 text-kokorko-black-400">
            <button onClick={() => navigate(-1)} className="flex items-center mb-6 gap-2">
                <ChevronLeft className="size-6" />
                <h1 className="text-2xl font-semibold">Help</h1>
            </button>

            <p className="text-lg mb-6">Got quetions? We're here to help.</p>

            <div className="flex flex-col gap-4">
                {helpItems.map((item, index) => (
                    <button
                        key={index}
                        className="flex items-center justify-between w-full p-4 bg-white rounded-xl border border-gray-200 hover:bg-gray-50 transition-colors"
                    >
                        <div className="flex items-center gap-4">
                            {item.icon}
                            <span className="text-base font-medium">{item.title}</span>
                        </div>
                        <ChevronRight className="w-5 h-5 text-kokorko-black-400" />
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Help;
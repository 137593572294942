import React from "react";

const PaymentStep4 = ({ onNext, onBack }) => {
  return (
    <div className="flex justify-center items-center">
      <div className="p-4 shadow-none rounded-none w-full">

      <div className="flex flex-col items-center justify-center mb-5">
        <div className="w-full p-4 bg-white shadow-none rounded-md">
          <h2 className="text-2xl font-bold text-center mb-6">Enter your Bank Account Details</h2>
          <form>
  <div className="flex gap-4 mb-4">
    <div className="flex-1">
      <label className="block text-gray-700 font-medium mb-2">Bank Name</label>
      <input
        type="text"
        placeholder="Name of Bank"
        value={""}
        onChange={()=>{}}
        className="w-full px-4 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>
  </div>
  <div className="flex gap-4 mb-4">
    <div className="flex-1">
      <label className="block text-gray-700 font-medium mb-2">Bank Branch</label>
      <input
        type="text"
        placeholder="Branch of Bank"
        value={""}
        onChange={()=>{}}
        className="w-full px-4 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>
  </div>
    <div className="flex gap-4 mb-4">
    <div className="flex-1">
      <label className="block text-gray-700 font-medium mb-2">Account Name</label>
      <input
        type="text"
        placeholder="Bank Account Name"
        value={""}
        onChange={()=>{}}
        className="w-full px-4 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>
  </div>
  <div className="flex gap-4 mb-4">
    <div className="flex-1">
      <label className="block text-gray-700 font-medium mb-2">Account Number</label>
      <input
        type="text"
        placeholder="Bank Account Number"
        value={""}
        onChange={()=>{}}
        className="w-full px-4 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>
  </div>

  <div className="mb-6">
    <label className="block text-gray-700 font-medium mb-2">Account Type</label>
    <select
      value={""}
      onChange={()=>{}}
      className="w-full px-4 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      <option value="">Select Type of Account</option>
      <option value="Saving">Current</option>
      <option value="Female">Credit</option>
      <option value="Other">Other</option>
    </select>
  </div>
  <div className="flex gap-4 pt-4 pb-10">
    <button
      className="flex-1 py-4 px-4 text-gray-800 bg-gray-100 border border-gray-300 rounded-md hover:bg-gray-200"
      onClick={onBack}
    >
      Cancel
    </button>
    <button
      className="flex-1 py-2 px-4 text-white bg-[#2752A5] rounded-md hover:bg-blue-600"
      onClick={onNext}
    >
      Next
    </button>
  </div>
</form>
         
        </div>
      </div>

  
      </div>
    </div>
  );
};

export default PaymentStep4;

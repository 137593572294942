import React, { useState, useEffect } from 'react';
import { FaAngleLeft, FaChevronDown, FaChevronRight } from 'react-icons/fa6';
import CategoriesModal from '../categoriesModal/CategoriesModal';
import { ChevronRight, X } from 'lucide-react';

// Price range options
const priceRangeOptions = [
    { label: 'Less than GH₵100.00', min: 0, max: 100 },
    { label: 'GH₵101.00 - GH₵200.00', min: 101, max: 200 },
    { label: 'GH₵201.00 - GH₵500.00', min: 201, max: 500 },
    { label: 'GH₵501.00 - GH₵1000.00', min: 501, max: 1000 },
    { label: 'GH₵1000+', min: 1000, max: Infinity }
];

// Discount options
const discountOptions = [
    { label: '10% and above', value: 10 },
    { label: '20% and above', value: 20 },
    { label: '30% and above', value: 30 },
    { label: '40% and above', value: 40 },
    { label: '50% and above', value: 50 },
    { label: '60% and above', value: 60 }
];

// Size options
const sizeOptions = [
    { label: 'XS', value: 'xs' },
    { label: 'S', value: 's' },
    { label: 'M', value: 'm' },
    { label: 'L', value: 'l' },
    { label: 'XL', value: 'xl' },
];

const PriceRange = ({ value, onChange, showBorder = true }) => {
    const [minValue, setMinValue] = useState('');
    const [maxValue, setMaxValue] = useState('');

    const handleInputChange = (e) => {
        const { name, value: inputValue } = e.target;

        if (name === "min") {
            setMinValue(inputValue);
            onChange([Number(inputValue), value[1]]);
        } else if (name === "max") {
            setMaxValue(inputValue);
            onChange([value[0], Number(inputValue)]);
        }
    };

    const handleRangeClick = (min, max) => {
        const adjustedMax = max === Infinity ? Infinity : max;
        setMinValue(min);
        setMaxValue(adjustedMax);
        onChange([min, adjustedMax]);
    };

    const isRangeSelected = (min, max) => {
        return (
            value[0] === min &&
            (max === Infinity ? value[1] === 1000 : value[1] === max)
        );
    };

    return (
        <div className="relative w-full">
            <div className="flex items-center space-x-2 mb-4">
                <input
                    type="number"
                    name="min"
                    value={minValue}
                    onChange={handleInputChange}
                    className="border-2 border-[#B8CAF2] rounded px-2 py-1 w-1/2"
                    placeholder="Min (GH₵)"
                />
                <input
                    type="number"
                    name="max"
                    value={maxValue}
                    onChange={handleInputChange}
                    className="border-2 border-[#B8CAF2] rounded px-2 py-1 w-1/2"
                    placeholder="Max (GH₵)"
                />
            </div>

            <div className="space-y-2">
                {priceRangeOptions.map((option) => (
                    <div
                        key={option.label}
                        className={`flex items-center justify-between cursor-pointer ${showBorder ? "border-2 border-[#B8CAF2] rounded" : ""} p-3`}
                        onClick={() => handleRangeClick(option.min, option.max)}
                    >
                        <label className="cursor-pointer font-semibold">{option.label}</label>
                        <input
                            type="radio"
                            checked={isRangeSelected(option.min, option.max)}
                            readOnly
                            className="cursor-pointer"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

const Discount = ({ value, onChange, showBorder = true }) => {
    const handleDiscountClick = (minDiscount) => {
        onChange([minDiscount, 100]);
    };

    const isDiscountSelected = (discountValue) => {
        return value[0] === discountValue;
    };

    return (
        <div className="relative w-full">
            <div className="space-y-2">
                {discountOptions.map((option) => (
                    <div
                        key={option.value}
                        className={`flex items-center justify-between cursor-pointer ${showBorder ? "border-2 border-[#B8CAF2] rounded" : ""} p-3`}
                        onClick={() => handleDiscountClick(option.value)}
                    >
                        <label className="cursor-pointer font-semibold">{option.label}</label>
                        <input
                            type="radio"
                            checked={isDiscountSelected(option.value)}
                            onChange={() => handleDiscountClick(option.value)}
                            className="cursor-pointer"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

const Sizes = ({ value, onChange, showBorder = true }) => {
    const handleSizeClick = (selectedSize) => {
        const newValue = value.includes(selectedSize)
            ? value.filter((size) => size !== selectedSize)
            : [...value, selectedSize];
        onChange(newValue);
    };

    const isSizeSelected = (sizeValue) => value.includes(sizeValue);

    return (
        <div className="relative w-full">
            <div className="space-y-2">
                {sizeOptions.map((option) => (
                    <div
                        key={option.value}
                        className={`flex items-center justify-between cursor-pointer ${showBorder ? "border-2 border-[#B8CAF2] rounded" : ""} p-3`}
                        onClick={() => handleSizeClick(option.value)}
                    >
                        <label className="cursor-pointer font-semibold">{option.label}</label>
                        <input
                            type="radio"
                            checked={isSizeSelected(option.value)}
                            readOnly
                            className="cursor-pointer"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

const Accordion = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(true);

    return (
        <div className="relative w-full py-2 mb-10">
            <button
                className="w-full text-left flex justify-between items-center text-gray-800 font-medium focus:outline-none"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span>{title}</span>
                <span>{isOpen ? <FaChevronDown /> : <FaChevronRight />}</span>
            </button>
            {isOpen && <div className="mt-4 pl-2">{children}</div>}
        </div>
    );
};

const MobileFilterModal = ({ activeMobileFilter, renderMobileFilterContent, setActiveMobileFilter, setDiscountRange, setPriceRange, toggleMobileFilterModal }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-end z-50">
            <div className={`w-full bg-white rounded-t-xl ${!activeMobileFilter?'h-[60dvh]':" h-[80dvh]"} relative z-50`}>
                <div className="p-4 flex justify-between items-center border-b">
                    <div className='flex items-center gap-4'>
                        {activeMobileFilter && (
                            <button onClick={() => setActiveMobileFilter(null)}>
                                <FaAngleLeft />
                            </button>
                        )}
                        <h2 className="text-lg font-semibold">
                            {activeMobileFilter === 'price'
                                ? 'Price Range'
                                : activeMobileFilter === 'size'
                                    ? 'Size'
                                    : activeMobileFilter === 'discount'
                                        ? 'Discount'
                                        : 'Filters'}
                        </h2>
                    </div>
                    <button onClick={toggleMobileFilterModal}>  <X /></button>
                </div>
                <div className={`p-4 overflow-auto ${!activeMobileFilter?'h-[calc(50dvh-80px)]':" h-[calc(70dvh-80px)]"}`}>
                    {renderMobileFilterContent()}
                </div>
                <div className="p-4 gap-4 flex justify-between items-center border-t">
                    <button
                        onClick={() => {
                            setPriceRange([0, 1000]);
                            setDiscountRange([0, 100]);
                        }}
                        className={`${!activeMobileFilter ? 'text-kokorko-blue-700' : 'text-[#C9C9CC]'}  w-full bg-neutral-grey-300  py-2 px-4 rounded-lg`}
                    >
                        {activeMobileFilter ? "Clear All Filters" : "Clear All"}
                    </button>
                    {activeMobileFilter && (
                        <button
                            onClick={toggleMobileFilterModal}
                            className="bg-kokorko-blue-primary-500 text-white py-2 px-4 rounded-lg w-full"
                        >
                            Apply
                        </button>
                    )}

                </div>
            </div>
        </div>
    )
}

const FilterPane = ({ onFilterChange, showMobileFilterModal, toggleMobileFilterModal }) => {
    const [isModalOpen, setModalOpen] = useState(true);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [priceRange, setPriceRange] = useState([0, 1000]);
    const [discountRange, setDiscountRange] = useState([0, 100]);
    const [activeMobileFilter, setActiveMobileFilter] = useState(null);

    const toggleModal = () => setModalOpen((prevState) => !prevState);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch('/data.json');
                const data = await response.json();
                setCategories(data.categories || []);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, []);

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setSelectedSubCategory(null);
        onFilterChange({ category, subCategory: null, priceRange, discountRange });
    };

    const handlePriceRangeChange = (newRange) => {
        setPriceRange(newRange);
        onFilterChange({
            category: selectedCategory,
            subCategory: selectedSubCategory,
            priceRange: newRange,
            discountRange,
        });
    };

    const handleDiscountChange = (newRange) => {
        setDiscountRange(newRange);
        onFilterChange({
            category: selectedCategory,
            subCategory: selectedSubCategory,
            priceRange,
            discountRange: newRange,
        });
    };

    const renderMobileFilterContent = () => {
        switch (activeMobileFilter) {
            case 'price':
                return <PriceRange showBorder={false} value={priceRange} onChange={handlePriceRangeChange} />;
            case 'size':
                return <Sizes showBorder={false} value={priceRange} onChange={handlePriceRangeChange} />;
            case 'discount':
                return <Discount showBorder={false} value={discountRange} onChange={handleDiscountChange} />;
            default:
                return (
                    <div className="space-y-4">
                        <button
                            onClick={() => setActiveMobileFilter('price')}
                            className="w-full flex items-center justify-between text-left py-2 px-4"
                        >
                            Price Range
                            <ChevronRight />
                        </button>
                        <button
                            onClick={() => setActiveMobileFilter('size')}
                            className="w-full flex items-center justify-between text-left py-2 px-4"
                        >
                            Size
                            <ChevronRight />
                        </button>
                        <button
                            onClick={() => setActiveMobileFilter('discount')}
                            className="w-full flex items-center justify-between text-left py-2 px-4"
                        >
                            Discount
                            <ChevronRight />
                        </button>
                    </div>
                );
        }
    };

    return (
        <div>
            {!showMobileFilterModal ? (
                <div className='hidden md:flex flex-col justify-center gap-12 items-center w-full'>
                    <div className="w-full h-fit rounded-xl border border-[#D6D6D6] overflow-hidden">
                        <h3 className="border-b p-2">Categories</h3>
                        <ul className="hidden">
                            {Array.isArray(categories) &&
                                categories.map((category) => (
                                    <li
                                        key={category.id}
                                        onClick={() => handleCategoryChange(category)}
                                        className="cursor-pointer"
                                    >
                                        {category.name}
                                    </li>
                                ))}
                        </ul>

                        <CategoriesModal isFilter={true} isModalOpen={isModalOpen} toggleModal={toggleModal} isAccordion={true} />
                    </div>

                    <Accordion title="Price Range">
                        <PriceRange value={priceRange} onChange={handlePriceRangeChange} />
                    </Accordion>
                    <Accordion title="Size">
                        <Sizes value={priceRange} onChange={handlePriceRangeChange} />
                    </Accordion>
                    <Accordion title="Discount">
                        <Discount value={discountRange} onChange={handleDiscountChange} />
                    </Accordion>
                </div>
            ) : (
                <MobileFilterModal
                    setPriceRange={setPriceRange}
                    setDiscountRange={setDiscountRange}
                    activeMobileFilter={activeMobileFilter}
                    setActiveMobileFilter={setActiveMobileFilter}
                    renderMobileFilterContent={renderMobileFilterContent}
                    toggleMobileFilterModal={toggleMobileFilterModal}
                />
            )}
        </div>
    );
};

export default FilterPane;

import React, { useState } from 'react'
import watchIcon from '../../assets/icons/WatchIcon_.svg'
import { HiOutlineBuildingStorefront } from 'react-icons/hi2';

export const StoreCard = ({ store, fullList = true }) => {
	const [activeImageIndex, setActiveImageIndex] = useState(0);

	// Ensure store.images is an array, if not, create array from single image
	const images = Array.isArray(store.images) ? store.images : [store.img];

	const nextImage = () => {
		setActiveImageIndex((prev) => (prev + 1) % images.length);
	};

	const previousImage = () => {
		setActiveImageIndex((prev) => (prev - 1 + images.length) % images.length);
	};

	const goToImage = (index) => {
		setActiveImageIndex(index);
	};

	return (
		<div className={`flex-shrink-0 h-64 ${fullList ? "w-full" : "w-[calc(50%-1rem)] md:w-[calc(25%-1.5rem)] "} md:mx-auto`}>
			<div className="store-name flex h-[15%] px-[var(--Font-size-200,0.75rem)] py-[0.375rem] justify-between items-center self-stretch rounded-t-md bg-[var(--Kokorko-Blue-Primary---500,#2752A5)] text-white">
				<h3 className="line-clamp-1">{store.name}</h3>
				{store.isFollowing ? (
					<HiOutlineBuildingStorefront className="w-5 h-5" />
				) : (
					<img src={watchIcon} alt="watch" className="w-5 h-5" />
				)}
			</div>

			<div
				className="relative flex flex-col h-[85%] p-[0.5rem_0.625rem] justify-end items-center gap-[0.625rem] bg-center bg-no-repeat bg-cover transition-all duration-300 ease-in-out"
				style={{ backgroundImage: `url(${images[activeImageIndex]})` }}
			>
				{/* Touch areas for navigation */}
				<div
					className="absolute left-0 top-0 h-full w-1/2 cursor-pointer"
					onClick={previousImage}
				/>
				<div
					className="absolute right-0 top-0 h-full w-1/2 cursor-pointer"
					onClick={nextImage}
				/>

				{/* Dots indicator */}
				<div className={`btn-container ${store.isFollowing ? 'mb-4' : ''} z-10 flex gap-2`}>
					{images.map((_, index) => (
						<button
							key={index}
							onClick={(e) => {
								e.stopPropagation();
								goToImage(index);
							}}
							className={`size-2 rounded-full transition-all duration-300 ${activeImageIndex === index ? 'bg-black' : 'bg-white'}`}
							aria-label={`Go to image ${index + 1}`}
						/>
					))}
				</div>

				{/* Follow button */}
				{!store.isFollowing && (
					<button
						className="store-button z-10 px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
						onClick={(e) => e.stopPropagation()}
					>
						Follow
					</button>
				)}
			</div>
		</div>
	);
};
import React from 'react'
import './MainBanner.css'
import mainBanner from '../../assets/images/main-banner.png'


const MainBanner = () => {
    return (
        <div className='w-full xl:w-[41.7rem] md:h-[23.6rem] lg:h-[23.6rem] md:herosectionBannerShadow main-banner'>
            <img className='h-full w-full' src={mainBanner} alt="main banner" />
        </div>
    )
}

export default MainBanner

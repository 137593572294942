import { Dot } from 'lucide-react'
import React from 'react'
import { statusColors } from '../../lib/constants'

export const OrderItem = ({ order, handleOrderClick, isOrderDetails, isReview = false, hideReview }) => {
	return (
		<div onClick={!isReview ? () => handleOrderClick(order.id) : undefined} className={`bg-white rounded-lg border-2 border-gray-100 p-4 ${!isReview ? 'cursor-pointer' : ''}`}>
			<div className="flex gap-4">
				<img
					src={order.productImage}
					alt={order.productName}
					className="w-32 h-32 rounded-lg object-cover"
				/>
				<div className="flex-1">
					<div className="flex justify-between items-start">
						<div className='md:w-full'>
							<h3 className="flex text-lg font-medium text-gray-900">{order.productName}</h3>
							<p className="text-gray-600 mt-1">{order.variant}</p>
							<div className={`flex items-center mt-2 ${isOrderDetails ? "justify-between" : ""}`}>
								<span className="text-kokorko-black-200 md:text-kokorko-black-300 font-bold">GHS {order.unitPrice}</span>
								<div className="ml-2 bg-kokorko-blue-50 px-2 py-0.5 rounded text-kokorko-blue-600">
									×{order.quantity}
								</div>
							</div>
						</div>
						<div className={`flex-shrink-0 ${isOrderDetails ? "hidden" : "hidden md:flex flex-col"} text-right`}>
							<p className="text-gray-700">Order #{order.orderNumber}</p>
							<p className="text-gray-600 mt-1">Date: {new Date(order.date).toLocaleDateString()}</p>
							{!isReview && <button onClick={() => handleOrderClick(order.id)} className="text-kokorko-blue-600 hover:underline mt-1">See Detail</button>}
						</div>
					</div>
					<div className="flex flex-col md:flex-row justify-between mt-4">
						<div className={`flex items-center bg-transparent md:bg-blue-50 md:px-4 rounded-full ${isReview && 'order-1'} ${isOrderDetails ? "md:order-2" : "order-2 md:order-1"}`}>
							{/* Total Price for Tablets and Desktops */}
							<span className="hidden md:flex items-center text-gray-900 font-semibold">{isOrderDetails ? "Sub total" : "TOTAL"}: GHS {order.total}</span>
							{/* Total Price for Mobile devices */}
							<p className="block md:hidden text-gray-900 font-normal">Sub total: <span className='font-semibold'>GHS {order.total}</span></p>
						</div>

						{!isReview ? (
							<div className={`flex items-center ${isReview ? 'order-2' : 'md:order-2'}  bg-transparent -mt-5 -mb-3 md:my-0 md:bg-blue-50 md:px-4 md:py-1 rounded-full`}>
								<Dot style={{ color: statusColors[order.status] }} className="-ml-4 size-12 block md:hidden" />
								<span className="text-kokorko-black-200 md:text-kokorko-black-900 font-semibold capitalize -ml-3 md:ml-0">{order?.status}</span>
							</div>
						) : (
							<button
								className={`${hideReview ? 'hidden' : 'flex'} bg-kokorko-blue-primary-500 text-white order-2 py-2 px-4 rounded hover:bg-blue-600 w-fit `}
								onClick={handleOrderClick}
							>
								Review Now
							</button>
						)}

					</div>
				</div>
			</div>
		</div>
	)
}

import React from 'react';
import { NotificationItem } from './NotificationItem';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import { FaChevronLeft } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import useFetchData from '../../hooks/useFetchData';

const Notifications = () => {
    const { data: notifications, error, loading } = useFetchData('/data.json', 'notifications');
    const navigate = useNavigate();

    const handleGoBack = () => navigate(-1);

    if (loading) return <div>Loading...</div>;
    if (error || !notifications.length) {
        return (
            <div>
                {error && <p>Sorry, an error occurred while loading data. Please reload the page.</p>}
                {!notifications.length && <p>No notifications available.</p>}
            </div>
        );
    }

    return (
        <div className="max-w-3xl mx-auto p-4 mt-24 pb-40">
            <Breadcrumb />

            <div className='flex items-center gap-2 mb-6'>
                <FaChevronLeft
                    className='block cursor-pointer'
                    onClick={handleGoBack}
                />

                <h1 className="text-2xl font-bold">Notifications</h1>
            </div>
            <div className="bg-white rounded-lg shadow">
                {notifications?.map((notification, index) => (
                    <div className="px-4" key={index}>
                        <NotificationItem {...notification} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Notifications;
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthRedirect } from '../context/AuthRedirectContext';
import { fetchCurrentUserAsync } from '../store/slices/authSlice';

const ProtectedRoute = ({ children, requiredRoles = [] }) => {
    const dispatch = useDispatch();
    const { isAuthenticated, user, status, userRole } = useSelector((state) => state.auth);
    const location = useLocation();
    const { saveIntendedDestination } = useAuthRedirect();

    // Debug logging at the start of the component
    useEffect(() => {
        console.group('ProtectedRoute Debug');
        console.log('Is Authenticated:', isAuthenticated);
        console.log('User Role:', userRole);
        console.log('Required Roles:', requiredRoles);
        console.log('Current User:', user);
        console.log('Current Location:', location.pathname);
        console.groupEnd();
    }, [isAuthenticated, userRole, requiredRoles, user, location]);

    // Fetch current user on mount if not already loaded
    useEffect(() => {
        if (!user && isAuthenticated) {
            dispatch(fetchCurrentUserAsync());
        }
    }, [dispatch, user, isAuthenticated]);

    // Loading state
    if (status === 'loading') {
        return <div>Loading...</div>; 
    }

    // Check authentication and role-based access
    if (!isAuthenticated) {
        // Save the intended destination before redirecting
        saveIntendedDestination(location.pathname);
        // Redirect to login page
        return <Navigate to="/auth" state={{ from: location }} replace />;
    }

    // Specific route role validation
    const isSellerRoute = location.pathname.includes('/profile/seller-overview');
    const isBuyerRoute = location.pathname.includes('/profile/overview');

    // Additional role-based route protection
    if (isSellerRoute && userRole !== 'seller') {
        console.error('Unauthorized access to seller route');
        return <Navigate to="/unauthorized" replace />;
    }

    if (isBuyerRoute && userRole !== 'buyer') {
        console.error('Unauthorized access to buyer route');
        return <Navigate to="/unauthorized" replace />;
    }

    // If required roles are specified and current role doesn't match
    if (requiredRoles.length > 0 && !requiredRoles.includes(userRole)) {
        return <Navigate to="/unauthorized" replace />;
    }

    // Detailed role checking with verbose logging
    const isRoleAllowed = requiredRoles.length === 0 || 
        requiredRoles.some(role => {
            console.log(`Checking role: ${role} against user role: ${userRole}`);
            return role === userRole;
        });

    if (!isRoleAllowed) {
        console.error('Access Denied Details:', {
            userRole,
            requiredRoles,
            isRoleAllowed
        });

        // Redirect to unauthorized page or show error
        return <Navigate to="/unauthorized" replace />;
    }

    return children;
};

export default ProtectedRoute;
import React, { useState, useEffect, useRef } from 'react';
import { PiBellBold } from "react-icons/pi";
import { NotificationModal } from './NotificationModal';

export const NotificationIcon = ({ notifications }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const modalRef = useRef(null);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		handleResize();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		if (isOpen) {
			document.addEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isOpen]);

	return (
		<>
			<button
				onClick={() => setIsOpen(!isOpen)}
				className="p-2 text-basewhite hover:text-gray-100 rounded-full relative"
			>
				<PiBellBold className="size-6" />
				<span className="absolute top-1 right-1 w-2 h-2 bg-red-500 rounded-full"></span>
			</button>

			<NotificationModal
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				isMobile={isMobile}
				notifications={notifications}
				modalRef={modalRef}
			/>
		</>
	);
};
import React, { useState } from "react";
import { MdPersonOutline } from "react-icons/md";
import Modal from "../Modal";
import PaymentStep2 from "./step2";
import PaymentStep4 from "./step4";

const PaymentStep1 = ({onNext}) => {
  const [selected, setSelected] = useState(""); // Tracks the selection
  const [modalOpen, setModalOpen] = useState(false); // Controls modal visibility
  const [stepComponent, setStepComponent] = useState(null); // Holds the component to render in modal
  const [isDone, setIsDone] = useState(false); // Tracks whether to show "Done"

  const handleSelection = (type) => {
    setSelected(type); // Set the selected option
  };

  const closeModal = () => {
    setModalOpen(false); // Close modal
  };

  const handleNextStep2 = () => {
    setStepComponent(
      <PaymentStep2 onNext={() => {
        setIsDone(true); // Update button text
        closeModal(); // Close modal
      }}
       onBack={closeModal} />
    );
  };

  const handleMerchantNextStep2 = () => {
    setStepComponent(
      <PaymentStep4 onNext={() => {
        setIsDone(true); // Update button text
        closeModal(); // Close modal
      }} onBack={closeModal} />
    );
  };


  const handleNext = () => {
    if (selected === "individual") {
      handleNextStep2();
      setModalOpen(true); // Open modal
    } else if (selected === "merchant") {
      handleMerchantNextStep2();
      setModalOpen(true); // Open modal
    }else {
          
      // Show a message if no option is selected
      alert("Please select either Individual or Merchant to proceed.");
    }
   
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-full max-w-lg items-center">
        <h1 className="text-xl font-bold mb-8 text-center">
          Select The Type of Your Mobile Money Account
        </h1>
        <div className="flex space-x-4 mb-8">
          <div
            className={`flex flex-col items-center justify-center w-64 h-32 p-10 border-2 rounded-lg cursor-pointer ${
              selected === "individual" ? "border-blue-500" : "border-gray-300"
            }`}
            onClick={() => handleSelection("individual")}
          >
            <div className="text-blue-500 rounded-lg p-2 border-2">
              <MdPersonOutline className="w-10 h-10" />
            </div>
            <p className="mt-2 text-lg font-medium">Individual Momo</p>
          </div>
          <div
            className={`flex flex-col items-center justify-center w-64 h-32 p-10 border-2 rounded-lg cursor-pointer ${
              selected === "merchant" ? "border-blue-500" : "border-gray-300"
            }`}
            onClick={() => handleSelection("merchant")}
          >
            <div className="text-blue-500 rounded-lg p-2 border-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10 h-10"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 9V6.75A2.25 2.25 0 016 4.5h12a2.25 2.25 0 012.25 2.25V9M3.75 9H20.25M3.75 9V19.5a2.25 2.25 0 002.25 2.25h12a2.25 2.25 0 002.25-2.25V9M9 13.5h6M9 17.25h6"
                />
              </svg>
            </div>
            <p className="mt-2 text-lg font-medium">Merchant</p>
          </div>
        </div>

        <div className="flex gap-4 pt-4 pb-5">
          <button
            className="flex-1 py-4 px-4 text-gray-800 bg-gray-100 border border-gray-300 rounded-md hover:bg-gray-200"
            onClick={() => {}}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="flex-1 py-2 px-4 text-white bg-[#2752A5] rounded-md hover:bg-blue-600"
            onClick={isDone? onNext : handleNext}
          >
            {isDone ? "Done" : "Next"}
          </button>
        </div>
      </div>

      {/* Modal Component */}
      <Modal showModal={modalOpen} onClose={closeModal} size="xxl">
        {stepComponent}
      </Modal>
    </div>
  );
};

export default PaymentStep1;

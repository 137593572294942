import React, { useState, useEffect } from 'react';
import './DiscoveriesPage.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ViewAllButton from '../../components/viewAllButton/ViewAllButton';
import ProductCard from '../../components/productsCard/ProductCard';
import HeroNav from '../../components/heroNav/HeroNav';
import BeautyImage from '../../assets/images/bento-images/beauty.png';
import GiftIdeasImage from '../../assets/images/bento-images/gift-ideas.png';
import InfluencerImage from '../../assets/images/bento-images/influencer-collab.png';
import TrendingImage from '../../assets/images/bento-images/trending.png';
import FragranceImage from '../../assets/images/bento-images/fragrance.png';
import JourneyImage from '../../assets/images/bento-images/journey.png';
import GameChangerImage from '../../assets/images/bento-images/game-changer.png';
import BedImage from '../../assets/images/bento-images/bed.png';
import UtensilsImage from '../../assets/images/bento-images/utensils.png';
import BlueWhiteJacketImage from '../../assets/images/bento-images/blue-white-jacket.png';
import BentoEarringsImage from '../../assets/images/bento-images/bento-ear-rings.png';
import BentoNecklaceImage from '../../assets/images/bento-images/bento-necklace.png';

const bentoSections = [
    {
        className: 'bento-top-top-left',
        title: 'Influencer Collabs',
        imageSrc: InfluencerImage,
        alt: 'Influencer Collabs',
    },
    {
        className: 'bento-top-top-middle',
        children: [
            {
                className: 'bento-top-top-middle-top',
                title: 'Gift Ideas',
                imageSrc: GiftIdeasImage,
                alt: 'Gift Ideas',
            },
            {
                className: 'bento-top-top-middle-bottom',
                title: 'Transform Your Beauty',
                imageSrc: BeautyImage,
                alt: 'Beauty',
            },
        ],
    },
    {
        className: 'bento-top-top-right',
        title: 'Trending',
        imageSrc: TrendingImage,
        alt: 'Trending',
    },
    {
        className: 'bento-top-bottom-left',
        title: 'Game Changer Gear',
        imageSrc: GameChangerImage,
        alt: 'Game Changer',
    },
    {
        className: 'bento-top-bottom-middle',
        title: 'Your Journey, Elevated',
        imageSrc: JourneyImage,
        alt: 'Journey',
    },
    {
        className: 'bento-top-bottom-right',
        title: 'Fragrance Finds',
        imageSrc: FragranceImage,
        alt: 'Fragrance',
    },
];

const BentoSection = ({ title, imageSrc, alt, className }) => (
    <div className={className}>
        <h2>{title}</h2>
        <img src={imageSrc} alt={alt} />
    </div>
);

const BentoGrid = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobileView = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        // Check initial screen size
        checkMobileView();

        // Add event listener for window resize
        window.addEventListener('resize', checkMobileView);

        // Cleanup event listener
        return () => window.removeEventListener('resize', checkMobileView);
    }, []);

    // Determine top and bottom slices based on screen size
    const topSections = isMobile
        ? bentoSections.slice(0, 2)
        : bentoSections.slice(0, 3);

    const bottomSections = isMobile
        ? bentoSections.slice(2)
        : bentoSections.slice(3);

    return (
        <div className='bento-top'>
            <div className='bento-top-top'>
                {topSections.map((section, index) => (
                    section.children ? (
                        <div key={index} className={section.className}>
                            {section.children.map((child, childIndex) => (
                                <BentoSection key={childIndex} {...child} />
                            ))}
                        </div>
                    ) : (
                        <BentoSection key={index} {...section} />
                    )
                ))}
            </div>
            <div className='bento-top-bottom'>
                {bottomSections.map((section, index) => (
                    <BentoSection key={index} {...section} />
                ))}
            </div>
        </div>
    );
};

const DiscoveriesPage = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const picks = {
        seasonalPicks: [[28], [29], [30], [31], [32]],
        customerPicks: [[33], [34], [35], [36], [37]],
        techPicks: [[38], [39], [40], [41], [42]],
    };

    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);
            try {
                const response = await axios.get('/data.json');
                setProducts(response.data.products);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        fetchProducts();
    }, []);

    // Helper function to filter products
    const getFilteredProducts = (ids) => products.filter((product) => ids.includes(product.id));

    const renderProductCards = (productGroup) => {
        return productGroup.map((productIds, index) => (
            <div key={index} className={` flex-shrink-0 h-80 w-[calc(50%-1rem)] md:w-[calc(25%-1.5rem)] md:mx-auto`}>
                {getFilteredProducts(productIds).map((product) => (
                    <ProductCard
                        key={product.id}
                        id={product.id}
                        name={product.name}
                        description={product.description}
                        price={product.price}
                        discount={product.discount}
                        image={product.image[0]}
                        category={product.category}
                        rating={product.rating}
                        reviews={product.reviews}
                        showName={true}
                        showDescription={false}
                        showPrice={true}
                        showDiscount={false}
                        showDiscountPercentage={false}
                        showRating={false}
                        showReview={false}
                        showCategory={false}
                        showSeller={false}
                        showButton={false}
                        showCartIcon={true}
                        showHeartIcon={true}
                        className='product-page-card-home'
                    />
                ))}
            </div>
        ));
    };

    if (loading) {
        return <h1>Loading...</h1>;
    }

    if (!products) {
        return (
            <div>
                {error && <p>Sorry, an error occurred loading data. Please reload page.</p>}
            </div>
        );
    }

    return (
        <div className='w-[95%] md:w-[93%] lg:w-4/5 mx-auto mb-60'>
            <div className='discoveries-page-top'>
                <HeroNav />
            </div>

            <div className='discoveries-page-bottom'>
                <BentoGrid />

                <div className='discoveriesPage-divider'></div>

                {/* Seasonal Picks */}
                <div className='seasonal-picks'>
                    <div className='seasonalPicks-header'>
                        <h1>Seasonal Picks</h1>
                        <Link to={'./top-picks'}>
                            <ViewAllButton />
                        </Link>
                    </div>
                    <div className='w-full flex gap-4 md:gap-6 overflow-x-auto mt-4 hideScrollbar'>
                        {renderProductCards(picks.seasonalPicks)}
                    </div>
                </div>

                {/* Customer Favourites */}
                <div className='customer-favourites'>
                    <div className='seasonalPicks-header'>
                        <h1>Customer Favourites</h1>
                        <Link to={'./top-picks'}>
                            <ViewAllButton />
                        </Link>
                    </div>
                    <div className='w-full flex gap-4 md:gap-6 overflow-x-auto mt-4 hideScrollbar'>
                        {renderProductCards(picks.customerPicks)}
                    </div>
                </div>

                <div className='discoveriesPage-divider'></div>

                <div className='bento-bottom'>
                    <div className='bento-bottom-left'>
                        <h2>Discover Your Style</h2>
                        <img src={BlueWhiteJacketImage} alt='jacket' />
                    </div>
                    <div className='bento-bottom-middle'>
                        <div className='bento-bottom-middle-top'>
                            <h2>Upgrade Your Kitchen</h2>
                            <img src={UtensilsImage} alt='utensils' />
                        </div>
                        <div className='bento-bottom-middle-bottom'>
                            <h2>New Arrivals</h2>
                            <div className='bento-bottom-middle-bottom-images'>
                                <img className='earRing-img' src={BentoEarringsImage} alt='ear rings' />
                                <img className='necklace-img' src={BentoNecklaceImage} alt='necklace' />
                            </div>
                        </div>
                    </div>

                    <div className='bento-bottom-right'>
                        <h2>Home Sweet Home</h2>
                        <img src={BedImage} alt='bed' />
                    </div>
                </div>
                <div className='discoveriesPage-divider'></div>

                {/* Tech Essentials */}
                <div className='tech-essentials'>
                    <div className='seasonalPicks-header'>
                        <h1>Tech Essentials</h1>
                        <Link to={'./top-picks'}>
                            <ViewAllButton />
                        </Link>
                    </div>
                    <div className='w-full flex gap-4 md:gap-6 overflow-x-auto mt-4 hideScrollbar'>
                        {renderProductCards(picks.techPicks)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DiscoveriesPage;
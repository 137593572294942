import React, { useState, useEffect } from 'react';
import './BestDeals.css';
import { Link } from 'react-router-dom';
import ViewAllButton from '../../components/viewAllButton/ViewAllButton';
import ProductCard from '../../components/productsCard/ProductCard';
import { v4 as uuidv4 } from 'uuid';

const BestDeals = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [products, setProducts] = useState([]);

    // Define product IDs for the best deals sections
    const bestDealsProductIds = {
        left: [1], // this is the id of the product that will be displayed on the left side of the best deals section
        rightTop: [2], // this is the id of the product that will be displayed on the right side of the best deals section
        rightBottom: [3], // this is the id of the product that will be displayed on the left side of the best deals section
    };

    useEffect(() => {
        const getProduct = async () => {
            setLoading(true);
            try {
                const response = await fetch('/data.json');
                const data = await response.json();

                setProducts(data);
                if (Array.isArray(data.products)) {
                    setProducts(data.products);

                    // console.log('products:', data.products);
                } else {
                    setError('Unexpected data format');
                }
            } catch (error) {
                setError('Error loading data');
            }
            setLoading(false);
        };
        getProduct();
    }, [])

    // Filter products based on provided IDs
    const getFilteredProducts = (ids) => products.filter((product) => ids.includes(product.id));

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!products.length) {
        return (
            <div>
                {error && <p>Sorry, an error occurred loading data. Please reload the page.</p>}
            </div>
        );
    }

    return (
        <div className="h-full xl:h-[55dvh] bestDeals">
            <div className="bestDeals-header">
                <h1>Best Deals for Today</h1>
                <Link to={'./best-deals-all'}>
                    <ViewAllButton />
                </Link>
            </div>

            {/* Products Section */}
            <div className="mt-3 products">
                {/* Left Section */}
                <div className="w-full h-fit md:w-[63%] lg:w-3/5 xl:h-full mx-auto">
                    {getFilteredProducts(bestDealsProductIds.left).map((product) => (
                        <ProductCard
                            key={uuidv4()}
                            id={product.id}
                            name={product.name}
                            description={product.description}
                            price={product.price}
                            discount={product.discount}
                            image={product.image[0]}
                            category={product.category}
                            rating={product.rating}
                            reviews={product.reviews}
                            showName={true}
                            showDescription={true}
                            showPrice={true}
                            showDiscount={true}
                            showDiscountPercentage={true}
                            showRating={false}
                            showReview={false}
                            showCategory={false}
                            showSeller={false}
                            showButton={true}
                            className="p-3 md:p-0 best-deals-left"
                            descriptionStyle="hidden md:flex"
                        />
                    ))}
                </div>

                {/* Right Section */}
                <div className="hidden md:flex w-[37%] md:gap-8 xl:gap-4 h-full flex-col justify-between">
                    {/* Top Right Section */}
                    <div className="right-top">
                        {getFilteredProducts(bestDealsProductIds.rightTop).map((product) => (
                            <ProductCard
                                key={uuidv4()}
                                id={product.id}
                                name={product.name}
                                description={product.description}
                                price={product.price}
                                discount={product.discount}
                                image={product.image[0]}
                                category={product.category}
                                rating={product.rating}
                                reviews={product.reviews}
                                showName={true}
                                showDescription={false}
                                showPrice={true}
                                showDiscount={true}
                                showDiscountPercentage={true}
                                showRating={false}
                                showReview={false}
                                showCategory={false}
                                showSeller={false}
                                showButton={false}
                                className="best-deals-right"
                            />
                        ))}
                    </div>

                    {/* Bottom Right Section */}
                    <div className="right-bottom">
                        {getFilteredProducts(bestDealsProductIds.rightBottom).map((product) => (
                            <ProductCard
                                key={uuidv4()}
                                id={product.id}
                                name={product.name}
                                description={product.description}
                                price={product.price}
                                discount={product.discount}
                                image={product.image[0]}
                                category={product.category}
                                rating={product.rating}
                                reviews={product.reviews}
                                showName={true}
                                showDescription={false}
                                showPrice={true}
                                showDiscount={true}
                                showDiscountPercentage={true}
                                showRating={false}
                                showReview={false}
                                showCategory={false}
                                showSeller={false}
                                showButton={false}
                                className="best-deals-right"
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BestDeals;
import React, { useState } from 'react'
import './LoginForm.css'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google'
import FacebookLogin from '@greatsumini/react-facebook-login'
import FacebookIcon from '../../assets/icons/Facebook-icon.svg'
import GoogleIcon from '../../assets/icons/Google-icon.svg'
import CheckIcon from '../../assets/icons/check-broken.svg'
import EyeIcon from '../../assets/icons/eye-closed.svg'
import PasswordResetForm from '../passwordResetForm/PasswordResetForm'
import { RotatingLines } from 'react-loader-spinner'
import { LuEye } from "react-icons/lu";
import { loginUser, socialLogin } from '../../store/slices/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthRedirect } from '../../context/AuthRedirectContext'


const LoginForm = ({ role }) => {
    const { register, handleSubmit, formState: { errors }, setError } = useForm();
    const [showResetForm, setShowResetForm] = useState(false);
    const [apiErrors] = useState({ email: '', password: '', general: '' }); //store api errors
    const [showPassword, setShowPassword] = useState(false); //toggle password visibility
    const { isLoading } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { intendedDestination, clearIntendedDestination } = useAuthRedirect();


    // form submission function
    const onSubmit = async (data) => {
        try {
            // Explicitly add role to login credentials
            const loginData = {
                ...data,
                role: role || 'buyer' // Default to buyer if not specified
            };

            console.log('Login Form Submit:', loginData);
            const result = await dispatch(loginUser(loginData)).unwrap();

            console.log('Login Result:', result);

            // if login was successful, navigate to intended destination or profile page
            if (result) {
                if (intendedDestination) {
                    navigate(intendedDestination);
                    clearIntendedDestination();
                } else {
                    navigate(`/profile`);
                }
            } else {
                setError('email', {
                    type: 'manual',
                    message: 'Invalid email or password'
                });
            }
        } catch (error) {
            // handle error 
            console.error('Login Error:', error);
            setError('email', {
                type: 'manual',
                message: error.message || 'Something went wrong'
            });
        }
    };

    // Google Login Handler
    const handleGoogleSuccess = async (response) => {
        try {
            const socialData = {
                provider: 'google',
                token: response.access_token
                // Add other relevant data
            };

            await dispatch(socialLogin(socialData)).unwrap();

            // Navigate to intended destination or profile
            if (intendedDestination) {
                navigate(intendedDestination);
                clearIntendedDestination();
            } else {
                navigate(`/profile`);
            }
        } catch (error) {
            console.error('Google Login Failed:', error);
        }
    };

    // Facebook Login Handler
    const handleFacebookResponse = async (response) => {
        try {
            const socialData = {
                provider: 'facebook',
                token: response.accessToken,
                name: response.name,
                email: response.email
                // Add other relevant data
            };

            await dispatch(socialLogin(socialData)).unwrap();

            // Navigate to intended destination or profile
            if (intendedDestination) {
                navigate(intendedDestination);
                clearIntendedDestination();
            } else {
                navigate(`/profile`);
            }
        } catch (error) {
            console.error('Facebook Login Failed:', error);
        }
    };

    const handleGoogleFailure = (response) => {
        console.log('Google Login Failed:', response);
    };

    const googleLogin = useGoogleLogin({
        onSuccess: handleGoogleSuccess,
        onError: handleGoogleFailure,
    });

    // function to show password
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // It errors on the first render preventing users from accessing the form
    // if (error) {
    //     return <div>Error: {error}</div>;
    // }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {!showResetForm ? (
                <div className='login-container'>
                    <div className="oauth-buttons">
                        <button className='google-button' onClick={() => googleLogin()} disabled={isLoading}>
                            <img src={GoogleIcon} alt='Google icon' />
                            <span>Login with Google</span>
                        </button>
                        <div className='facebook-button'>
                            <img src={FacebookIcon} alt='Facebook icon' />
                            <FacebookLogin
                                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                autoLoad={false}
                                fields="name,email,picture"
                                callback={handleFacebookResponse}
                                className='facebook-login'
                            />
                        </div>
                    </div>
                    <div className="or-separator">
                        <span className='or-separator-left'></span><span>OR</span><span className='or-separator-right'></span>
                    </div>
                    <div className='input-fields'>
                        <div className={`input-field ${errors.email || apiErrors.email ? 'error' : ''}`}>
                            <label>Email</label>
                            <div className='email-field'>
                                <input
                                    {...register('email', { required: 'Email is required', pattern: { value: /^\S+@\S+$/i, message: 'Invalid email' } })}
                                    type='email'
                                    placeholder='Enter your email'
                                />
                                <img src={CheckIcon} alt='Check icon' className={`${errors.email ? 'error-icon' : ''}`} />
                            </div>
                            {errors.email && <p className='error-message'>{errors.email.message}</p>}
                            {apiErrors.email && <p className='error-message'>{apiErrors.email}</p>}
                        </div>
                        <div className={`input-field ${errors.password || apiErrors.password ? 'error' : ''}`}>
                            <label>Password</label>
                            <div className='password-field'>
                                <input
                                    {...register('password', { required: 'Password is required' })}
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Enter your password'
                                />
                                <div onClick={togglePasswordVisibility}>
                                    {showPassword ? <LuEye size='1.86rem' className={`eye-icon ${errors.password ? 'error-icon' : ''}`} /> : <img src={EyeIcon} alt='Eye icon' className={`${errors.password ? 'error-icon' : ''}`} />}
                                </div>
                            </div>
                            {errors.password && <p className='error-message'>{errors.password.message}</p>}
                            {apiErrors.password && <p className='error-message'>{apiErrors.password}</p>}
                        </div>
                    </div>
                    {apiErrors.general && <p className="error-message">{apiErrors.general}</p>}
                    <div>
                        <p className='forgot-password cursor-pointer' onClick={() => setShowResetForm(true)}>Forgot your password?{' '}</p>
                    </div>
                    <button className='login-button' type='submit' disabled={isLoading}>
                        {isLoading ? <RotatingLines
                            strokeColor="white"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="24"
                            color="grey"
                            visible={true}
                            ariaLabel="rotating-lines-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        /> : 'Continue'}
                    </button>
                </div>
            ) : (
                <div>
                    <PasswordResetForm />
                </div>
            )}
        </form>
    );
};

export default LoginForm

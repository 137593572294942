import axios from 'axios';

// Create axios instance
const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 10000,
    // headers: {
    //     'Content-Type': 'application/json',
    // }
});

// Request interceptor for adding tokens
api.interceptors.request.use(
    (config) => {
        console.log('API Request:', {
            method: config.method,
            url: config.url,
            data: config.data,
            headers: config.headers
        });
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        console.error('API Request Error:', error);
        return Promise.reject(error);
    }
);

// Add response interceptor for logging
api.interceptors.response.use((response) => {
    console.log('API Response:', {
      status: response.status,
      data: response.data
    });
    return response;
  }, (error) => {
    console.error('API Response Error:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message
    });
    return Promise.reject(error);
  });

export default api;
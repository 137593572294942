import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="min-h-screen  flex justify-center items-center">
      <div className="bg-white w-full max-w-4xl p-6 md:p-10">
        <div className="text-center mb-8 space-y-4">
          <h1 className="text-4xl font-bold text-kokorko-grey-50 py-2 bg-kokorko-blue-primary-500">Kokorko</h1>
          <h2 className="text-2xl font-semibold text-gray-700 mt-10">Terms and Conditions</h2>
        </div>

        {/* Terms and Conditions Content */}
        <div className="text-gray-700 space-y-6 mt-10">
          <section>
            <h3 className="text-lg font-semibold">1. Introduction</h3>
            <p>
              Welcome to Kokorko. These Terms and Conditions ("Terms") govern your
              relationship with the Kokorko website. Please read these Terms carefully
              before using our Service.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-semibold">2. Acceptance of Terms</h3>
            <p>
              By accessing or using the Service, you agree to be bound by these Terms. If
              you disagree with any part of the terms, then you may not access the Service.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-semibold">3. Account Registration</h3>
            <p>
              3.1 Eligibility: To use our Service, you must be at least 18 years old or
              have reached the age of majority in your jurisdiction.
            </p>
            <p>
              3.2 Account Information: When you create an account with us, you must
              provide accurate and complete information at all times. Failure to do so
              may result in immediate termination of your account.
            </p>
            <p>
              3.3 Account Security: You are responsible for safeguarding your password.
              Notify us immediately of any breach of security.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-semibold">4. Use of the Service</h3>
            <p>You agree not to use the Service for illegal or unauthorized purposes.</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Copying or distributing any part of the Service in any medium.</li>
              <li>Using automated systems like robots or spiders to access the Service.</li>
              <li>Transmitting spam or attempting to interfere with system integrity.</li>
            </ul>
          </section>

          <section>
            <h3 className="text-lg font-semibold">5. Purchases</h3>
            <p>
              5.1 Payment Information: You may be asked to supply information relevant to
              your purchase, including your credit card number and billing address.
            </p>
            <p>
              5.2 Pricing and Availability: We reserve the right to change pricing or
              availability of any product or service without prior notice.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;

import React, { useState } from 'react';
import { SlidersHorizontal, X } from 'lucide-react';

const FilterModal = ({
	isOpen,
	onClose,
	onApply,
	selectedFilters,
	options,
	modalTitle = 'Filters',
}) => {
	const [tempFilters, setTempFilters] = useState(selectedFilters);

	// Group filters if they have group property
	const groupedOptions = options.reduce((acc, option) => {
		const group = option.group || 'default';
		if (!acc[group]) {
			acc[group] = [];
		}
		acc[group].push(option);
		return acc;
	}, {});

	const handleToggleFilter = (filterId) => {
		setTempFilters(prev =>
			prev.includes(filterId)
				? prev.filter(id => id !== filterId)
				: [...prev, filterId]
		);
	};

	const handleApply = () => {
		onApply(tempFilters);
		onClose();
	};

	const handleClear = () => {
		setTempFilters([]);
	};

	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-end sm:items-center justify-center">
			<div className="bg-white w-full sm:w-96 rounded-t-2xl sm:rounded-2xl p-6">
				<div className="flex justify-between items-center mb-6">
					<h2 className="text-lg font-semibold">{modalTitle}</h2>
					<X className="cursor-pointer" onClick={onClose} />
				</div>

				<div className="space-y-6">
					{Object.entries(groupedOptions).map(([group, groupOptions]) => (
						<div key={group} className="space-y-4">
							{/* {group !== 'default' && (
								<h3 className="font-medium text-gray-700">{group}</h3>
							)} */}
							{groupOptions.map(option => (
								<label key={option.id} className="flex items-center justify-between space-x-3">
									<span>{option.label}</span>
									<input
										type="checkbox"
										checked={tempFilters.includes(option.id)}
										onChange={() => handleToggleFilter(option.id)}
										className="w-5 h-5 border-2 rounded text-blue-600"
									/>
								</label>
							))}
						</div>
					))}
				</div>

				<div className="flex gap-4 mt-8">
					<button
						onClick={handleClear}
						className="flex-1 py-3 px-4 bg-[#F1F1F1] border border-gray-300 rounded-lg text-gray-700"
					>
						Clear
					</button>
					<button
						onClick={handleApply}
						className="flex-1 py-3 px-4 bg-kokorko-blue-primary-500 text-white rounded-lg"
					>
						Apply
					</button>
				</div>
			</div>
		</div>
	);
};

const Filter = ({
	options = [],
	onFilterChange,
	buttonLabel = 'Filter',
	modalTitle = 'Filters',
	className = '',
	filterButtonClassName = '',
	isFilterModalOpen,
	setIsFilterModalOpen,
	selectedFilters,
	setSelectedFilters,
}) => {
	const handleApplyFilters = (filters) => {
		setSelectedFilters(filters);
		onFilterChange(filters);
	};

	return (
		<div className={className}>
			<div className="flex items-center justify-end mb-4">
				<button
					onClick={() => setIsFilterModalOpen(true)}
					className={`flex items-center justify-start gap-2 text-gray-600 font-semibold ${filterButtonClassName}`}
				>
					<SlidersHorizontal className="text-gray-600" />
					{buttonLabel}
				</button>
			</div>

			<FilterModal
				isOpen={isFilterModalOpen}
				onClose={() => setIsFilterModalOpen(false)}
				onApply={handleApplyFilters}
				selectedFilters={selectedFilters}
				options={options}
				modalTitle={modalTitle}
			/>
		</div>
	);
};

export default Filter;
import React from 'react'
import './HeroBottom.css'
import MainBanner from '../../components/mainBannerCard/MainBanner'
import SideBanner, { BottomSideBanner, TopSideBanner } from '../../components/sideBannerCard/SideBanner'
import { Carousel } from '../../components/carousel/Carousel';

const carouselData = [
    { comp: <MainBanner /> },
    { comp: <TopSideBanner /> },
    { comp: <BottomSideBanner /> },
];
const HeroBottom = () => {
    return (
        <div className='flex w-full'>
            <div className='flex md:hidden w-full h-full'>
                <Carousel data={carouselData} />
            </div>

            <div className='hidden md:flex justify-between mb-12 w-full h-fit'>
                <div className='md:w-3/5 lg:w-[67%] xl:w-full h-fit lg:h-full'>
                    <MainBanner />
                </div>
                <div className='hidden md:flex md:w-[40%] lg:w-[37%] h-full'>
                    <SideBanner />
                </div>
            </div>
        </div>
    )
}

export default HeroBottom

// Password strength evaluation logic
export const evaluateStrength = (password, setPasswordStrength) => {
	if (!password) {
		setPasswordStrength('');
	} else if (password.length < 6) {
		setPasswordStrength('Weak');
	} else if (/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{6,}$/.test(password)) {
		setPasswordStrength('Strong');
	} else if (/^(?=.*[A-Z])(?=.*\d).{6,}$/.test(password)) {
		setPasswordStrength('Medium');
	} else {
		setPasswordStrength('Weak');
	}
};
import {
  Eye,
  EyeOff,
  MapPin,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import React, { useState, useEffect } from "react";
import { SpinnerLoader } from "../common/SpinnerLoader";
import { SuccessModal } from "../common/SuccessModal";
import { tabsConfig } from "../../lib/constants";
import { Verification } from "../Verification";
import { useNavigate } from "react-router-dom";

// Handles phone number verification
const VerificationModal = ({ phoneNumber, onClose, onSubmit }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <Verification contactInfo={phoneNumber} isEmail={false} />

        <div className="flex gap-4 mt-6">
          <button
            onClick={onClose}
            className="flex-1 py-2 text-gray-600 bg-gray-100 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={onSubmit}
            className="flex-1 py-2 text-white bg-kokorko-blue-primary-500 rounded-md"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

// PhoneNumberSection Component
const PhoneNumberSection = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [savedPhoneNumber, setSavedPhoneNumber] = useState("595631886");
  const [isEditing, setIsEditing] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Validates and formats phone number input
  // Removes non-digit characters and limits to 9 digits
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    if (value.length <= 9) {
      setPhoneNumber(value);
    }
  };

  // Initiates verification process
  const handleVerify = () => {
    setShowVerificationModal(true);
  };

  // Handles verification submission
  const handleVerificationSubmit = () => {
    setIsLoading(true);
    setShowVerificationModal(false);

    // Simulates API call with setTimeout
    setTimeout(() => {
      setSavedPhoneNumber(phoneNumber);
      setPhoneNumber("");
      setIsEditing(false);
      setIsLoading(false);
    }, 3000);
  };

  // Enables phone number editing mode
  const handleEdit = () => {
    setIsEditing(true);
    setPhoneNumber(savedPhoneNumber);
  };

  // Verify button is enabled only when phone number is 9 digits
  const isVerifyEnabled = phoneNumber.length === 9;

  return (
    <div className="p-4">
      <div className="max-w-2xl mx-auto">
        {!savedPhoneNumber && !isEditing ? (
          <div className="space-y-4">
            <div>
              <h2 className="text-lg font-medium">Add Your Phone Number</h2>
              <p className="text-gray-600 text-sm">
                Enter your phone number to enhance your account security and get
                personalized updates
              </p>
            </div>
            <div className="flex">
              <span className="p-3 border rounded-l-md bg-gray-50">+233</span>
              <input
                type="text"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                className="flex-1 p-3 border border-l-0 rounded-r-md focus:outline-none"
                placeholder="0123456789"
              />
            </div>
            <button
              onClick={handleVerify}
              disabled={!isVerifyEnabled}
              className={`w-full py-3 rounded-md transition-colors ${
                isVerifyEnabled
                  ? "bg-kokorko-blue-primary-500 text-white"
                  : "bg-[#F1F1F1] text-gray-400"
              }`}
            >
              Verify
            </button>
          </div>
        ) : (
          <div className="bg-gray-50 p-4 rounded-md">
            {isEditing ? (
              <div className="space-y-4">
                <div className="flex">
                  <span className="p-3 border rounded-l-md bg-gray-50">
                    +233
                  </span>
                  <input
                    type="text"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    className="flex-1 p-3 border border-l-0 rounded-r-md focus:outline-none"
                    placeholder="0123456789"
                  />
                </div>
                <button
                  onClick={handleVerify}
                  disabled={!isVerifyEnabled}
                  className={`w-full py-3 rounded-md transition-colors ${
                    isVerifyEnabled
                      ? "bg-kokorko-blue-primary-500 text-white"
                      : "bg-[#F1F1F1] text-gray-400"
                  }`}
                >
                  Verify
                </button>
              </div>
            ) : (
              <div className="flex flex-col justify-between bg-kokorko-grey-50 gap-5 px-6 py-4">
                <span>+233 {savedPhoneNumber}</span>
                <button
                  onClick={handleEdit}
                  className="flex w-fit border border-kokorko-grey-200 rounded text-kokorko-black-300 text-base py-1.5 px-6"
                >
                  Edit
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      {showVerificationModal && (
        <VerificationModal
          phoneNumber={`+233 ${phoneNumber}`}
          onClose={() => setShowVerificationModal(false)}
          onSubmit={handleVerificationSubmit}
        />
      )}

      {isLoading && <SpinnerLoader />}
    </div>
  );
};

// Custom dropdown select component with search functionality
const CustomSelect = ({ value, onChange, options, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <button
        type="button"
        onClick={() => !disabled && setIsOpen(!isOpen)}
        className="w-full p-3 border rounded-md focus:outline-none flex justify-between items-center bg-white"
        disabled={disabled}
      >
        {value}
        <ChevronDown
          size={20}
          className={`transition-transform ${isOpen ? "rotate-180" : ""}`}
        />
      </button>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg">
          {options.map((option) => (
            <button
              key={option}
              type="button"
              className="w-full px-4 py-2 text-left hover:bg-gray-50"
              onClick={() => {
                onChange({ target: { name: "gender", value: option } });
                setIsOpen(false);
              }}
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

// Date picker component with validation for max date
const CustomDatePicker = ({ value, onChange, disabled }) => {
  return (
    <input
      type="date"
      value={value}
      onChange={onChange}
      className="w-full p-3 border rounded-md focus:outline-none"
      disabled={disabled}
      max={new Date().toISOString().split("T")[0]}
    />
  );
};

// Displays saved address details
const AddressCard = ({ address, onEdit }) => (
  <div className="bg-gray-50 p-4 rounded-md mb-4">
    <div className="flex justify-between items-start mb-4">
      <div className="flex items-start gap-2">
        <MapPin className="mt-1" size={20} />
        <div>
          <p>{address.streetAddress}</p>
          <p>{address.city}</p>
          <p>{address.region}</p>
          <p>{address.gps}</p>
        </div>
      </div>
      <button
        onClick={() => onEdit(address)}
        className="text-kokorko-blue-primary-500 underline text-sm"
      >
        Edit
      </button>
    </div>
  </div>
);

// Reusable input component that renders different input types
const DynamicInput = ({
  field,
  value,
  onChange,
  isEditing = false,
  showPassword,
  onTogglePassword,
}) => {
  // Render password input with toggle visibility
  if (field.type === "password") {
    return (
      <div className="relative">
        <input
          type={showPassword ? "text" : "password"}
          name={field.name}
          value={value}
          onChange={onChange}
          placeholder={field.placeholder}
          className="w-full p-3 border rounded-md focus:outline-none"
          disabled={!isEditing}
        />
        <button
          type="button"
          onClick={onTogglePassword}
          className="absolute right-3 top-1/2 -translate-y-1/2 text-kokorko-black-400"
        >
          {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
        </button>
      </div>
    );
  }

  // Render custom select component
  if (field.type === "select") {
    return (
      <CustomSelect
        value={value}
        onChange={onChange}
        options={field.options}
        disabled={!isEditing}
      />
    );
  }

  // Render date picker component
  if (field.type === "date") {
    return (
      <CustomDatePicker
        value={value}
        onChange={onChange}
        disabled={!isEditing}
      />
    );
  }

  // Render default text input
  return (
    <input
      type={field.type}
      name={field.name}
      value={value}
      onChange={onChange}
      placeholder={field.placeholder}
      className="w-full p-3 border rounded-md focus:outline-none"
      disabled={!isEditing}
    />
  );
};

// Main settings page component
const Settings = () => {
  const [activeTab, setActiveTab] = useState(Object.keys(tabsConfig)[0]);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState({});
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [editingAddress, setEditingAddress] = useState(null);

  // Initial addresses data
  const [addresses, setAddresses] = useState([
    {
      id: 1,
      streetAddress: "House Number 5, Nii Ashidam Loop",
      city: "Teshie, Atta-Odoi",
      region: "Greater Accra",
      gps: "GZ- 101 232 31 - 0",
    },
  ]);

  // Initialize form data from tabsConfig
  const [formData, setFormData] = useState(() => {
    const initialData = {};
    Object.values(tabsConfig)
      .flat()
      .forEach((field) => {
        initialData[field.name] = field.defaultValue;
      });
    return initialData;
  });

  const navigate = useNavigate();

  // Check viewport size for mobile/desktop view
  useEffect(() => {
    const checkViewport = () => {
      // Check if screen width is less than 768px (mobile breakpoint)
      const isMobile = window.innerWidth < 768;

      // If on mobile, reset active tab to show main menu
      setIsMobileView(isMobile);
      if (isMobile) {
        setActiveTab("");
      }
    };

    checkViewport();

    // Add event listener to handle screen resize
    window.addEventListener("resize", checkViewport);

    // Cleanup function to remove event listener when component unmounts
    return () => window.removeEventListener("resize", checkViewport);
  }, []);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Toggle password field visibility
  const togglePasswordVisibility = (fieldName) => {
    setShowPasswordFields((prev) => ({
      ...prev,
      [fieldName]: !prev[fieldName],
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Simulate API call
    setTimeout(() => {
      setIsLoading(false);
      if (activeTab === "Change Password") {
        setShowSuccess(true);
      } else if (activeTab === "Address") {
        if (editingAddress) {
          setAddresses(
            addresses.map((addr) =>
              addr.id === editingAddress.id
                ? { ...formData, id: addr.id }
                : addr
            )
          );
          setEditingAddress(null);
        } else {
          setAddresses([...addresses, { ...formData, id: Date.now() }]);
        }
        setShowAddressForm(false);
      }
      setIsEditing(false);
    }, 3000);
  };

  // Render address management interface
  const renderAddressTab = () => {
    if (showAddressForm || editingAddress) {
      return (
        <div className="max-w-2xl mx-auto p-4">
          <form onSubmit={handleSubmit} className="space-y-4">
            {tabsConfig.Address.map((field) => (
              <div key={field.name} className="space-y-1">
                <label className="block text-sm text-gray-600">
                  {field.label}
                </label>
                <DynamicInput
                  field={field}
                  value={formData[field.name]}
                  onChange={handleInputChange}
                  isEditing={true}
                />
              </div>
            ))}
            <div className="flex gap-4 pt-4">
              <button
                type="button"
                onClick={() => {
                  setShowAddressForm(false);
                  setEditingAddress(null);
                }}
                className="flex-1 py-2 text-gray-600 bg-gray-100 rounded-md"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="flex-1 py-2 text-white bg-kokorko-blue-primary-500 rounded-md"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      );
    }

    // Render list of addresses and add new address button
    return (
      <div className="max-w-2xl mx-auto p-4">
        {addresses.map((address) => (
          <AddressCard
            key={address.id}
            address={address}
            onEdit={(address) => {
              setEditingAddress(address);
              setFormData(address);
            }}
          />
        ))}
        <button
          onClick={() => {
            setShowAddressForm(true);
            setFormData(
              tabsConfig.Address.reduce(
                (acc, field) => ({
                  ...acc,
                  [field.name]: field.defaultValue,
                }),
                {}
              )
            );
          }}
          className="w-full py-3 text-white bg-kokorko-blue-primary-500 rounded-md flex items-center justify-center gap-2"
        >
          <span>+</span> Add New Address
        </button>
      </div>
    );
  };

  // Render content based on selected tab
  const renderTabContent = (tabName) => {
    // Handle Address tab
    if (tabName === "Address") {
      return renderAddressTab();
    }

    // Handle Phone number tab
    if (tabName === "Phone number") {
      return <PhoneNumberSection />;
    }

    // Get fields for current tab
    const fields = tabsConfig[tabName];

    return (
      <div className="p-4">
        <div className="max-w-2xl mx-auto">
          {/* Show edit button except for Change Password tab */}
          {tabName !== "Change Password" && !isEditing && (
            <div className="flex justify-end mb-4">
              <button
                onClick={() => setIsEditing(true)}
                className="text-kokorko-blue-primary-500 underline text-sm"
              >
                Edit Information
              </button>
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            {fields.map((field) => (
              <div key={field.name} className="space-y-1">
                <label className="block text-sm text-gray-600">
                  {field.label}
                </label>
                <DynamicInput
                  field={field}
                  value={formData[field.name]}
                  onChange={handleInputChange}
                  isEditing={tabName === "Change Password" ? true : isEditing}
                  showPassword={showPasswordFields[field.name]}
                  onTogglePassword={() => togglePasswordVisibility(field.name)}
                />
              </div>
            ))}

            {/* Show action buttons when editing or on Change Password tab */}
            {(isEditing || tabName === "Change Password") && (
              <div className="fixed bottom-0 left-0 right-0 p-4 bg-white border-t md:relative md:border-t-0">
                <div className="flex gap-4 max-w-2xl mx-auto">
                  <button
                    type="button"
                    onClick={() => setIsEditing(false)}
                    className="flex md:hidden flex-1 py-2 text-gray-600 bg-gray-100 rounded-md"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex-1 py-2 text-white bg-kokorko-blue-primary-500 rounded-md"
                  >
                    {tabName === "Change Password" ? "Change Password" : "Save"}
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    );
  };

  // Render mobile view
  if (isMobileView) {
    if (activeTab) {
      return (
        <div>
          {/* Mobile header with back button */}
          <div className="text-kokorko-black-400 p-4 flex items-center mt-24">
            <button
              onClick={() => {
                setActiveTab("");
                setIsEditing(false);
              }}
              className="flex items-center"
            >
              <ChevronLeft size={24} />
              <span className="ml-2 font-semibold">{activeTab}</span>
            </button>
          </div>
          {renderTabContent(activeTab)}
        </div>
      );
    }

    // Mobile menu view
    return (
      <div>
        <div className="text-kokorko-black-400 px-3 py-4 mt-24">
          <div className="flex items-center">
            <button onClick={() => navigate(-1)}>
              <ChevronLeft size={24} />
            </button>
            <span className="ml-2">Settings</span>
          </div>
        </div>
        <div className="divide-y">
          {Object.keys(tabsConfig).map((tabName) => (
            <button
              key={tabName}
              onClick={() => setActiveTab(tabName)}
              className="w-full px-4 py-3 flex items-center justify-between bg-white"
            >
              <span>{tabName}</span>
              <ChevronRight size={20} className="text-gray-400" />
            </button>
          ))}
        </div>
      </div>
    );
  }

  // Desktop view
  return (
    <div className="max-w-6xl mx-auto p-4 mt-28">
      <h1 className="text-2xl font-semibold mb-6">Settings</h1>
      {/* Tab navigation */}
      <div className="border-b mb-6">
        <div className="flex gap-8">
          {Object.keys(tabsConfig).map((tabName) => (
            <button
              key={tabName}
              onClick={() => {
                setActiveTab(tabName);
                setIsEditing(false);
                setShowAddressForm(false);
                setEditingAddress(null);
              }}
              className={`pb-2 -mb-px ${
                activeTab === tabName
                  ? "border-b-2 border-kokorko-red-700 text-kokorko-red-700"
                  : "text-gray-500"
              }`}
            >
              {tabName}
            </button>
          ))}
        </div>
      </div>
      {/* Render active tab content */}
      {activeTab && renderTabContent(activeTab)}

      {/* Loading spinner */}
      {isLoading && <SpinnerLoader />}

      {/* Success modal for password change */}
      {showSuccess && (
        <SuccessModal setShowSuccess={setShowSuccess}>
          <h3 className="text-xl font-semibold mb-2">
            Password Change Successfully
          </h3>
          <p className="text-gray-600 mb-6">Your password has been reset.</p>

          <div className="flex items-center md:flex-col gap-2 md:space-y-3">
            <button
              onClick={() => setShowSuccess(false)}
              className="flex items-center justify-center w-full md:w-4/5 py-2 rounded-lg font-medium bg-[#F5F5F5] transition-colors"
            >
              <p>Continue</p>
            </button>
          </div>
        </SuccessModal>
      )}
    </div>
  );
};

export default Settings;

export const routes = {
	home: '/',
	auth: '/auth',
	cart: '/cart',
	stores: '/stores',
	myFeed: '/my-feed',
	product: '/product',
	products: '/products',
	wishlist: '/wishlist',
	topPicks: '/top-picks',
	bestSellers: '/best-sellers',
	discoveries: '/discoveries',
	notifications: '/notifications',
	profile: '/profile',
	checkout: '/checkout',
}

export const quickLinks = {
	termsConditions: '/terms-and-conditions',
}
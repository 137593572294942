import { ArrowLeft } from "lucide-react";
import React, { useEffect } from "react";
import { MdClose } from "react-icons/md";

const Modal = ({
  showModal,
  onClose,
  title,
  description,
  children,
  size = "md",
  showBackButton = false, 
}) => {
  // Handle Escape Key
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape") onClose();
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => document.removeEventListener("keydown", handleEscapeKey);
  }, [onClose]);

  // Prevent Modal Click Propagation
  const handleModalClick = (e) => e.stopPropagation();

  if (!showModal) return null;

  // TailwindCSS Modal Size Classes
  const modalSize = {
    sm: "max-w-sm",
    md: "max-w-md",
    lg: "max-w-lg",
    xl: "max-w-xl h-full",
    xxl: "max-w-3xl",
    xxlh: "max-w-3xl h-full",
    full: "max-w-full",
    "5xl": "max-w-5xl",
    "7xl": "max-w-7xl",
    "8xl": "max-w-[90rem] h-full",
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black/60 z-50"
      onClick={onClose}
    >
      {/* Modal Content */}
      <div
        className={`bg-white rounded-lg shadow-lg overflow-hidden transform transition-transform duration-300 ${modalSize[size]} w-full max-h-[90%] flex flex-col`}
        onClick={handleModalClick}
      >
        {/* Header */}
        <div className="flex justify-between items-center px-5 py-3">
          {showBackButton ? (
            <>
              <button
                onClick={onClose}
                className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
              >
                <ArrowLeft size={20} />
                <span className="text-sm font-medium">Back</span>
              </button>
              <button
                onClick={onClose}
                className="text-gray-600 hover:text-red-500 rounded-full p-1.5"
              >
                <MdClose size={22} />
              </button>
            </>
          ) : (
            <button
              onClick={onClose}
              className="ml-auto text-gray-600 hover:text-red-500 rounded-full p-1.5"
            >
              <MdClose size={22} />
            </button>
          )}
        </div>

        {/* Scrollable Content */}
        <div className="p-6 overflow-y-auto flex-grow">
          {title && (
            <h3 className="text-lg font-semibold text-gray-800 mb-2">{title}</h3>
          )}
          {description && (
            <p className="text-sm text-gray-600 mb-4">{description}</p>
          )}
          <div className="text-gray-700">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;

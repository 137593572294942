import React, { useState, useEffect, useRef } from 'react'
import ReviewsModal from '../reviewsModal/ReviewsModal'
import axios from 'axios'
import { OrderItem } from '../orders/OrderItem';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { FaChevronLeft } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

const Reviews = () => {
    const [fulfilledOrders, setFulfilledOrders] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [rating, setRating] = useState(0);
    const [recommend, setRecommend] = useState(null);
    const [reviewText, setReviewText] = useState('');
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const navigate = useNavigate();

    const textareaRef = useRef(null);

    const handleGoBack = () => {
        navigate(-1); // Goes back to the previous page
    };

    useEffect(() => {
        const getFulfilledOrders = async () => {
            setLoading(true);
            try {
                const response = await axios.get('/data.json');
                const data = await response.data;

                if (Array.isArray(data.orders)) {
                    const deliveredOrders = data.orders.filter((order) => order.status === 'delivered');
                    setFulfilledOrders(deliveredOrders);
                }

            } catch (error) {
                console.error('Error fetching fulfilled orders:', error);
            } finally {
                setLoading(false);
            }
        }
        getFulfilledOrders();
    }, []);

    // Focus only when the modal opens
    useEffect(() => {
        if (showModal && textareaRef.current) {
            textareaRef.current.focus();
        }
    }, [showModal]);

    if (loading) {
        return <div>Loading...</div>;
    }

    const handleOpenModal = (order) => {
        setSelectedOrder(order);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedOrder(null);
        setRating(0);
        setRecommend(null);
        setReviewText('');
        setIsSubmitted(false);
    };

    const handleRatingChange = (newRating) => {
        setRating(newRating);
    };

    const continueShopping = () => {
        setIsSubmitted(false);
        handleCloseModal();
    };

    const handleSubmitReview = () => {
        try {
            if (rating === 0 || recommend === null || reviewText.trim() === '') {
                alert('Please fill in all fields');
                return;
            }

            const review = {
                orderId: selectedOrder.id,
                rating,
                recommend,
                reviewText,
            };
            console.log('Review Submitted:', review);

            setIsSubmitted(true)

        } catch (error) {
            console.error('Error submitting review:', error);
        }
    };

    const handleGoBackOnMobile = () => {
        setShowModal(false)
    };

    const ModalContent = React.memo(({value, onChange}) => {
        return (
            <div className="relative flex flex-col p-5 h-full">
                <OrderItem
                    order={selectedOrder}
                    key={selectedOrder.id}
                    isReview={true}
                    hideReview={true}
                    isOrderDetails={false}
                    handleOrderClick={() => handleOpenModal(selectedOrder)}
                />

                <div className="flex items-center mt-4 gap-2">
                    <h4 className='font-medium'>Your Rating:</h4>
                    <div className="flex gap-2">
                        {[1, 2, 3, 4, 5].map((value) => (
                            <span
                                key={value}
                                className={`text-3xl cursor-pointer ${value <= rating ? 'text-kokorko-red-400' : 'text-gray-300'}`}
                                onClick={() => handleRatingChange(value)}
                            >
                                ★
                            </span>
                        ))}
                    </div>
                </div>

                <div className="flex justify-start flex-col mt-4">
                    <h4 className="flex font-medium">Would you recommend this merchant to anyone?</h4>
                    <div className="flex gap-4 mt-2">
                        <label className="flex items-center gap-2 cursor-pointer">
                            <input
                                type="radio"
                                name="recommend"
                                value="yes"
                                checked={recommend === 'yes'}
                                onChange={() => setRecommend('yes')}
                                className="cursor-pointer"
                            />
                            Yes
                        </label>
                        <label className="flex items-center gap-2 cursor-pointer">
                            <input
                                type="radio"
                                name="recommend"
                                value="no"
                                checked={recommend === 'no'}
                                onChange={() => setRecommend('no')}
                                className="cursor-pointer"
                            />
                            No
                        </label>
                    </div>
                </div>

                <div className="flex flex-col mt-4">
                    <h4 className="flex font-medium">Your Review:</h4>
                    <textarea
                        // ref={textareaRef}
                        className="w-full h-24 border border-gray-300 rounded p-2 mt-2"
                        value={value}
                        onChange={onChange}
                        placeholder="Write your review here..."
                    />

                </div>

                <div className="flex items-end md:items-center gap-4 mt-auto md:mt-5 w-full h-full">
                    <button
                        className="bg-kokorko-grey-200 w-full py-2 px-4 rounded hover:bg-gray-400"
                        onClick={handleCloseModal}
                    >
                        Cancel
                    </button>
                    <button
                        className="bg-kokorko-blue-primary-500 w-full text-white py-2 px-4 rounded hover:bg-blue-600"
                        onClick={handleSubmitReview}
                    >
                        Submit
                    </button>
                </div>
            </div>
        );
    });

    return (
        <div className="py-8 md:p-5 flex flex-col gap-8">
            <h2 className={`${showModal ? 'flex' : "flex"} items-center gap-3 text-2xl font-semibold`}>
                <FaChevronLeft className='block cursor-pointer' onClick={showModal ? handleGoBackOnMobile : handleGoBack} />
                <p className={`${showModal ? "block md:hidden" : "hidden"} md:hidden`}>Rate Product</p>
                <p className={`${showModal ? "hidden md:block" : "block"}`}>Review Purchases</p>
            </h2>

            {fulfilledOrders.length === 0 ? (
                <p>No fulfilled orders to review.</p>
            ) : (
                <div className={`${showModal ? 'hidden md:flex' : 'flex'} flex-col gap-5`}>
                    {fulfilledOrders.map((order) => (
                        <OrderItem
                            order={order}
                            key={order.id}
                            isReview={true}
                            isOrderDetails={false}
                            handleOrderClick={() => handleOpenModal(order)}
                        />
                    ))}
                </div>
            )}

            {selectedOrder && (
                <>
                    <div className='block md:hidden h-[calc(93dvh-8rem)]'>
                        <ModalContent value={reviewText} onChange={(e) => {
                            setReviewText(e.target.value);
                            // textareaRef.current.focus(); // Re-focus explicitly
                        }}/>
                    </div>
                    <div className={`${isSubmitted ? "block" : " hidden md:block"} `}>
                        <ReviewsModal showModal={showModal} onClose={handleCloseModal} isSubmitted={isSubmitted}>
                            {isSubmitted ? (
                                <div className='flex flex-col text-center items-center h-72 gap-2'>
                                    <IoCheckmarkCircle className='size-16 text-[#1BB66E]' />
                                    <p className='font-bold text-lg text-kokorko-blue-900'>Review Submitted Successfully</p>
                                    <p className='max-w-[390px] text-kokorko-black-300 text-center'>Thank you for your feedback! Your review helps us and other buyers.</p>
                                    <button
                                        className="bg-kokorko-blue-primary-500 w-fit mt-4 text-white py-3 px-5 rounded hover:bg-blue-600"
                                        onClick={continueShopping}
                                    >
                                        Continue Shopping
                                    </button>
                                </div>
                            ) : <ModalContent value={reviewText} onChange={(e) => {
                            setReviewText(e.target.value);
                            // textareaRef.current.focus(); // Re-focus explicitly
                        }}/>}
                        </ReviewsModal>
                    </div>
                </>
            )}
        </div>
    )
}

export default Reviews

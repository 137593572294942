import { ZoomIn, ZoomOut } from "lucide-react";
import React, { useRef, useState } from "react";
import { FiMoreVertical } from "react-icons/fi";
import { PiCameraPlusBold } from "react-icons/pi";
import Modal from "../Modal";

const BusinessStep2 = ({ onBack, onNext }) => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedBanner, setUploadedBanner] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showBannerModal, setShowBannerModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBannerModalOpen, setIsBannerModalOpen] = useState(false);

  
  const fileInputRef = useRef(null);
  const bannerInputRef = useRef(document.createElement("input"));

  const handleUploadClick = () => {
    if (fileInputRef.current) {
        fileInputRef.current.click();
    } else {
        console.error("File input ref is not assigned.");
    }
};


const handleBannerUploadClick = () => {
  if (bannerInputRef.current) {
    bannerInputRef.current.click();
  } else {
    console.error("bannerInputRef is not assigned!");
  }
};



  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage(reader.result);
        setShowModal(true);
        setIsModalOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBannerChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedBanner(reader.result);
        setShowBannerModal(true);
        setIsBannerModalOpen(true); 
      };
      reader.readAsDataURL(file);
    }
  };

  // const handleUploadComplete = () => {
  //   setShowModal(false);
  //   setUploadedImage(null);
  // };

  // const handleBannerUploadComplete = () => {
  //   setShowBannerModal(false);
  //   setUploadedBanner(null);
  // };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsBannerModalOpen(false);
  };

  return (
    <div className="flex items-center justify-center">
      <div className="bg-white p-6 w-full max-w-4xl">
        {/* Title */}
        <h1 className="text-2xl text-center font-semibold text-gray-900 mb-2">
          Let’s Customize Your Store
        </h1>
        <p className="text-gray-600 text-center mb-6">
          Customize your store’s look and feel by adding a logo and a banner.
        </p>

        {/* Banner Upload */}
        <div className="relative bg-blue-100 rounded-lg  h-48 flex items-center justify-center mb-8">
          {uploadedBanner ? (
            <div className="h-48 w-full rounded-lg overflow-hidden">
              <img
                src={uploadedBanner}
                alt="Uploaded Banner"
                className="h-full w-full object-cover"
              />
            </div>
          ) : (
            <div className="text-center">
              <div className="flex items-center justify-center w-12 h-12 mx-auto bg-gray-50 rounded-full">
                <PiCameraPlusBold className="w-6 h-6" />
              </div>
              <button
                onClick={handleBannerUploadClick}
                className="mt-2 border-1 text-sm p-1 pl-2 pr-2 rounded-md bg-gray-50 hover:bg-gray-300 text-gray-600 font-medium"
              >
                Upload Banner
              </button>
              <input
              type="file"
              ref={bannerInputRef}
              className="hidden"
              onChange={handleBannerChange}
            />
            </div>
          )}
        </div>

        {/* Logo Upload and Description */}
        <div className="">
          <div className="flex items-center mb-6">
              {uploadedImage ? (
              //  <div className="flex-shrink-0 flex pb-4 flex-col items-center bg-blue-100">
                <div className="h-48 w-72   pt-4 flex items-center justify-center rounded-md">
                  <img
                    src={uploadedImage}
                    alt="Uploaded"
                    className="h-full w-full object-cover rounded-md transition-transform duration-200"
                  />
                {/* </div> */}
                </div>
              ) : (
                <>
              <div className="flex-shrink-0 flex pb-4 flex-col items-center bg-blue-100">

                  <div className="relative h-18 w-18 pt-4 flex items-center justify-center rounded-md">
                    <div className="flex items-center justify-center w-8 h-8 bg-gray-50 rounded-full">
                      <PiCameraPlusBold className="w-5 h-5" />
                    </div>
                  </div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    className="hidden"
                    onChange={handleImageChange}
                  />
                  <button
                    onClick={handleUploadClick}
                    className="m-3 border-1 text-xs p-1 pl-2 pr-2 rounded-md bg-gray-50 hover:bg-gray-300 text-gray-600 font-medium"
                  >
                    Upload Logo
                  </button>
                  </div>

                </>
              )}
            <div className="ml-4 flex-grow  opacity-40">
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold text-gray-900">Luminar Threads</h3>
                <div className="flex items-center space-x-2">
                  <span className="text-gray-600 text-sm border border-gray-300 rounded-md px-2 py-1">5 Followers</span>
                  <FiMoreVertical className="text-gray-600 w-5 h-5" />
                </div>
              </div>
              <p className="text-gray-500 text-sm mb-1">Clothing & Accessories</p>
              <p className="text-gray-700 text-sm">
                Discover a diverse range of stylish and comfortable T-shirts designed
                to suit every taste. From bold graphics to minimalist designs, we have
                something for everyone. Whether you’re looking for casual everyday wear.
              </p>
            </div>
          </div>
        </div>
          {/* Buttons */}
      <div className="flex justify-center gap-4 pt-4 pb-10">
          <button
            className="py-2 px-20 text-gray-800 bg-gray-100 border border-gray-300 rounded-md hover:bg-gray-200"
            onClick={onBack}
          >
            Back
          </button>
         {
          uploadedImage && uploadedBanner ? (
            <button
            type="submit"
            className="py-2 px-20 text-white bg-[#2752A5] rounded-md hover:bg-blue-600"
            onClick={onNext}
          >
            Next
          </button>
          ) : (
            <button
            type="submit"
            className="py-2 px-20 text-white bg-[#2752A5] rounded-md hover:bg-blue-600"
            disabled
          >
            Skip
          </button>
          )
         }
        </div>
      </div>

      {/* Logo Modal */}
      {showModal && (
        <Modal showModal={isModalOpen} onClose={closeModal} size={"8xl"}>
          <ImagePreviewModal
            image={uploadedImage}
            onClose={() => setShowModal(false)}
            onUpload={closeModal}
            handleImageChange={handleImageChange}
            handleUploadClick={handleUploadClick}
          />
        </Modal>
      )}

      {/* Banner Modal */}
      {showBannerModal && (
        <Modal showModal={isBannerModalOpen} onClose={() => setShowBannerModal(false)} size={"8xl"}>
          <BannerImagePreviewModal
            image={uploadedBanner}
            onClose={() => setShowBannerModal(false)}
            onUpload={closeModal}
            handleImageChange={handleBannerChange}
            handleUploadClick={handleBannerUploadClick}
          />
        </Modal>
      )}
    </div>
  );
};

const BannerImagePreviewModal = ({ image, onClose, onUpload, handleImageChange, handleUploadClick }) => {
  const [zoomLevel, setZoomLevel] = useState(1);

  const handleSliderChange = (e) => setZoomLevel(e.target.value);

  return (
    <div className="flex items-center justify-center">
      <div className="bg-white pb-8 rounded-lg shadow-none">
        {/* Modal Header */}
        <div className="text-center mb-6">
          <p className="text-2xl font-semibold text-gray-900 mb-6">Upload Image</p>
          <p className="text-sm text-gray-500">You’ll image will be cropped to 4:1 ratio.</p>
          <p className="text-sm text-gray-500">Tip: You can zoom and pan to reposition your image.</p>
        </div>

        {/* Image Preview Section */}
        <div className="relative flex border-2 border-gray-300 items-center justify-center h-[200px] w-[700px] rounded-md overflow-hidden mb-6">
          <img
            src={image}
            alt="Uploaded"
            style={{ transform: `scale(${zoomLevel})` }}
            className="w-full h-full object-cover transition-transform duration-200"
          />
          <button
            onClick={() => setZoomLevel((prev) => Math.min(3, prev + 0.1))}
            className="absolute bottom-4 left-4 p-2 bg-white rounded-full hover:bg-gray-200 shadow"
          >
            <ZoomIn size={18} />
          </button>
        </div>

        {/* Zoom Controls */}
        <div className="flex w-full items-center justify-center pr-20  pl-20 pt-0 mb-6">
          <button
            className="text-gray-700 hover:text-gray-900"
            onClick={() => setZoomLevel((prev) => Math.max(1, prev - 0.1))}
          >
            <ZoomOut />
          </button>
          <input
            type="range"
            min="1"
            max="3"
            step="0.1"
            value={zoomLevel}
            onChange={handleSliderChange}
            className="w-full mx-4"
          />
          <button
            className="text-gray-700 hover:text-gray-900"
            onClick={() => setZoomLevel((prev) => Math.min(3, prev + 0.1))}
          >
            <ZoomIn />
          </button>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-center items-center w-full gap-4 mb-4">
          <button
            className="py-2 px-20 text-gray-700 bg-gray-100 border border-gray-300 rounded-md hover:bg-gray-200"
            onClick={handleUploadClick}
          >
            Change Image
          </button>
          <button
            className="py-2 px-20 text-white bg-[#2752A5] rounded-md hover:bg-blue-600"
            onClick={onUpload}
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};


const ImagePreviewModal = ({ image, onClose, onUpload, handleImageChange, handleUploadClick }) => {
  const [zoomLevel, setZoomLevel] = useState(1);

  const handleSliderChange = (e) => setZoomLevel(e.target.value);

  return (
    <div className="flex items-center justify-center">
      <div className="bg-white pb-8 rounded-lg shadow-none">
        {/* Modal Header */}
        <div className="text-center mb-6">
          <p className="text-2xl font-semibold text-gray-900 mb-6">Upload Image</p>
          <p className="text-sm text-gray-500">Your image will be cropped to 1:1.</p>
          <p className="text-sm text-gray-500">Tip: You can zoom and pan to reposition your image.</p>
        </div>

        {/* Image Preview Section */}
        <div className="relative flex border-2 border-gray-300 items-center justify-center h-[500px] w-[500px] rounded-md overflow-hidden mb-6">
          <img
            src={image}
            alt="Uploaded"
            style={{ transform: `scale(${zoomLevel})` }}
            className="w-full h-full object-cover transition-transform duration-200"
          />
          <button
            onClick={() => setZoomLevel((prev) => Math.min(3, prev + 0.1))}
            className="absolute bottom-4 left-4 p-2 bg-white rounded-full hover:bg-gray-200 shadow"
          >
            <ZoomIn size={18} />
          </button>
        </div>

        {/* Zoom Controls */}
        <div className="flex w-full items-center justify-center p-8 pt-0 mb-6">
          <button
            className="text-gray-700 hover:text-gray-900"
            onClick={() => setZoomLevel((prev) => Math.max(1, prev - 0.1))}
          >
            <ZoomOut />
          </button>
          <input
            type="range"
            min="1"
            max="3"
            step="0.1"
            value={zoomLevel}
            onChange={handleSliderChange}
            className="w-full mx-4"
          />
          <button
            className="text-gray-700 hover:text-gray-900"
            onClick={() => setZoomLevel((prev) => Math.min(3, prev + 0.1))}
          >
            <ZoomIn />
          </button>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-center items-center w-full gap-4 mb-4">
          <button
            className="py-2 px-20 text-gray-700 bg-gray-100 border border-gray-300 rounded-md hover:bg-gray-200"
            onClick={handleUploadClick}
          >
            Change Image
          </button>
          <button
            className="py-2 px-20 text-white bg-[#2752A5] rounded-md hover:bg-blue-600"
            onClick={onUpload}
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessStep2;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';


// Rate Limiting Configuration
const RATE_LIMITS = {
    ADD_TO_CART: {
        MAX_ATTEMPTS: 10,      // Max 10 add to cart actions
        WINDOW_MS: 60 * 1000,  // Per minute
    },
    REMOVE_FROM_CART: {
        MAX_ATTEMPTS: 20,      // Max 20 remove actions
        WINDOW_MS: 60 * 1000,  // Per minute
    }
};

// Rate Limiter Class
class RateLimiter {
    constructor(maxAttempts, windowMs) {
        this.maxAttempts = maxAttempts;
        this.windowMs = windowMs;
        this.attempts = [];
    }

    // Check if action is allowed
    isAllowed() {
        const now = Date.now();
        
        // Remove expired attempts
        this.attempts = this.attempts.filter(
            attempt => now - attempt < this.windowMs
        );

        // Check if within limit
        if (this.attempts.length < this.maxAttempts) {
            this.attempts.push(now);
            return true;
        }

        return false;
    }
}

// Rate Limiters
const addToCartLimiter = new RateLimiter(
    RATE_LIMITS.ADD_TO_CART.MAX_ATTEMPTS, 
    RATE_LIMITS.ADD_TO_CART.WINDOW_MS
);

const removeFromCartLimiter = new RateLimiter(
    RATE_LIMITS.REMOVE_FROM_CART.MAX_ATTEMPTS, 
    RATE_LIMITS.REMOVE_FROM_CART.WINDOW_MS
);

// Helper function to get cart from localStorage with expiration
const getLocalCart = () => {
    const cartData = localStorage.getItem('cart');
    
    if (cartData) {
        const parsedCart = JSON.parse(cartData);
        
        // Check cart expiration (30 days)
        const THIRTY_DAYS = 30 * 24 * 60 * 60 * 1000;
        const isExpired = (Date.now() - parsedCart.createdAt) > THIRTY_DAYS;
        
        if (isExpired) {
            // Clear expired cart
            localStorage.removeItem('cart');
            return { 
                items: [], 
                guestId: uuidv4(),
                createdAt: Date.now()
            };
        }
        
        return parsedCart;
    }
    
    // Create new cart if none exists
    return { 
        items: [], 
        guestId: uuidv4(),
        createdAt: Date.now()
    };
};

// Helper function to save cart to localStorage
const saveLocalCart = (cart) => {
    // Ensure createdAt exists
    if (!cart.createdAt) {
        cart.createdAt = Date.now();
    }
    localStorage.setItem('cart', JSON.stringify(cart));
};


// Async thunk for fetching cart items
export const fetchCartItems = createAsyncThunk(
    'cart/fetchCartItems',
    async (_, { getState, rejectWithValue }) => {
        try {
            const { auth } = getState();

            // if not authenticated, return guest cart or local cart items
            if (!auth.isAuthenticated) {
                const localCart = getLocalCart();
                if (localCart.items.length > 0) {
                    return localCart.items;
                } else {
                    return [];
                }
            }

            // // if authenticated, return user cart items
            // const response = await axios.get('/api/cart', {
            //     headers: {
            //         'Authorization': `Bearer ${auth.token}`
            //     }
            // });

            // if (!response.ok) {
            //     throw new Error('Failed to fetch cart items');
            // }
            
            // return response.data;

            // if authenticated, return user cart items form data.json
            if (auth.isAuthenticated) {
                const response = await axios.get('/data.json');
            const cartData = response.data.cart;

            // filter cart items for the logged in user
            const userCartItems = cartData.filter(cartItem => cartItem.user === auth.user.id);

             // Map cart items to include a unique cartItemId for React rendering
            const mappedCartItems = userCartItems.map(item => ({
                ...item,
                cartItemId: `cart_${item.id}`,
                addedAt: new Date().toISOString()
            }));

            return mappedCartItems;
            }
        } catch (error) {
            return rejectWithValue(error.response.data || 'Failed to fetch cart items');
        }
    }
);

// Async thunk for adding item to cart with rate limiting
export const addToCart = createAsyncThunk(
    'cart/addToCart',
    async (item, { getState, rejectWithValue, dispatch }) => {
        try {
            // Rate limit check
            if (!addToCartLimiter.isAllowed()) {
                return rejectWithValue('Too many add to cart attempts. Please try again later.');
            }

            const { auth, cart } = getState();
            const localCart = getLocalCart();

            // Check if item already exists in cart
            const existingItemIndex = cart.items.findIndex(
                cartItem => cartItem.id === item.id
            );

            if (existingItemIndex !== -1) {
                // If item exists, update its quantity
                const updatedItems = [...cart.items];
                updatedItems[existingItemIndex] = {
                    ...updatedItems[existingItemIndex],
                    quantity: updatedItems[existingItemIndex].quantity + item.quantity
                };

                // Update local storage or backend
                if (auth.isAuthenticated) {
                    await fetch('/api/cart/update', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${auth.token}`
                        },
                        body: JSON.stringify(updatedItems[existingItemIndex])
                    });
                } else {
                    localCart.items = updatedItems;
                    saveLocalCart(localCart);
                }

                return updatedItems[existingItemIndex];
            }

            // Add unique identifier to the item
            const cartItem = { 
                ...item, 
                cartItemId: uuidv4(),
                addedAt: new Date().toISOString()
            };

            if (auth.isAuthenticated) {
                // If logged in, send to backend
                const response = await fetch('/api/cart/add', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.token}`
                    },
                    body: JSON.stringify(cartItem)
                });

                if (!response.ok) {
                    throw new Error('Failed to add item to cart');
                }

                return await response.json();
            } else {
                // If guest, save to localStorage
                localCart.items.push(cartItem);
                saveLocalCart(localCart);
                return cartItem;
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


// Async thunk for syncing guest cart with backend when user logs in
export const syncGuestCartWithBackend = createAsyncThunk(
    'cart/syncGuestCart',
    async (_, { getState, rejectWithValue }) => {
        try {
            const { auth } = getState();
            const localCart = getLocalCart();
            
            // Only sync if user is logged in and there are guest cart items
            if (auth.isAuthenticated && localCart.items.length > 0) {
                const response = await fetch('/api/cart/sync', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.token}`
                    },
                    body: JSON.stringify({
                        guestId: localCart.guestId,
                        items: localCart.items
                    })
                });

                if (!response.ok) {
                    throw new Error('Failed to sync cart');
                }

                const syncedCart = await response.json();
                
                // Clear local storage after successful sync
                localStorage.removeItem('cart');
                
                return syncedCart;
            }
            return null;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Async thunk for removing item from cart with rate limiting
export const removeFromCart = createAsyncThunk(
    'cart/removeFromCart',
    async (cartItemId, { getState, rejectWithValue }) => {
        try {
            // Rate limit check
            if (!removeFromCartLimiter.isAllowed()) {
                return rejectWithValue('Too many remove from cart attempts. Please try again later.');
            }

            const { auth } = getState();
            const localCart = getLocalCart();

            if (auth.isAuthenticated) {
                // If logged in, send to backend
                const response = await fetch(`/api/cart/remove/${cartItemId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${auth.token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to remove item from cart');
                }

                return cartItemId;
            } else {
                // If guest, remove from localStorage
                localCart.items = localCart.items.filter(item => item.cartItemId !== cartItemId);
                saveLocalCart(localCart);
                return cartItemId;
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        items: [],
        ...getLocalCart(),
        total: 0,
        loading: false,
        status: 'idle',
        error: null,
        rateLimitError: null
    },
    reducers: {
        updateItemQuantity: (state, action) => {
            const { cartItemId, quantity } = action.payload;
            const itemIndex = state.items.findIndex(item => item.cartItemId === cartItemId);
            if (itemIndex !== -1) {
                state.items[itemIndex].quantity = Math.max(1, quantity);
            }

            // Recalculate total
            state.total = (state.items || []).reduce(
                (total, item) => total + (item.price * item.quantity), 
                0
            );
        },
        clearCart: (state) => {
            state.items = [];
            state.total = 0;
        },
        removeItem: (state, action) => {
            const id = action.payload;
            state.items = state.items.filter(item => item.cartItemId !== id);
            state.total = state.items.reduce((total, item) => total + (item.price * item.quantity), 0);
        },
        clearRateLimitError: (state) => {
            state.rateLimitError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCartItems.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCartItems.fulfilled, (state, action) => {
                state.loading = false;
                state.items = action.payload || [];
                state.total = (state.items || []).reduce((total, item) => total + (item.price * item.quantity), 0);
            })
            .addCase(fetchCartItems.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.items = [];
                state.total = 0;
            })
            .addCase(addToCart.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addToCart.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const existingItemIndex = state.items.findIndex(item => item.cartItemId === action.payload.cartItemId);
                if (existingItemIndex !== -1) {
                    state.items[existingItemIndex] = action.payload;
                } else {
                    state.items.push(action.payload);
                }
                state.total = (state.items || []).reduce((total, item) => total + (item.price * item.quantity), 0);

                // Update localStorage for guest users
                saveLocalCart({
                    ...state,
                    createdAt: state.createdAt || Date.now()
                });

                // Clear any previous rate limit error
                state.rateLimitError = null;
            })
            .addCase(addToCart.rejected, (state, action) => {
                state.rateLimitError = action.payload;
                state.status = 'failed';
                state.error = action.payload;
            })

            // Remove from Cart
            .addCase(removeFromCart.fulfilled, (state, action) => {
                state.items = state.items.filter(
                    item => item.cartItemId !== action.payload
                );

                // Recalculate total
                state.total = (state.items || []).reduce(
                    (total, item) => total + (item.price * item.quantity), 
                    0
                );

                // Update localStorage for guest users
                saveLocalCart({
                    ...state,
                    createdAt: state.createdAt || Date.now()
                });

                // Clear any previous rate limit error
                state.rateLimitError = null;
            })
            .addCase(removeFromCart.rejected, (state, action) => {
                state.rateLimitError = action.payload;
            })
            .addCase(syncGuestCartWithBackend.fulfilled, (state, action) => {
                if (action.payload) {
                    // Update state with synced cart from backend
                    state.items = action.payload;
                    state.total = action.payload.reduce(
                        (total, item) => total + (item.price * item.quantity), 
                        0
                    );
                }
            })
            
    }
});

export const { 
    updateItemQuantity, 
    clearCart, 
    clearRateLimitError 
} = cartSlice.actions;

export default cartSlice.reducer;
import React from "react";
import CircularProgress from "./CircularProgress";

export const SpinnerLoader = () => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-md w-1/2 md:max-w-xs mx-4">
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      </div>
    </div>
  );
};

import  { useState, useEffect } from 'react';

const Toast = ({
  timeout,
  onClose = () => {},
  children,
}) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (timeout) {
      const timer = setTimeout(() => {
        setVisible(false);
        onClose();
      }, timeout);
      return () => clearTimeout(timer);
    }
  }, [timeout, onClose]);

  if (!visible) return null;

  return typeof children === 'function'
    ? children({ visible, setVisible, onClose: () => {
        setVisible(false);
        onClose();
      }})
    : children;
};

export default Toast;
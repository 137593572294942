import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Pagination } from '../common/Pagination';
import { useNavigate } from 'react-router-dom';
import { OrderItem } from './OrderItem';
import { FaChevronLeft } from 'react-icons/fa6';
import Filter from './OrdersFilter';
import { SelectedFilterItem } from './SelectedFilterItem';

const OrdersPage = () => {
    const [orders, setOrders] = useState([]);
    const [filterdOrders, setFilteredOrders] = useState(orders);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('all');
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);

    const navigate = useNavigate();

    const API_URL = '/data.json';

    useEffect(() => {
        const fetchOrders = async () => {
            setLoading(true);
            try {
                const response = await axios.get(API_URL);
                setOrders(response.data.orders);
            } catch (error) {
                setError('Failed to load orders. Please try again.');
                console.error('Error fetching orders:', error);
            } finally {
                setLoading(false);
            }
        };

        if (!orders.length) {
            fetchOrders();
        }

        if (!!orders.length) {
            setFilteredOrders(orders);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orders]);

    const handleGoBack = () => {
        navigate(-1); // Goes back to the previous page
    };

    // handle order click
    const handleOrderClick = (orderId) => {
        // Navigate to the order details page with the selected order ID
        navigate(`/profile/orders/${orderId}`);
    };

    const tabs = [
        { id: 'all', label: 'All' },
        { id: 'in transit', label: 'In Transit' },
        { id: 'delivered', label: 'Delivered' },
        { id: 'cancelled', label: 'Cancelled' },
        { id: 'returns', label: 'Returns & Refunds' }
    ];

    const filterOptions = [
        { id: 'in transit', label: 'In Transit', group: 'Status' },
        { id: 'delivered', label: 'Delivered', group: 'Status' },
        { id: 'cancelled', label: 'Cancelled', group: 'Status' },
        { id: 'returns', label: 'Return & Refunds', group: 'Status' }
    ];

    // A helper function for removing filters
    const handleRemoveFilter = (filterId) => {
        const newFilters = selectedFilters.filter(id => id !== filterId);
        setSelectedFilters(newFilters);
        handleFilterChange(newFilters);
    };

    // A helper function for getting the selected filter label
    const getFilterLabel = (filterId) => {
        const option = filterOptions.find(opt => opt.id === filterId);
        return option?.label || filterId;
    };

    const handleFilterChange = (selectedFiltersArr) => {
        if (selectedFiltersArr[0] === 'all') {
            setFilteredOrders(orders);
        } else {
            // Filter orders based on selected filters
            const filteredOrders = orders.filter(order => {
                if (selectedFiltersArr.length === 0) return true;
                return selectedFiltersArr.includes(order.status.toLowerCase());
            });

            setFilteredOrders(filteredOrders)
        }
    };

    const selectTab = (tabId) => {
        setActiveTab(tabId);

        handleFilterChange([tabId]);
    };

    if (loading) return <div className="text-center py-8">Loading...</div>;
    if (error) return <div className="text-center py-8 text-red-600">{error}</div>;

    return (
        <div className="max-w-5x mx-auto px-2 lg:px-6 py-8">
            {/* Page title on large screens */}
            <div className='hidden md:flex items-center gap-2 -mt-8 mb-4'>
                <FaChevronLeft className='block md:hidden cursor-pointer' onClick={handleGoBack} />
                <h1 className='text-kokorko-black-900 font-bold text-lg'>Orders</h1>
            </div>

            {/* Page header on mobile devices */}
            <div className='flex md:hidden items-center justify-between'>
                <div className='flex items-center gap-2 mb-4'>
                    <FaChevronLeft className='block md:hidden cursor-pointer' onClick={handleGoBack} />
                    <h1 className='text-kokorko-black-900 font-bold text-lg'>Orders</h1>
                </div>

                <Filter
                    options={filterOptions}
                    onFilterChange={handleFilterChange}
                    buttonLabel="Filters"
                    modalTitle=" Filters"
                    className=""
                    selectedFilters={selectedFilters}
                    isFilterModalOpen={isFilterModalOpen}
                    setIsFilterModalOpen={setIsFilterModalOpen}
                    setSelectedFilters={setSelectedFilters}
                    selectedFilterClassName=''
                    filterButtonClassName="text-kokorko-black-300"
                />
            </div>

            {/* selected filters on Tablets and Desktops */}
            {selectedFilters.length > 0 && (
                <div className='block md:hidden'>
                    <SelectedFilterItem
                        getFilterLabel={getFilterLabel}
                        handleRemoveFilter={handleRemoveFilter}
                        selectedFilters={selectedFilters}
                    />
                </div>
            )}

            {/* Tabs */}
            <div className="hidden md:flex gap-8 items-center py-4 overflow-x-scroll hideScrollbar">
                {tabs.map(tab => (
                    <button
                        key={tab.id}
                        onClick={() => selectTab(tab.id)}
                        className={`flex-shrink-0 px-4 py-2 -mb-px ${activeTab === tab.id
                            ? 'text-kokorko-red-700 border-b-2 border-kokorko-red-700'
                            : 'text-gray-600'
                            }`}>
                        {tab.label}
                    </button>
                ))}
            </div>

            {/* selected filters on Mobile Devices */}
            {selectedFilters.length > 0 && (
                <div className='hidden md:block'>
                    <SelectedFilterItem
                        getFilterLabel={getFilterLabel}
                        handleRemoveFilter={handleRemoveFilter}
                        selectedFilters={selectedFilters}
                    />
                </div>
            )}

            {/* Orders List */}
            <div className="space-y-6">
                {filterdOrders.map(order => (
                    <OrderItem
                        handleOrderClick={handleOrderClick}
                        key={order.id}
                        order={order}
                    />
                ))}
            </div>

            <br />
            <br />

            {/* Pagination */}
            <Pagination
                currentPage={1}
                totalPages={13}
                onPageChange={() => { }}
            />
        </div>
    );
};

export default OrdersPage;
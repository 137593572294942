import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './CheckoutPage.css';
import DeliveryInformation from './DeliveryInformation';
import PaymentInformation from './PaymentInformation';
import OrderSuccess from './ReviewCompleted';
import ReviewOrder from './ReviewOrder';
import { setCurrentStep } from '../../store/slices/checkoutSlice';

const CheckoutPage = () => {
    const dispatch = useDispatch();
    const { currentStep } = useSelector((state) => state.checkout);
    const { items, total } = useSelector((state) => state.cart);

    const steps = [
        { id: 1, label: 'Delivery Information' },
        { id: 2, label: 'Payment Information' },
        { id: 3, label: 'Review Order' },
    ];

    const stepClasses = (step) => {
        if (step.id < currentStep) return 'stepper__item complete';
        if (step.id === currentStep) return 'stepper__item current';
        return 'stepper__item';
    };

    const goToNextStep = () => {
        dispatch(setCurrentStep(Math.min(currentStep + 1, steps.length)));
    };

    const goToPreviousStep = () => {
        dispatch(setCurrentStep(Math.max(currentStep - 1, 1)));
    };

    return (
        <div className="checkout-page p-2">
            <h1 className="text-2xl font-bold mb-1 pt-[5.5rem]">Complete Your Order</h1>
            
            {/* Stepper Component */}
            <ul className="stepper flex justify-between mb-8">
                {steps.map((step) => (
                    <li key={step.id} className={stepClasses(step)}>
                        {step.label}
                    </li>
                ))}
            </ul>

            {/* Step Content */}
            <div className="checkout-content">
                {currentStep === 1 && <DeliveryInformation onContinue={goToNextStep} />}
                {currentStep === 2 && (
                    <PaymentInformation 
                        onBack={goToPreviousStep} 
                        onContinue={goToNextStep} 
                        cartTotal={total}
                        cartItems={items}
                    />
                )}
                {currentStep === 3 && (
                    <ReviewOrder 
                        onBack={goToPreviousStep} 
                        onFinish={() => <OrderSuccess />} 
                        cartTotal={total}
                        cartItems={items}
                    />
                )}
            </div>
        </div>
    );
};

export default CheckoutPage;

import React from "react";

const BusinessStep3 = ({ onNext, onBack }) => {
  return (
    <div className="flex justify-center items-center w-full">
      <div className="w-full max-w-2xl items-center mb-6 p-4 shadow-none rounded-none">
        <h2 className="text-2xl font-semibold mb-4">Where is Your Business Located?</h2>
        <form>
          <div className="mb-4">
            <label className="block mb-2 text-gray-700">Address</label>
            <input type="text" className="w-full p-2 border rounded-lg" placeholder="The Address where your business is located" />
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-gray-700">City</label>
            <input type="text" className="w-full p-2 border rounded-lg" placeholder="The City where your business is located" />
          </div>
          <div className="mb-4">
          <label className="block mb-2 text-gray-700">Region</label>
          <select className="w-full p-2 border rounded-lg">
            <option value="" selected>
              Select the Region where your business is located
            </option>
            <option>Greater Accra</option>
            <option>Other</option>
          </select>
        </div>

          <div className="flex gap-4 pt-4 pb-10">
          <button
            className="flex-1 py-4 px-4 text-gray-800 bg-gray-100 border border-gray-300 rounded-md hover:bg-gray-200"
            onClick={onBack}
          >
            Back
          </button>
          <button type='submit'
            className="flex-1 py-2 px-4 text-white bg-[#2752A5] rounded-md hover:bg-blue-600"
            onClick={onNext}>
            Next
          </button>
        </div>
        </form>
      </div>
    </div>
  );
};

export default BusinessStep3;

import React, { useState, useEffect } from 'react';
import './BestSeller.css';
import ViewAllButton from '../../components/viewAllButton/ViewAllButton';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import ProductCard from '../../components/productsCard/ProductCard';
import { routes } from '../../lib/routes';

const BestSeller = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const location = useLocation();

    // Define product IDs for best sellers
    const bestSellerProductIds = [
        [4],  // bestSellerProductOne
        [5],  // bestSellerProductTwo
        [6],  // bestSellerProductThree
        [10], // bestSellerProductFour
        [11], // bestSellerProductFive
    ];

    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);
            try {
                const response = await axios.get('/data.json');
                const data = response.data.products;

                if (Array.isArray(data) && data.length > 0) {
                    setProducts(data);
                }
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    // Function to filter products based on IDs
    const getBestSellers = (productIds) => {
        return products.filter((product) => productIds.includes(product.id));
    };

    if (loading) {
        return <h1>Loading...</h1>;
    }

    if (!products.length) {
        return (
            <div>
                {error && <p>Sorry, an error occurred loading data. Please reload the page.</p>}
            </div>
        );
    }

    return (
        <div className="w-full h-auto mb-12">
            {/* conditionally render if location is home */}
            {location.pathname === routes.home && (
                <div className="bestSeller-header">
                    <h1>Best Sellers</h1>
                    <Link to={routes.bestSellers}><ViewAllButton /></Link>
                </div>
            )}

            <div className='w-full flex gap-4 md:gap-6 overflow-x-auto mt-4 hideScrollbar mx-auto'>
                {/* Mapping through the bestSellersProductIds to display each group of products */}
                {bestSellerProductIds.map((productIds, index) => (
                    <div key={index} className={` flex-shrink-0 h-80 w-[calc(50%-1rem)] md:w-[calc(25%-1.5rem)] md:mx-auto`}>
                        {getBestSellers(productIds).map((product) => (
                            <ProductCard
                                key={product.id}
                                id={product.id}
                                name={product.name}
                                description={product.description}
                                price={product.price}
                                discount={product.discount}
                                image={product.image[0]}
                                category={product.category}
                                rating={product.rating}
                                reviews={product.reviews}
                                showName={true}
                                showDescription={false}
                                showPrice={true}
                                showDiscount={false}
                                showDiscountPercentage={false}
                                showRating={false}
                                showReview={false}
                                showCategory={false}
                                showSeller={false}
                                showButton={false}
                                showCartIcon={true}
                                showHeartIcon={true}
                                className="product-page-card-home"
                            />
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BestSeller;
import React from "react";

const BusinessStep4 = ({ onNext, onBack }) => {
  return (
    <div className="flex justify-center items-center">
      <div className="p-4 shadow-none rounded-none w-full max-w-lg">
        <h2 className="text-2xl font-semibold mb-6">Your Phone Number</h2>
        <form>
          <div className="mb-6">
            <label className="block mb-2 text-gray-700">Phone Number</label>
            <div className="flex space-x-2">
              <input
                type="text"
                value="+233"
                disabled
                className="w-16 p-2 border rounded-lg text-center bg-white"
              />
              <input
                type="text"
                placeholder="0201234567"
                className="flex-1 p-2 border rounded-lg"
              />
            </div>
          </div>
          <div className="flex gap-4 pt-4 pb-10">
          <button
            className="flex-1 py-4 px-4 text-gray-800 bg-gray-100 border border-gray-300 rounded-md hover:bg-gray-200"
            onClick={onBack}
          >
            Back
          </button>
          <button type='submit'
            className="flex-1 py-2 px-4 text-white bg-[#2752A5] rounded-md hover:bg-blue-600"
            onClick={onNext}>
            Next
          </button>
        </div>
        </form>
      </div>
    </div>
  );
};

export default BusinessStep4;

import React, { useEffect } from 'react';
import { MdClose } from 'react-icons/md';

const CheckoutModal = ({ showModal, onClose, title, description, children, size = "md" }) => {

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  if (!showModal) return null;

  const modalSize = {
    sm: 'max-w-sm',
    md: 'max-w-md',
    lg: 'max-w-lg',
    xl: 'max-w-xl',
    full: 'max-w-full',
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-60 flex justify-center items-center z-50" onClick={onClose}>
      <div
        className={`bg-white rounded-lg shadow-lg overflow-hidden w-full transform transition-all ${modalSize[size]}`}
        onClick={handleModalClick}
      >
        <div className="flex justify-end pr-4 pt-4 items-center bg-white">
          <div className="hover:text-red-500" onClick={onClose}>
            <MdClose className='w-4 h-4 text-gray-700' />
          </div>
        </div> 
        <div className="p-10 pt-2 pb-0">
        <h3 className="text-sm font-semibold text-center">{title}</h3>

          <p className="text-xs mb-4 text-center">{description}</p>
          {children}
        </div>

      </div>
    </div>
  );
};

export default CheckoutModal;

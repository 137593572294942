
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuthRedirect } from '../../context/AuthRedirectContext';
import { useGoogleOAuth } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';

import { signupUser, resetSignupState } from '../../store/slices/signupSlice';
import { socialLogin } from '../../store/slices/authSlice';
import { evaluateStrength } from '../../utils';
import { quickLinks } from '../../lib/routes';

// Import icons and styles
import './SignupForm.css';
import FacebookIcon from '../../assets/icons/Facebook-icon.svg';
import GoogleIcon from '../../assets/icons/Google-icon.svg';
import CheckIcon from '../../assets/icons/check-broken.svg';
import { LuEye, LuEyeOff } from "react-icons/lu";
import { RiErrorWarningLine } from "react-icons/ri";

const SignupForm = ({ role }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { intendedDestination, clearIntendedDestination } = useAuthRedirect();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    
    const { loading, success, apiErrors } = useSelector((state) => state.signup);
    
    const [showPassword, setShowPassword] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState('');

    const isBuyer = role === 'buyer';

    const onSubmit = async (data) => {
        // Reset previous signup state
        dispatch(resetSignupState());

        // Validate password match
        if (data.password !== data.confirmPassword) {
            dispatch(signupUser({
                ...data,
                role: role,
                apiErrors: { confirmPassword: 'Passwords do not match' }
            }));
            return;
        }

        // Remove confirmPassword and add role
        const { confirmPassword, ...signupData } = data;
        signupData.role = role;

        // Additional validation based on role
        if (!isBuyer) {
            // Add seller-specific validation
            if (!signupData.phoneNumber) {
                dispatch(signupUser({
                    ...signupData,
                    apiErrors: { phoneNumber: 'Business phone number is required' }
                }));
                return;
            }
        }

        // Dispatch signup action
        try {
            await dispatch(signupUser(signupData)).unwrap();
            
            // Navigate to onboarding or intended destination
            if (intendedDestination) {
                navigate('/profile/s/onboarding', { 
                    state: { 
                        redirectTo: intendedDestination,
                        userRole: role 
                    } 
                });
                clearIntendedDestination();
            } else {
                navigate('/profile/s/onboarding', {
                    state: {userRole: role}
                });
            }
        } catch (error) {
            // Error handling is now done in the slice
            console.error('Signup error:', error);
        }
    };

    // Redirect on successful signup
    useEffect(() => {
        // Handle any post-signup logic or redirects
        if (success) {
            const redirectPath = intendedDestination || '/profile/s/onboarding';
            navigate(redirectPath, {
                state: { 
                    userRole: role,
                    redirectFrom: 'signup'
                }
            });
            
            // Clear any intended destination
            clearIntendedDestination();
        }
    }, [success, navigate, intendedDestination, clearIntendedDestination, role]);

    const handlePasswordChange = (e) => {
        const input = e.target.value;
        setValue('password', input);
        evaluateStrength(input, setPasswordStrength);
    };

    const handleGoogleSuccess = async (response) => {
        try {
            const { credential } = response;
            await dispatch(socialLogin({ 
                provider: 'google', 
                token: credential,
                role: role  // Pass the selected role
            })).unwrap();
    
            // Similar navigation logic as signup
            navigate('/profile/s/onboarding', {
                state: { 
                    userRole: role,
                    socialSignup: true
                }
            });
        } catch (error) {
            console.error('Google Signup Failed:', error);
            // user-friendly error message goes here
        }
    };

    const handleGoogleFailure = (response) => {
        console.log('Google Login Failed:', response);
    };

    const handleFacebookResponse = async (response) => {
        try {
            await dispatch(socialLogin({
                provider: 'facebook',
                token: response.accessToken,
                role: role
            })).unwrap();
    
            navigate('/profile/s/onboarding', {
                state: { 
                    userRole: role,
                    socialSignup: true
                }
            });
        } catch (error) {
            console.error('Facebook Signup Failed:', error);
            // user-friendly error message goes here
        }
    };
    
    const signupWithGoogle = useGoogleOAuth({
        onSuccess: handleGoogleSuccess,
        onError: handleGoogleFailure,
    });

    if (loading) return <div>Loading...</div>;

    // function to show password
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    return (
        <form className={`flex flex-col h-full ${passwordStrength ? 'mb-10 lg:mb-48 lg:mt-80' : 'mb-10 lg:mb-32 lg:mt-64'}`} onSubmit={handleSubmit(onSubmit)}>
            {/* <h3>Sign up as {role.charAt(0).toUpperCase() + role.slice(1)}</h3> */}
            <div className='mb-10'>
                {isBuyer ? (
                    <h3 className='text-kokorko-black-900 text-xl font-bold py-8'>Start your shopping journey with Kokorko</h3>
                ) : (
                    <div className='flex flex-col items-center justify-center text-kokorko-black-900 text-center'>
                        <h3 className='text-kokorko-black-900 text-xl font-bold'>Create your Kokorko ID to get started.</h3>
                        <p>Enter your personal Information</p>
                    </div>
                )}
            </div>
            <div className='signup-container'>
                <div className="oauth-buttons-signup">
                    <button className='google-button' onClick={signupWithGoogle} disabled={loading}>
                        <img src={GoogleIcon} alt='Google icon' />
                        <span>Sign Up with Google</span>
                    </button>
                    <div className='facebook-button'>
                        <img src={FacebookIcon} alt='Facebook icon' />
                        <FacebookLogin
                            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                            autoLoad={false}
                            fields="name,email,picture"
                            callback={handleFacebookResponse}
                            className='facebook-login'
                        />
                    </div>
                </div>
                <div className="or-separator">
                    <span className='or-separator-left'></span><span>OR</span><span className='or-separator-right'></span>
                </div>
                <div className='input-fields'>

                    {/* User name container */}
                    <div className='flex items-center gap-4'>
                        <div className={`input-field ${errors.firstName || apiErrors.firstName ? 'error' : ''}`}>
                            <label>First Name</label>
                            <div className='email-field'>
                                <input
                                    {...register('firstName', {
                                        required: 'First name is required',
                                        pattern: {
                                            value: /^(?!\s*$)[^\s]+$/,
                                            message: 'First name cannot be empty or contain spaces'
                                        }
                                    })} type='text'
                                    placeholder='Enter your first name'
                                />
                            </div>
                            {errors.firstName && <p className='error-message'>{errors.firstName.message}</p>}
                            {apiErrors.firstName && <p className='error-message'>{apiErrors.firstName}</p>}
                        </div>
                        <div className={`input-field ${errors.email || apiErrors.email ? 'error' : ''} `}>
                            <label>Last Name</label>
                            <div className='email-field'>
                                <input
                                    {...register('lastName', {
                                        required: 'Last name is required',
                                        pattern: {
                                            value: /^(?!\s*$)[^\s]+$/,
                                            message: 'Last name cannot be empty or contain spaces'
                                        }
                                    })} type='text'
                                    placeholder='Enter your last name'
                                />
                            </div>
                            {errors.lastName && <p className='error-message'>{errors.lastName.message}</p>}
                            {apiErrors.lastName && <p className='error-message'>{apiErrors.lastName}</p>}
                        </div>
                    </div>

                    <div className={`input-field ${errors.email || apiErrors.email ? 'error' : ''} `}>
                        <label>{!isBuyer && 'Business '}Email</label>
                        <div className='email-field'>
                            <input
                                {...register('email', { required: 'Email is required', pattern: { value: /^\S+@\S+$/i, message: 'Invalid email' } })}
                                type='email'
                                placeholder={`${isBuyer ? 'Enter your email' : 'Enter your business email'} `}
                            />
                            <img src={CheckIcon} alt='Check icon' className={`${errors.email ? 'error-icon' : ''} `} />
                        </div>
                        {errors.email && <p className='error-message'>{errors.email.message}</p>}
                        {apiErrors.email && <p className='error-message'>{apiErrors.email}</p>}
                    </div>

                    {!isBuyer && (
                        <div className={`input-field ${errors.phone || apiErrors.phone ? 'error' : ''} `}>
                            <label>Business Phone Number</label>
                            <div className='phone-field'>
                                <input
                                    {...register('phoneNumber', {
                                        required: 'Business phone number is required',
                                        pattern: { value: /^[0-9]{10}$/, message: 'Invalid phone number' }
                                    })}
                                    type='tel'
                                    placeholder='Enter your business phone number'
                                />
                            </div>
                            {errors.phoneNumber && <p className='error-message'>{errors.phoneNumber.message}</p>}
                            {apiErrors.phoneNumber && <p className='error-message'>{apiErrors.phoneNumber}</p>}
                        </div>
                    )}

                    <div className={`input-field ${errors.password || apiErrors.password ? 'error' : ''} `}>
                        <label>Password</label>
                        <div className='password-field'>
                            <input
                                {...register('password', {
                                    required: 'Password is required',
                                    minLength: {
                                        value: 8,
                                        message: 'Password must be at least 8 characters'
                                    },
                                    validate: {
                                        hasUpperCase: value => 
                                            /[A-Z]/.test(value) || 'Must include an uppercase letter',
                                        hasLowerCase: value => 
                                            /[a-z]/.test(value) || 'Must include a lowercase letter',
                                        hasNumber: value => 
                                            /[0-9]/.test(value) || 'Must include a number',
                                        hasSpecialChar: value => 
                                            /[!@#$%^&*]/.test(value) || 'Must include a special character'
                                    },
                                    // pattern: {
                                    //     value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&]).{8,}$/,
                                    //     message:
                                    //         'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character',
                                    // },
                                    onChange: handlePasswordChange, // For strength evaluation
                                })}
                                type={showPassword ? 'text' : 'password'}
                                placeholder='Enter your password'
                            />
                            <div onClick={togglePasswordVisibility}>
                                {showPassword ? <LuEye size='1.86rem' className={`eye-icon cursor-pointer ${errors.password ? 'error-icon' : ''} `} /> : <LuEyeOff size='1.86rem' className={`eye-icon cursor-pointer ${errors.password ? 'error-icon' : ''} `} />}
                            </div>
                        </div>
                        {errors.password && <p className='error-message'>{errors.password.message}</p>}
                        {apiErrors.password && <p className='error-message'>{apiErrors.password}</p>}
                    </div>

                </div>
                {/* Display general API errors */}
                {apiErrors.general && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                        <span className="block sm:inline">{apiErrors.general}</span>
                    </div>
                )}

                {/* Password Strength */}
                {passwordStrength && (
                    <div className='mt-2'>
                        <div className='h-2 w-full bg-gray-200 rounded-full overflow-hidden'>
                            <div
                                className={`h-full ${passwordStrength === 'Strong'
                                    ? 'bg-global-green-800'
                                    : passwordStrength === 'Medium'
                                        ? 'bg-yellow-500'
                                        : 'bg-red-500'
                                    } transition-all`}
                                style={{ width: `${passwordStrength === 'Strong' ? 100 : passwordStrength === 'Medium' ? 66 : 33}% ` }}
                            ></div>
                        </div>
                        <p className="text-sm text-kokorko-grey-800 ">
                            Password strength:
                            <span
                                className={` ml-1 ${passwordStrength === 'Strong' ? 'text-global-green-800' : passwordStrength === 'Medium' ? 'text-yellow-600' : 'text-red-600'} `}
                            >
                                {passwordStrength}
                            </span>
                        </p>
                    </div>
                )}

                <TermsCheckboxWithTooltip />

                {/* <button className='login-button' type='submit' disabled={loading}>
                    // {loading ? <RotatingLines
                        strokeColor="white"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="24"
                        color="grey"
                        visible={true}
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    /> : 'Continue'}
                </button> */}

            </div>
        </form>
    )
}

export default SignupForm


const TermsCheckboxWithTooltip = () => {
    const [showTooltip, setShowTooltip] = useState(false);
    const hoverTimeout = useRef(null);

    const handleMouseEnter = () => {
        hoverTimeout.current = setTimeout(() => {
            setShowTooltip(true);
        }, 2000); // Show tooltip after 9 seconds
    };

    const handleMouseLeave = () => {
        clearTimeout(hoverTimeout.current); // Clear timeout on mouse leave
        setShowTooltip(false); // Hide tooltip
    };

    return (
        <div className="relative flex items-start mx-auto">
            <input
                type="checkbox"
                id="terms"
                className="size-5 cursor-pointer border-gray-300 rounded focus:ring-2 focus:ring-indigo-500"
                onChange={(e) => setShowTooltip(!e.target.checked)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
            <label htmlFor="terms" className="ml-2 text-sm text-gray-600">
                I accept the{' '}
                <a
                    href={quickLinks.termsConditions}
                    className="text-blue-600 underline"
                >
                    terms and conditions
                </a>
            </label>

            {/* Tooltip */}
            {showTooltip && (
                <div className="absolute top-6 -left-2 flex items-center bg-white border border-gray-300 shadow-lg rounded p-2 text-sm text-gray-700">
                    {/* Tooltip arrow */}
                    <div className="absolute -top-2 left-2 w-4 h-4 bg-white border-l border-t border-gray-300 rotate-45" />
                    <p className="flex items-center gap-2">
                        <span>
                            <RiErrorWarningLine className="text-kokorko-black-900 size-5" />
                        </span>
                        Please check this box if you want to proceed.
                    </p>
                </div>
            )}
        </div>
    );
};
import React from 'react'
import './Stores.css'
import ViewAllButton from '../../components/viewAllButton/ViewAllButton'
import { Link } from 'react-router-dom'
import { routes } from '../../lib/routes'
import { StoreCard } from './StoreCard'

// Stores data
const stores = [
    {
        name: "Gadget Galaxy",
        images: [
            require('../../assets/stores-images/store-img-watch.jpeg'),
            require("../../assets/stores-images/store-img-shoe.jpeg"),
            require("../../assets/stores-images/store-img-sneakers.jpeg"),
        ],
        isFollowing: true
    },
    {
        name: "Kicks Kingdom",
        images: [
            require("../../assets/stores-images/store-img-shoe.jpeg"),
            require('../../assets/stores-images/store-img-watch.jpeg'),
            require("../../assets/stores-images/store-img-sneakers.jpeg"),
        ], isFollowing: false
    },
    {
        name: "Sneaker Society",
        images: [
            require("../../assets/stores-images/store-img-sneakers.jpeg"),
            require('../../assets/stores-images/store-img-watch.jpeg'),
            require("../../assets/stores-images/store-img-shoe.jpeg"),
        ],
        isFollowing: true
    },
    {
        name: "Tech Haven",
        images: [
            require("../../assets/stores-images/store-img-laptop.jpeg"),
            require("../../assets/stores-images/store-img-shoe.jpeg"),
            require("../../assets/stores-images/store-img-sneakers.jpeg"),
        ],
        isFollowing: false
    },
];

const Stores = ({ fullList }) => {
    return (
        <div className='stores'>
            <div className='stores-header'>
                <h1>Stores</h1>
                <Link to={routes.stores}><ViewAllButton /></Link>
            </div>

            <div className='w-full flex gap-4 md:gap-6 overflow-x-auto mt-4 hideScrollbar'>
                {stores?.map((store, index) => (
                    <StoreCard fullList={fullList} key={index} store={store} />
                ))}
            </div>
        </div>
    )
}

export default Stores;
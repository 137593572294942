import './HeroNav.css'
import React from 'react'
import { RiHome6Line } from "react-icons/ri";
import { FaRegCompass } from "react-icons/fa6";
import { Link, useLocation } from 'react-router-dom';
import { HiOutlineBuildingStorefront } from "react-icons/hi2";
import { routes } from '../../lib/routes';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedButton } from '../../store/slices/heroNavSlice';

const HeroNav = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    // Get the selected button from Redux state
    const selectedButton = useSelector((state) => state.heroNav.selectedButton);

    // Derive selected button from current pathname
    React.useEffect(() => {
        // Remove the first character to get the path name
        const pathname = location.pathname.slice(1);

        // Determine the selected button based on current pathname
        const defaultPathname = pathname === '' ? 'home' : pathname;
        
        // Dispatch the action to update selected button
        dispatch(setSelectedButton(defaultPathname));
    }, [location.pathname, dispatch]);

    // Handle button click
    const handleButtonClick = (buttonName) => {
        dispatch(setSelectedButton(buttonName));
    };

    return (
        <div className='gap-2 md:gap-[1.2rem] heroNavContainer'>
            <Link 
                to={routes.home} 
                className={`text-[15px] md:text-[0.875rem] py-2 px-3 lg:py-[0.6rem] lg:px-4 homeButton ${selectedButton === 'home' ? 'changeColor' : ''}`} 
                onClick={() => handleButtonClick('home')}
            >
                <RiHome6Line className={`size-5 md:size-[1.4rem] homeIcon ${selectedButton === 'home' ? 'changeColor' : ''}`} />
                Home
            </Link>
            <Link 
                to={routes.discoveries} 
                className={`text-[15px] md:text-[0.875rem] py-2 px-3 lg:py-[0.6rem] lg:px-4 discoverButton ${selectedButton === 'discoveries' ? 'changeColor' : ''}`} 
                onClick={() => handleButtonClick('discoveries')}
            >
                <FaRegCompass className={`size-5 md:size-[1.4rem] discoverIcon ${selectedButton === 'discoveries' ? 'changeColor' : ''}`} />
                Discover
            </Link>
            {user? (
                <Link 
                to={routes.myFeed} 
                className={`text-[15px] md:text-[0.875rem] py-2 px-3 lg:py-[0.6rem] lg:px-4 feedButton ${selectedButton === 'my-feed' ? 'changeColor' : ''}`} 
                onClick={() => handleButtonClick('my-feed')}
            >
                <HiOutlineBuildingStorefront className={`size-5 md:size-[1.4rem] storeIcon ${selectedButton === 'my-feed' ? 'changeColor' : ''}`} />
                Feed
            </Link>
            ) : (
                ''
            )}
        </div>
    )
}

export default HeroNav;
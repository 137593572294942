import React from "react";

const PaymentStep2 = ({ onBack, onNext }) => {
  const handleSubmit = (event) => {
    event.preventDefault(); 
    onNext(); 
  };

  return (
    <>
    <div className="flex items-center justify-center">
      <div className="bg-white p-8 w-full max-w-2xl">
        <h2 className="text-xl font-semibold mb-6 text-start">
          Enter your Mobile Money Account Details
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="momoAccountName"
              className="block text-gray-700 font-medium text-sm mb-2"
            >
              Momo Account Name
            </label>
            <input
              type="text"
              id="momoAccountName"
              placeholder="Name Registered on Momo"
              className="w-full px-4 py-3 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="phoneNumber"
              className="block text-gray-700 font-medium text-sm mb-2"
            >
              Phone Number
            </label>

            <div className="flex space-x-2">
              <input
                type="text"
                value="+233"
                disabled
                className="w-16 p-2 py-3 border-2 rounded-lg text-center bg-white"
              />
              <input
                type="text"
                placeholder="0201234567"
                className="flex-1 p-2 py-3 border-2 rounded-lg"
              />
            </div>
          </div>
          <div className="mb-6">
            <label
              htmlFor="idNumber"
              className="block text-gray-700 font-medium text-sm mb-2"
            >
              ID Number
            </label>
            <input
              type="text"
              id="idNumber"
              placeholder="Enter ID Number used for Momo Registration"
              className="w-full px-4 py-3 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="flex gap-4 pt-4 pb-5">
            <button
              type="button"
              className="flex-1 py-4 px-4 text-gray-800 bg-gray-100 border border-gray-300 rounded-md hover:bg-gray-200"
              onClick={onBack}
            >
              Back
            </button>
            <button
              type="submit"
              className="flex-1 py-2 px-4 text-white bg-[#2752A5] rounded-md hover:bg-blue-600"
            >
              Next 
            </button>
          </div>
        </form>
      </div>
    </div>
    </>

  );
};

export default PaymentStep2;

import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../../assets/Footer Logo.svg";
import "./ProfilePage.css";
import { logout } from "../../store/slices/authSlice";
import {
  clearUserProfile,
  fetchUserProfile,
} from "../../store/slices/userProfileSlice";
import { buyerLinks, sellerLinks } from "../../lib/constants";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import RecommendedProducts from "../../containers/RecommendedProducts/RecommendedProducts";
import { Divider } from "../../components/common/Divider";

// Navigation Component
const Navigation = ({ links, onLogout, logo }) => (
  <div className="nav-column">
    {logo && (
      <div onClick={() => logo.navigate("/")} className="sideNavLogo">
        <img src={Logo} alt="logo" />
      </div>
    )}

    {/* Navigation links */}
    <ul>
      {links.map((section, idx) => (
        <React.Fragment key={idx}>
          <div>
            {section.items.map((item, i) => (
              <li key={i} className="flex items-center w-full">
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    `flex items-center gap-2 w-full py-2 px-4  ${
                      isActive
                        ? "active font-semibold bg-[#F3F8FF]"
                        : "text-[14px]"
                    }`
                  }
                >
                  {item?.icon}
                  {item.label}
                </NavLink>
              </li>
            ))}
          </div>
          {/* Add separator line between sections */}
          {idx < links.length - 1 && <hr />}
        </React.Fragment>
      ))}

      <Divider otherStyles="block" />

      <button className="px-4 logout-button" onClick={onLogout}>
        Logout
      </button>
    </ul>
  </div>
);

const ProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve user information and auth status from Redux store
  const { user, isAuthenticated, userRole } = useSelector(
    (state) => state.auth
  );
  const {
    userInfo,
    loading: profileLoading,
    error: profileError,
  } = useSelector((state) => state.userProfile);

  // Add a local state to track initial load
  const [initialLoadAttempted, setInitialLoadAttempted] = useState(false);

  // Debug logging
  useEffect(() => {
    console.group("ProfilePage Debug");
    console.log("Is Authenticated:", isAuthenticated);
    console.log("User:***************", user);
    console.log("User Role:", userRole);
    console.log("User Info:", userInfo);
    console.log("Current Location:", location.pathname);
    console.log("Profile Loading:", profileLoading);
    console.log("Profile Error:", profileError);
    console.log("Initial Load Attempted:", initialLoadAttempted);
    console.groupEnd();
    console.groupEnd();
  }, [
    isAuthenticated,
    user,
    userRole,
    userInfo,
    location,
    profileLoading,
    profileError,
    initialLoadAttempted,
  ]);

  // Fetch user profile and current user on mount
  useEffect(() => {
    if (isAuthenticated && userRole && !initialLoadAttempted) {
      dispatch(fetchUserProfile())
        .then(() => setInitialLoadAttempted(true))
        .catch((error) => {
          console.error("Profile fetch failed:", error);
          setInitialLoadAttempted(true);
        });
    }
  }, [
    dispatch,
    isAuthenticated,
    user,
    userInfo,
    userRole,
    initialLoadAttempted,
  ]);

  // Redirect based on auth status and user role
  useEffect(() => {
    if (!isAuthenticated) {
      // Redirect to auth page if not logged in
      navigate("/auth");
      return;
    }

    // Ensure user role is set before redirecting
    // const storedRole = localStorage.getItem('userRole');

    if (location.pathname === "/profile") {
      // Ensure user role is correctly set before redirecting
      if (userRole === "buyer") {
        navigate("/profile/overview");
      } else if (userRole === "seller") {
        navigate("/profile/seller-overview");
      } else {
        // Fallback or error handling
        console.error("Invalid user role:", userRole);
        navigate("/auth");
      }
    }
  }, [location, navigate, isAuthenticated, userRole]);

  // Handle user logout
  const handleLogout = () => {
    dispatch(logout()); // Dispatch logout action from auth slice
    dispatch(clearUserProfile()); // Clear user profile data
    navigate("/"); // Redirect to home page
  };

  // Render loading state
  if (!isAuthenticated || profileLoading) {
    return <div>Loading...</div>;
  }

  // Render error state
  //   if (profileError) {
  //     return <div>Error: {profileError}</div>;
  //   }

  return (
    <div
      className={`w-[93%] xl:w-4/5 mx-auto mt-4 md:pt-10 profilePage ${
        user?.userRole === "seller" ? "sellerProfilePage" : ""
      }`}
    >
      {/* Add a top margin for spacing */}
      <div className="mt-16" />

      <div className="hidden md:flex items-center justify-start w-full">
        <Breadcrumb />
      </div>

      {/* Render buyer profile page layout */}
      {userRole === "buyer" && (
        <div className="flex-col md:flex-row profilePage-content">
          {/* Pass buyer-specific navigation links to Navigation component */}
          <div className="items-start justify-start order-2 md:order-1 w-full md:w-1/5">
            <Navigation links={buyerLinks} onLogout={handleLogout} />
          </div>
          {/* Outlet renders the content of the selected route */}
          <div className="order-1 md:order-2 w-full xl:px-20 md:w-full content-column ">
            <Outlet />
          </div>
        </div>
      )}

      {/* Render seller profile page layout */}
      {userRole === "seller" && (
        <div className="profilePage-content">
          {/* Pass seller-specific navigation links to Navigation component */}
          <Navigation
            links={sellerLinks}
            onLogout={handleLogout}
            logo={{ navigate }}
          />
          {/* Outlet renders the content of the selected route */}
          <div className="seller-content-column">
            <Outlet />
          </div>
        </div>
      )}

      <div className="w-full">
        <RecommendedProducts />
      </div>
    </div>
  );
};

export default ProfilePage;

import React from "react";

export const Stepper = ({ currentStep, totalSteps, activeStyle, goToStep }) => {

	return (
		<div className="flex w-full items-center justify-center space-x-4">
			{Array.from({ length: totalSteps }, (_, index) => {
				const step = index + 1;
				const isActiveOrCompleted = currentStep >= step;

				return (
					<div
						key={step}
						className={`h-2 w-3/12 rounded-full cursor-pointer transition-all ${isActiveOrCompleted ? activeStyle : "bg-gray-200"}`}
						onClick={() => goToStep(step > currentStep ? currentStep : step)} // only allows going to previous step and not next step
					></div>
				);
			})}
		</div>
	);
};
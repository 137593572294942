import React, { useState } from 'react'
import './HeroCatsMenu.css'
import { IoMenu } from "react-icons/io5";
import { IoChevronDown } from "react-icons/io5";
import CategoriesModal from '../categoriesModal/CategoriesModal';

const HeroCatsMenu = () => {
    const [isModalOpen, setModalOpen] = useState(false);

    // A helper function to toggle the modal open state
    const toggleModal = () => setModalOpen(prevState => !prevState);

    return (
        <div>
            <div className='flex items-center justify-between heroCatsMenu' onClick={toggleModal}>
                <IoMenu size='1.4rem' />
                <div className='hidden lg:flex items-center justify-between gap-2'>
                    <h1>Shop by Categories</h1>
                    <IoChevronDown size='1.4rem' />
                </div>
            </div>
            <CategoriesModal isModalOpen={isModalOpen} toggleModal={toggleModal} />
        </div>
    )
}

export default HeroCatsMenu;

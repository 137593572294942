import './Nav.css'
import React, { useCallback, useMemo } from 'react'
import { HiOutlineHeart } from "react-icons/hi"
import { FiShoppingCart } from "react-icons/fi"
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '../../lib/routes';
import { useSelector } from 'react-redux';
import { IoPersonOutline } from "react-icons/io5";
import { NotificationIcon } from '../notifications/NotificationIcon';

const Nav = () => {
    const user = useSelector((state) => state.auth.user);
    const navigate = useNavigate();
    const cartItems = useSelector((state) => state.cart.items);
    const wishlistItems = useSelector((state) => state.wishlist.items);

    // Calculate total quantity of items in cart
    const totalCartQuantity = useMemo(() => {
        return cartItems.reduce((total, item) => total + (item.quantity || 1), 0);
    }, [cartItems]);

    // Calculate total wishlist items
    const totalWishlistItems = useMemo(() => {
        return wishlistItems.length;
    }, [wishlistItems]);


    const handleSignInClick = useCallback(() => {
        try {
            if (user) {
                if (user.userRole === 'seller') {
                    navigate('/profile/seller-overview', { replace: false });
                } else if (user.userRole === 'buyer') {
                    navigate('/profile/overview', { replace: false });
                } else {
                    navigate('/profile', { replace: false });
                }
            } else {
                navigate('/auth', { replace: false });
            }
        } catch (error) {
            console.error('Navigation error:', error);
            navigate('/', { replace: false });
        }
    }, [user, navigate]);

    return (
        <div className='flex items-center justify-between gap-6 h-full'>
            <Link to={routes.cart}>
                <FiShoppingCart size='1.5rem' color='var(--globalbasewhite)' />
                {totalCartQuantity > 0 && (
                    <span className='absolute top-8 right-[19rem] bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center'>
                        {totalCartQuantity}
                    </span>
                )}
            </Link>
            <Link to={routes.wishlist}>
                <HiOutlineHeart size='1.5rem' color='var(--globalbasewhite)' />
                {totalWishlistItems > 0 && (
                    <span className='absolute top-8 right-[16.2rem] bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center'>
                        {totalWishlistItems}
                    </span>
                )}
            </Link>
            {/* <Link to={routes.notifications}> */}
                <NotificationIcon />
            {/* </Link> */}
            <button className=' -mx-1 md:mx-0 text-lg font-semibold text-basewhite' onClick={handleSignInClick}>
                {user ? (
                    user.image ? (
                        <img src={user.image} alt='user avatar' className='w-8 h-8 rounded-full' />
                    ) : (
                        <IoPersonOutline size='1.5rem' color='var(--globalbasewhite)' />
                    )
                ) : 'Sign In'}
            </button>
        </div>
    )
}

export default Nav

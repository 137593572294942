import React, { useState } from "react";
import { ChevronLeft, } from "lucide-react";
import { SpinnerLoader } from "../common/SpinnerLoader";
import { SuccessModal } from "../common/SuccessModal";

const Suggestions = () => {
  const [suggestion, setSuggestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Simulate API call with 3 second delay
    setTimeout(() => {
      setIsLoading(false);
      setShowSuccess(true);
    }, 3000);
  };

  const handleReset = () => {
    setSuggestion("");
    setShowSuccess(false);
  };

  return (
    <div className="min-h-screen bg-white flex mt-20 md:mt-24 justify-center py-4 md:p-4">
      <div className="w-full md:max-w-md lg:max-w-3xl">
        {/* Header */}
        <div className="p-4 flex items-center">
          <button className="mr-2">
            <ChevronLeft className="size-6 text-black" />
          </button>
          <h2 className=" text-lg md:text-xl font-bold">Suggestions</h2>
        </div>

        {/* Main Content */}
        <div className="flex flex-col h-full p-6">
          <h1 className="text-xl font-bold mb-2">
            Help us build a better shopping experience!
          </h1>
          <p className="text-gray-600">
            Kokorko's suggestion box is wide open, and we're eager to hear your
            thoughts on making it the best shopping app ever.
          </p>

          <br />
          <p className="text-gray-600 -mt-4 mb-12">Share your thoughts below</p>

          <form
            className="flex flex-col h-full md:h-fit pb-12 md:p-0"
            onSubmit={handleSubmit}
          >
            <textarea
              value={suggestion}
              onChange={(e) => setSuggestion(e.target.value)}
              className="w-full border rounded-lg p-3 h-32 mb-4 focus:outline-none focus:ring-2 focus:ring-kokorko-blue-primary-500"
              placeholder="Kindly type your suggestions here"
            />
            <button
              type="submit"
              className="mt-auto w-full bg-kokorko-blue-primary-500 text-white py-3 rounded-lg font-medium hover:bg-blue-700 transition-colors"
            >
              Submit
            </button>
          </form>
        </div>
      </div>

      {/* Loading Modal */}
      {isLoading && <SpinnerLoader />}

      {/* Success Modal */}
      {showSuccess && (
        <SuccessModal setShowSuccess={setShowSuccess}>
          <h3 className="text-xl font-semibold mb-2">
            Thank You for Your Suggestion
          </h3>
          <p className="text-gray-600 mb-6">
            We appreciate your feedback! It helps us make our app better for
            everyone.
          </p>

          <div className="flex items-center md:flex-col gap-2 md:space-y-3">
            <button
              onClick={() => setShowSuccess(false)}
              className="flex items-center justify-center w-full md:w-4/5 py-2 rounded-lg font-medium bg-[#F5F5F5] transition-colors"
            >
              <p> Continue Shopping</p>
            </button>
            <button
              onClick={handleReset}
              className="flex items-center justify-center w-full md:w-4/5 bg-kokorko-blue-primary-500 text-white border border-kokorko-blue-primary-500 py-2 rounded-lg font-medium hover:bg-blue-500 transition-colors"
            >
              <p className="hidden md:flex"> Submit Another Suggestion</p>
              <p className="flex md:hidden"> Submit Again</p>
            </button>
          </div>
        </SuccessModal>
      )}
    </div>
  );
};

export default Suggestions;

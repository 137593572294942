import React, { useState } from "react";
import { maskEmail } from "../utils/maskEmail";

// Reusable verification component for email or phone
export const Verification = ({ contactInfo, isEmail = true }) => {
  const [code, setCode] = useState(["", "", "", ""]); // Four fields for the code
  const [error, setError] = useState(false);
  const correctCode = "1234"; // Hard-coded correct code for demonstration

  // Handle input changes
  const handleInputChange = (value, index) => {
    if (value.length > 1) return; // Ensure single digit input

    const newCode = [...code];
    newCode[index] = value;

    // If input is cleared, move focus backward
    if (value === "" && index > 0) {
      document.getElementById(`input-${index - 1}`).focus();
    }

    // If input is entered, move focus forward
    if (value && index < 3) {
      document.getElementById(`input-${index + 1}`).focus();
    }

    setCode(newCode);

    // Check if all fields are filled
    if (newCode.every((digit) => digit !== "")) {
      handleSubmit(newCode.join(""));
    }
  };

  // Handle verification submission
  const handleSubmit = (enteredCode) => {
    if (enteredCode === correctCode) {
      alert("Code verified successfully!");
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <div className="flex h-full flex-col items-center justify-center">
      <div className="bg-white p-6 w-[400px]">
        <h2 className="text-lg font-semibold mb-4 text-center">
          Verify your {isEmail ? "Email" : "Phone"}
        </h2>

        <p className="text-sm text-gray-600 text-center mb-4">
          We have sent you a code on{" "}
          <span className="font-semibold">
            {isEmail ? maskEmail(contactInfo) : contactInfo}
          </span>
          . Enter it below to verify your account.
        </p>

        <div className="flex justify-center gap-2 mb-4">
          {code.map((digit, index) => (
            <input
              key={index}
              id={`input-${index}`}
              type="text"
              maxLength="1"
              value={digit}
              onChange={(e) => handleInputChange(e.target.value, index)}
              className={`w-12 h-12 text-center border ${
                error ? "border-red-500" : "border-gray-300"
              } rounded-md text-lg focus:outline-none focus:ring-2 ${
                error ? "focus:ring-red-500" : "focus:ring-kokorko-black-900"
              }`}
            />
          ))}
        </div>

        <div className="flex items-center gap-2 text-center justify-center mt-4">
          <p className="text-kokorko-grey-800">
            {!error
              ? "Didn’t receive code?"
              : "The code you entered is incorrect."}
          </p>
          <p
            onClick={() => {
              setCode(["", "", "", ""]);
              setError(false);
            }}
            className="text-sm text-kokorko-blue-primary-500 font-semibold cursor-pointer hover:text-blue-500"
          >
            Resend code
          </p>
        </div>
      </div>
    </div>
  );
};

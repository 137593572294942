import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk for creating an order
export const createOrder = createAsyncThunk(
    'checkout/createOrder',
    async (orderData, { getState, rejectWithValue }) => {
        try {
            const { auth, cart } = getState();
            
            // Prepare order payload
            const orderPayload = {
                userId: auth.user.id,
                items: cart.items,
                total: cart.total,
                status: 'pending',
                ...orderData
            };

            // Call backend to create order
            const response = await axios.post('/api/orders', orderPayload, {
                headers: {
                    'Authorization': `Bearer ${auth.token}`
                }
            });

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const checkoutSlice = createSlice({
    name: 'checkout',
    initialState: {
        currentStep: 1,
        deliveryInfo: null,
        paymentInfo: null,
        purchaseMode: 'cart', // 'cart' or 'direct'
        order: null,
        status: 'idle',
        error: null
    },
    reducers: {
        setDeliveryInfo: (state, action) => {
            state.deliveryInfo = action.payload;
        },
        setPaymentInfo: (state, action) => {
            state.paymentInfo = action.payload;
        },
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        setPurchaseMode: (state, action) => {
            state.purchaseMode = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createOrder.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createOrder.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.order = action.payload;
            })
            .addCase(createOrder.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
    resetCheckout: (state) => {
        state.currentStep = 1;
        state.deliveryInfo = null;
        state.paymentInfo = null;
        state.purchaseMode = 'cart';
        state.order = null;
        state.status = 'idle';
        state.error = null;
    }
});

export const { 
    setDeliveryInfo, 
    setPaymentInfo, 
    setCurrentStep,
    setPurchaseMode,
    resetCheckout
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
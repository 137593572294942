import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        profile: null,
        preferences: {}
    },
    reducers: {
        updateProfile: (state, action) => {
        state.profile = { ...state.profile, ...action.payload };
        },
        updatePreferences: (state, action) => {
        state.preferences = { ...state.preferences, ...action.payload };
        }
    }
});

export const { updateProfile, updatePreferences } = userSlice.actions;
export default userSlice.reducer;
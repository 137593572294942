import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './Wishlists.css'
import { RiDeleteBin6Line } from "react-icons/ri";
import { X } from 'lucide-react';
import { FaChevronLeft } from "react-icons/fa6";
import WishlistModal from '../../components/wishlistModal/WishlistModal'
import Toast from '../../components/common/Toast';
import { 
    fetchWishlist, 
    removeFromWishlistAsync, 
    clearWishlistAsync 
} from '../../store/slices/wishlistSlice';
import { addToCart } from '../../store/slices/cartSlice';
import { routes } from '../../lib/routes';

const WishlistItem = ({ item, handleAddToCart, handleDeleteClick }) => {
    return (
        <div
            className="flex justify-between p-4 border border-gray-200 rounded-lg shadow-none md:shadow-xs mb-4"
            key={item?.id}
        >
            {/* Image and Info */}
            <div className="flex items-start">
                <div className="w-32 h-32 bject-cover rounded-lg mr-4">
                    <img
                        src={item?.productImage}
                        alt={item?.productName}
                        className="w-full h-full object-cover rounded-lg mr-4"
                    />
                </div>
                <div className="flex flex-col justify-between">
                    <div className='flex flex-col items-start'>
                        <h3 className="text-lg font-semibold text-gray-800">{item?.productName}</h3>
                        <p className="text-gray-600 items-start flex -mt-2">
                            GH₵ {item?.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </p>
                    </div>
                    <button
                        className="bg-kokorko-blue-primary-500 text-white px-4 py-2 mt-4 rounded-md shadow-md hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
                        onClick={() => handleAddToCart(item)}
                    >
                        Add to Cart
                    </button>
                </div>
            </div>

            {/* Delete Button */}
            <button
                className="flex items-start text-kokorko-black-400 focus:outline-none"
                onClick={() => handleDeleteClick(item)}
            >
                <RiDeleteBin6Line className='size-5' />
            </button>
        </div>
    )
};

const ToastItem = ({ onClose }) => {
    return (
        <div className="fixed inset-x-0 bottom-4 flex justify-center p-4">
            <div className="flex items-center justify-between w-full md:max-w-max gap-6 bg-kokorko-blue-primary-500 text-white px-4 py-2 rounded-lg">
                <p>Item removed from wishlist</p>

                <div className='flex items-center'>
                    <button
                        onClick={() => { }}
                        className="text-sm font-medium hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
                    >
                        Undo
                    </button>

                    <button
                        onClick={onClose}
                        className="p-1 rounded-lg hover:bg-white/10 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
                    >
                        <X className="size-4" />
                    </button>
                </div>
            </div>
        </div>
    )
};

const Wishlists = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { items: wishlistItems, loading, error } = useSelector((state) => state.wishlist);
    const { isAuthenticated } = useSelector((state) => state.auth);
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    // fetch wishlist items
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchWishlist());
        }
    }, [dispatch, isAuthenticated]);

    const handleGoBack = () => {
        navigate(-1); // Goes back to the previous page
    };

    // open modal when delete button is clicked
    const handleDeleteClick = (item) => {
        setSelectedItem(item);
        setShowModal(true);
    };

    // close the modal
    const handleCloseModal = () => {
        setSelectedItem(null);
        setShowModal(false);
    };

    // Handle removing an item from wishlist
    const handleRemoveItem = () => {
        if (selectedItem) {
            dispatch(removeFromWishlistAsync(selectedItem.id));
            handleCloseModal();
        }
    };

    // Handle adding an item to the cart
    const handleAddToCart = (item) => {
        const cartItem = {
            id: item.id,
            productName: item.productName,
            price: item.price,
            productImage: item.productImage,
            quantity: 1,
            color: 'Default',
            store: item.seller || 'Unknown Store'
        };

        // Add to cart and remove from wishlist
        dispatch(addToCart(cartItem));
        dispatch(removeFromWishlistAsync(item.id));
    };

    // Handle clearing entire wishlist
    const handleClearWishlist = () => {
        dispatch(clearWishlistAsync());
    };

    // Loading and error states
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return (
            <div className="flex flex-col items-center justify-center h-full mt-20">
                <p className="text-red-500 text-lg mb-4">Error: {error}</p>
                <button 
                    className="bg-kokorko-blue-primary-500 text-white px-6 py-2 rounded-md"
                    onClick={() => dispatch(fetchWishlist())}
                >
                    Retry
                </button>
            </div>
        );
    }

    return (
        <>
            <div className="p-4 lg:p-5">
                <div className='flex items-center gap-2 mb-4'>
                    <FaChevronLeft className='block md:hidden cursor-pointer' onClick={handleGoBack} />
                    <h2 className='text-kokorko-black-900 font-bold text-lg'>Wishlist</h2>
                </div>
            </div>
            {isAuthenticated && (
                <div className="p-4 lg:p-5 h-full">
                    <div className='flex justify-between mb-4'>
                        <div className='flex items-center gap-2'>
                            <FaChevronLeft className='block md:hidden cursor-pointer' onClick={() => navigate(-1)} />
                            <h2 className='text-kokorko-black-900 font-bold text-lg'>Wishlist</h2>
                        </div>
                        {wishlistItems.length > 0 && (
                            <button 
                                className="text-red-500 text-sm"
                                onClick={handleClearWishlist}
                            >
                                Clear Wishlist
                            </button>
                        )}
                    </div>
    
                    <Toast timeout={5000}>
                        {({ onClose }) => (
                            <ToastItem onClose={onClose} />
                        )}
                    </Toast>
    
                {wishlistItems.length === 0 ? (
                    <div className="flex flex-col items-center justify-center h-full mt-20">
                        <p className="text-kokorko-black-900 text-lg mb-4">Your wishlist is empty.</p>
                        <button 
                            className="bg-kokorko-blue-primary-500 text-white px-6 py-2 rounded-md"
                            onClick={() => navigate(routes.home)}
                        >
                            Continue Shopping
                        </button>
                    </div>
                ) : (
                    <div className="wishlist-items">
                        {wishlistItems.map((item) => (
                            <WishlistItem
                                item={item}
                                key={item?.id}
                                handleAddToCart={handleAddToCart}
                                handleDeleteClick={handleDeleteClick}
                            />
                        ))}
                    </div>
                )}
    
                {/* modal for delete confirmation */}
                <WishlistModal
                    showModal={showModal}
                    onClose={handleCloseModal}
                    onDelete={handleRemoveItem}
                    itemName={selectedItem ? selectedItem?.productName : ''}
                />
            </div>
            )}
            {!isAuthenticated && (
                <div className="p-4 lg:p-5 pb-5 mt-24 lg:w-[80%] mx-auto h-full">
                    <div className='flex items-center justify-between mb-4'>
                        <div className='flex items-center gap-2'>
                            <FaChevronLeft className='block md:hidden cursor-pointer' onClick={() => navigate(-1)} />
                            <h2 className='text-kokorko-black-900 font-bold text-lg'>Wishlist</h2>
                        </div>
                        {wishlistItems.length > 0 && (
                            <button 
                                className="text-red-500 text-sm"
                                onClick={handleClearWishlist}
                            >
                                Clear Wishlist
                            </button>
                        )}
                    </div>
        
                    <Toast timeout={5000}>
                        {({ onClose }) => (
                            <ToastItem onClose={onClose} />
                        )}
                    </Toast>
    
                {wishlistItems.length === 0 ? (
                    <div className="flex flex-col items-center justify-center h-full mt-20">
                        <p className="text-kokorko-black-900 text-lg mb-4">Your wishlist is empty.</p>
                        <button 
                            className="bg-kokorko-blue-primary-500 text-white px-6 py-2 rounded-md"
                            onClick={() => navigate(routes.home)}
                        >
                            Continue Shopping
                        </button>
                    </div>
                ) : (
                    <div className="wishlist-items">
                        {wishlistItems.map((item) => (
                            <WishlistItem
                                item={item}
                                key={item?.id}
                                handleAddToCart={handleAddToCart}
                                handleDeleteClick={handleDeleteClick}
                            />
                        ))}
                    </div>
                )}
    
                {/* modal for delete confirmation */}
                    <WishlistModal
                        showModal={showModal}
                        onClose={handleCloseModal}
                        onDelete={handleRemoveItem}
                        itemName={selectedItem ? selectedItem?.productName : ''}
                    />
                </div>
            )}
        </>
    )
}

export default Wishlists;
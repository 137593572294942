import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import wishlistService from '../../services/wishlistService';
import { getRateLimitKey, checkRateLimit } from '../../utils/rateLimiter';

// Rate-limited async thunks
export const fetchWishlist = createAsyncThunk(
    'wishlist/fetchWishlist',
    async (_, { rejectWithValue, getState }) => {
        const { auth } = getState();
        
        // Check authentication
        if (!auth.isAuthenticated) {
            return rejectWithValue('User not authenticated');
        }

        // Rate limit check
        const rateLimitKey = getRateLimitKey('fetchWishlist');
        if (!checkRateLimit(rateLimitKey)) {
            return rejectWithValue('Rate limit exceeded');
        }

        try {
            const response = await wishlistService.getWishlist();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Failed to fetch wishlist');
        }
    }
);

export const addToWishlistAsync = createAsyncThunk(
    'wishlist/addToWishlist',
    async (item, { rejectWithValue, getState }) => {
        const { auth } = getState();
        
        // Check authentication
        if (!auth.isAuthenticated) {
            return rejectWithValue('User not authenticated');
        }

        // Rate limit check
        const rateLimitKey = getRateLimitKey('addToWishlist');
        if (!checkRateLimit(rateLimitKey)) {
            return rejectWithValue('Rate limit exceeded');
        }

        try {
            const response = await wishlistService.addToWishlist(item);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Failed to add to wishlist');
        }
    }
);

export const removeFromWishlistAsync = createAsyncThunk(
    'wishlist/removeFromWishlist',
    async (itemId, { rejectWithValue, getState }) => {
        const { auth } = getState();
        
        // Check authentication
        if (!auth.isAuthenticated) {
            return rejectWithValue('User not authenticated');
        }

        // Rate limit check
        const rateLimitKey = getRateLimitKey('removeFromWishlist');
        if (!checkRateLimit(rateLimitKey)) {
            return rejectWithValue('Rate limit exceeded');
        }

        try {
            await wishlistService.removeFromWishlist(itemId);
            return itemId;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Failed to remove from wishlist');
        }
    }
);

export const clearWishlistAsync = createAsyncThunk(
    'wishlist/clearWishlist',
    async (_, { rejectWithValue, getState }) => {
        const { auth } = getState();
        
        // Check authentication
        if (!auth.isAuthenticated) {
            return rejectWithValue('User not authenticated');
        }

        // Rate limit check
        const rateLimitKey = getRateLimitKey('clearWishlist');
        if (!checkRateLimit(rateLimitKey)) {
            return rejectWithValue('Rate limit exceeded');
        }

        try {
            await wishlistService.clearWishlist();
            return [];
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Failed to clear wishlist');
        }
    }
);

const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState: {
        items: [],
        total: 0,
        status: 'idle',
        error: null,
        loading: false
    },
    reducers: {
        // Local reducers for immediate UI updates
        addToWishlist: (state, action) => {
            const existingItemIndex = state.items.findIndex(
                item => item.id === action.payload.id
            );

            if (existingItemIndex === -1) {
                state.items.push({
                    ...action.payload,
                    addedAt: new Date().toISOString()
                });
                state.total += action.payload.price;
            }
        },
        removeFromWishlist: (state, action) => {
            const indexToRemove = state.items.findIndex(
                item => item.id === action.payload
            );

            if (indexToRemove !== -1) {
                const removedItem = state.items[indexToRemove];
                state.total -= removedItem.price;
                state.items.splice(indexToRemove, 1);
            }
        },
        clearWishlist: (state) => {
            state.items = [];
            state.total = 0;
        }
    },
    extraReducers: (builder) => {
        // Fetch Wishlist
        builder.addCase(fetchWishlist.pending, (state) => {
            state.status = 'loading';
            state.loading = true;
        });
        builder.addCase(fetchWishlist.fulfilled, (state, action) => {
            state.items = action.payload;
            state.total = state.items.reduce((total, item) => total + item.price, 0);
            state.status = 'succeeded';
            state.loading = false;
        });
        builder.addCase(fetchWishlist.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
            state.loading = false;
        });

        // Add to Wishlist
        builder.addCase(addToWishlistAsync.fulfilled, (state, action) => {
            const existingItemIndex = state.items.findIndex(
                item => item.id === action.payload.id
            );

            if (existingItemIndex === -1) {
                state.items.push(action.payload);
                state.total += action.payload.price;
            }
        });

        // Remove from Wishlist
        builder.addCase(removeFromWishlistAsync.fulfilled, (state, action) => {
            const indexToRemove = state.items.findIndex(
                item => item.id === action.payload
            );

            if (indexToRemove !== -1) {
                const removedItem = state.items[indexToRemove];
                state.total -= removedItem.price;
                state.items.splice(indexToRemove, 1);
            }
        });

        // Clear Wishlist
        builder.addCase(clearWishlistAsync.fulfilled, (state) => {
            state.items = [];
            state.total = 0;
        });
    }
});

export const { 
    addToWishlist, 
    removeFromWishlist, 
    clearWishlist 
} = wishlistSlice.actions;

export default wishlistSlice.reducer;
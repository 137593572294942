import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { MdFileUpload } from 'react-icons/md';
import { PiBellBold } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';
import BusinessStep1 from '../BusinessInformationSteps/step1';
import BusinessStep2 from '../BusinessInformationSteps/step2';
import BusinessStep3 from '../BusinessInformationSteps/step3';
import BusinessStep4 from '../BusinessInformationSteps/step4';
import BusinessStep5 from '../BusinessInformationSteps/step5';
import Modal from '../Modal';
import PaymentStep1 from '../PaymentInformationSteps/step1';
import LinkSocials from '../SocialMediaSteps/step1';

const SellerOnboarding = () => {
    const [seller, setSeller] = useState(null);
    const [stepsCompleted, setStepsCompleted] = useState({
        personalInfo: false,
        businessInfo: false,
        paymentSetup: false,
        socialMediaIntegration: false,
    });
    const [completionModalOpen, setCompletionModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState('');
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
      firstName: "",
      lastName: "",
      dob: "",
      gender: "",
      idNumber: "",
      idImage: null,
    });
  
    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);
  
    const userInfo = localStorage.getItem('userInfo');
    const userInfoParsed = JSON.parse(userInfo);
    const loggedInUserId = userInfoParsed.id;
    const navigate = useNavigate();


    const [business_currentStep, setBusinessCurrentStep] = useState(1);

    const business_nextStep = () => setBusinessCurrentStep(business_currentStep + 1);
    const business_prevStep = () => setBusinessCurrentStep(business_currentStep - 1);
  

    const renderStep = () => {
      switch (business_currentStep) {
        case 1:
          return <BusinessStep1 onNext={business_nextStep} />;
        case 2:
          return <BusinessStep2 onNext={business_nextStep} onBack={business_prevStep} />;
        case 3:
          return <BusinessStep3 onNext={business_nextStep} onBack={business_prevStep} />;
        case 4:
          return <BusinessStep4 onNext={business_nextStep} onBack={business_prevStep} />;
        case 5:
          return <BusinessStep5 onBack={business_prevStep} onDone={() => handleStepCompletion('businessInfo')} />;
            
        default:
          return null;
      }
    };


    useEffect(() => {
        const fetchSellerData = async () => {
            try {
                const response = await axios.get('/data.json');
                const users = response.data.users;

                const loggedInSeller = users.find(
                    user => user.id === loggedInUserId && user.role === 'seller'
                );

                if (!loggedInSeller) {
                    console.error('No seller found');
                    return;
                }

                setSeller(loggedInSeller);

            } catch (error) {
                console.error('Error fetching seller data:', error);
            }
        };

        fetchSellerData();
    }, [loggedInUserId]);

    const openModal = (step) => {
        setCurrentStep(step);
        setIsModalOpen(true);
    };


    const handleStepCompletion = (step) => {
      const updatedSteps = { ...stepsCompleted, [step]: true };
      setStepsCompleted(updatedSteps);
      closeModal();

      if (Object.values(updatedSteps).every(Boolean)) {
          setCompletionModalOpen(true);
      }
  };

  const closeCompletionModal = () => {
      setCompletionModalOpen(false);
      
      navigate('/profile/seller-overview'); 

    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const progress = Object.values(stepsCompleted).filter(Boolean).length;

    const generateInitials = (name) => {
        const names = name.split(' ');
        const initials = names[0][0] + (names[1] ? names[1][0] : '');
        return initials.toUpperCase();
    };

    if (!seller) {
        return <div>Loading...</div>;
    }

    return (
        <div className="seller-overview-container p-6">
            {/* Page Header */}
            <div className="header-row flex justify-between items-center mb-4">
                <div>
                    <p className="text-3xl font-bold">Welcome {seller.name}</p>
                    <p className='text-sm text-gray-600'>We're excited to have you onboard. To get your store up and running, you'll need to complete a few steps.</p>
                </div>
                <div className="flex items-center space-x-4">
                    <PiBellBold className="text-2xl text-gray-700" />
                    {seller.image ? (
                        <img src={seller.image} alt="Seller Avatar" className="w-10 h-10 rounded-full" />
                    ) : (
                        <div className="w-10 h-10 bg-gray-300 text-gray-700 flex items-center justify-center rounded-full">
                            {generateInitials(seller.name)}
                        </div>
                    )}
                </div>
            </div>

            {/* Progress Bar */}
            <div className="flex mb-6 items-center space-x-2">
                <p className="mb-2 text-sm space-x-3"> <span className='font-bold'>{progress}/4</span> Completed</p>
                <div className="w-2/5  bg-blue-200 rounded-full h-2">
                    <div
                        className="bg-blue-500 h-2 rounded-full"
                        style={{ width: `${(progress / 4) * 100}%` }}
                    ></div>
                </div>
            </div>

            {/* Steps */}
            <div className="space-y-4 font-semibold">
            <div className="bg-white w-3/6 p-4 rounded-md flex items-center space-x-4 cursor-pointer" onClick={() => openModal('personalInfo')}>
            <input
              type="checkbox"
              className="w-4 h-4"
              checked={stepsCompleted.personalInfo}
              readOnly
            />
            <span
              className={`text-sm ${stepsCompleted.personalInfo ? "line-through" : ""}`}
            >
              1. Personal Information
            </span>
          </div>

                <div className="flex bg-white p-4 w-3/6 rounded-md  items-center space-x-4 cursor-pointer" onClick={() => openModal('businessInfo')}>
                    <input type="checkbox" className="w-4 h-4" checked={stepsCompleted.businessInfo} readOnly />
                    <span
                      className={`text-sm ${stepsCompleted.businessInfo ? "line-through" : ""}`}
                  >
                      2. Business Information
                  </span>

                </div>
                <div className="flex bg-white p-4 w-3/6 rounded-md  items-center space-x-4 cursor-pointer" onClick={() => openModal('paymentSetup')}>
                    <input type="checkbox" className="w-4 h-4" checked={stepsCompleted.paymentSetup} readOnly />
                    <span
                      className={`text-sm ${stepsCompleted.paymentSetup ? "line-through" : ""}`}
                  >
                      3. Payment Setup
                  </span>

                </div>
                <div className="flex  bg-white p-4 w-3/6 rounded-md  items-center space-x-4 cursor-pointer" onClick={() => openModal('socialMediaIntegration')}>
                    <input type="checkbox" className="w-4 h-4" checked={stepsCompleted.socialMediaIntegration} readOnly />
                   
                    <span
                       className={`text-sm ${stepsCompleted.socialMediaIntegration ? "line-through" : ""}`}
                    >                      
                      4. Social Media Page Integration</span>
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && (
               <Modal 
               showModal={isModalOpen} 
               onClose={closeModal} 
               size={
                 currentStep === 'personalInfo' ? 'xxl' :
                 currentStep === 'businessInfo' ? 'xxl' :
                 currentStep === 'paymentSetup' ? 'xxlh' :
                 currentStep === 'socialMediaIntegration' ? 'xxl' : '8xl'
               }
             >
               {currentStep === 'personalInfo' && (
                 <div>
                   {currentStep === 'personalInfo' && step === 1 ? (
                     <Step1 nextStep={nextStep} formData={formData} setFormData={setFormData} />
                   ) : (
                     <Step2 prevStep={prevStep} formData={formData} setFormData={setFormData} handleStepCompletion={handleStepCompletion} />
                   )}
                 </div>
               )}
               
               {currentStep === 'businessInfo' && (
                 <div>
                   {renderStep()}
                 </div>
               )}
               
               {currentStep === 'paymentSetup' && (
                 <div>
                   {currentStep === 'paymentSetup' && (
                     <PaymentStep1 onNext={() => handleStepCompletion('paymentSetup')} />
                   )}
                 </div>
               )}
             
               {currentStep === 'socialMediaIntegration' && (
                 <div>
                   <LinkSocials onDone={() => handleStepCompletion('socialMediaIntegration')} />
                 </div>
               )}
             </Modal>
             
            )}

             {/* Completion Modal */}
             {completionModalOpen && (
                <Modal showModal={completionModalOpen} onClose={closeCompletionModal}>
                    <div className="text-center">
                        <div className="w-16 h-16 bg-green-500 rounded-full flex items-center justify-center mb-4 mx-auto">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-8 w-8 text-white"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>
                        <h2 className="text-xl font-bold mb-2">Setup Complete</h2>
                        <p className="text-gray-600 mb-4">Welcome aboard! Your shop is live and ready for customers.</p>
                        <button
                            className="px-6 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600"
                            onClick={closeCompletionModal}
                        >
                            Go To My Shop
                        </button>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default SellerOnboarding;

const Step1 = ({ nextStep, formData, setFormData }) => {
    return (
      <div className="flex flex-col items-center justify-center mb-5">
        <div className="w-full max-w-lg p-4 bg-white shadow-none rounded-md">
          <h2 className="text-2xl font-bold text-center mb-6">Let’s Get to Know You Better</h2>
          <p className="text-gray-600 text-center mb-6">
            We’ll need some basic details to secure your account and verify your identity.
          </p>

          <form>
  <div className="flex gap-4 mb-4">
    <div className="flex-1">
      <label className="block text-gray-700 font-medium mb-2">First Name</label>
      <input
        type="text"
        placeholder="John"
        value={formData.firstName}
        onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
        className="w-full px-4 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>
    <div className="flex-1">
      <label className="block text-gray-700 font-medium mb-2">Last Name</label>
      <input
        type="text"
        placeholder="Doe"
        value={formData.lastName}
        onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
        className="w-full px-4 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>
  </div>
  <div className="mb-4">
    <label className="block text-gray-700 font-medium mb-2">Date of Birth</label>
    <input
      type="date"
      value={formData.dob}
      onChange={(e) => setFormData({ ...formData, dob: e.target.value })}
      className="w-full px-4 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
    />
  </div>
  <div className="mb-6">
    <label className="block text-gray-700 font-medium mb-2">Gender</label>
    <select
      value={formData.gender}
      onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
      className="w-full px-4 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      <option value="">Select your gender</option>
      <option value="Male">Male</option>
      <option value="Female">Female</option>
      <option value="Other">Other</option>
    </select>
  </div>
  <div className="flex gap-4 pt-4 pb-10">
    <button
      className="flex-1 py-4 px-4 text-gray-800 bg-gray-100 border border-gray-300 rounded-md hover:bg-gray-200"
      onClick={() => {}}
    >
      Cancel
    </button>
    <button
      className="flex-1 py-2 px-4 text-white bg-[#2752A5] rounded-md hover:bg-blue-600"
      onClick={nextStep}
    >
      Next
    </button>
  </div>
</form>
         
        </div>
      </div>
    );
  };
  
const Step2 = ({ prevStep, formData, setFormData, handleStepCompletion }) => {

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        setSelectedImage(file);
      }
    };

    return (
      <div className="flex flex-col items-center justify-center">
        <div className="w-full max-w-md p-4 rounded-md">
          <h2 className="text-2xl font-bold text-center mb-6">Let’s Get to Know You Better</h2>
          <p className="text-gray-600 text-center mb-6">
            We’ll need some basic details to secure your account and verify your identity.
          </p>
          <form>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">ID Number</label>
              <input
                type="text"
                placeholder="Enter your Ghana card number"
                value={formData.idNumber}
                onChange={(e) => setFormData({ ...formData, idNumber: e.target.value })}
                className="w-full px-4 py-3 border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 font-medium mb-2">Image of your ID Card</label>
    
            <div className="border-4 rounded-lg border-dashed border-gray-300 p-5 w-full ">
      <div className="text-center">
        {selectedImage ? (
          <div className="mb-4 h-[300px] w-[400px] overflow-hidden text-center pr-5">
            <img
              src={URL.createObjectURL(selectedImage)}
              alt="Uploaded Preview"
              className="h-full w-full object-cover mx-auto rounded-md"
            />
            <p className="text-sm text-gray-500 mt-2">{selectedImage.name}</p>
          </div>
        ) : (
          <div className='flex justify-center pr-6 space-x-2'>
             <MdFileUpload className='w-6 h-6 text-gray-400'/>

          <p className="mt-1 text-sm text-gray-600 ">
            Drag file to upload <br /> <span className="text-gray-500">or</span>
          </p>
                  
          </div>
        )}
        <label
          htmlFor="image-upload"
          className="cursor-pointer mt-2 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
        >
                 {!selectedImage?.name ?  "Browse Files" : "Uploaded Another Image"}

          <input
            id="image-upload"
            type="file"
            accept="image/jpeg, image/png"
            className="hidden"
            onChange={handleImageChange}
          />
        </label>
        <p className="mt-1 text-xs text-gray-500">Supported file types: JPEG, PNG. <br />Max file size: 4MB</p>

        </div>
      </div>

    </div>
    <p className="text-sm text-gray-500 mt-2">Please upload only one file</p>

            <div className="flex gap-4 pt-4 pb-10">
    <button
      className="flex-1 py-4 px-4 text-gray-800 bg-gray-100 border border-gray-300 rounded-md hover:bg-gray-200"
      onClick={prevStep}
    >
      Back
    </button>
    <button type='submit'
      className="flex-1 py-2 px-4 text-white bg-[#2752A5] rounded-md hover:bg-blue-600"
      onClick={() => handleStepCompletion('personalInfo')}    >
      Done
    </button>
  </div>
           
          </form>
        </div>
      </div>
    );
  };
  

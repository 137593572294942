import React, { useState } from 'react';
import Modal from '../Modal';

const LinkSocials = ({onDone}) => {
    const [modalOpen, setModalOpen] = useState(false); // Controls modal visibility
  
    const closeModal = () => {
      setModalOpen(false); // Close modal
    };


  const openModal = () => {

      setModalOpen(true); // Open modal
  }
   
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-none max-w-md">
        <h2 className="text-lg text-start font-semibold mb-2">Link your socials</h2>
        <p className="text-gray-500 text-sm mb-6">
          We'll make sure you're ready to receive payments quickly and securely.
        </p>
        <div className="space-y-4">
          <button className="flex items-center justify-between w-full px-4 py-3
           bg-gray-100 border-2 font-medium rounded-full hover:bg-gray-100"
           onClick={openModal}
           >
            <span className="flex items-center">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png"
                alt="Instagram"
                className="w-6 h-6 mr-3"
              />
              Connect to Instagram
            </span>
          </button>
          <button className="flex items-center justify-between w-full px-4 py-3 bg-gray-100 font-medium border-2 rounded-full hover:bg-gray-100">
            <span className="flex items-center">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
                alt="Facebook"
                className="w-6 h-6 mr-3"
              />
              Connect to Facebook
            </span>
          </button>
        </div>
        <button className="mt-6 w-full px-4 py-3 text-gray-700 font-medium border-2 rounded-lg hover:bg-gray-00">
          Cancel
        </button>
      </div>


  {
    modalOpen && (
      
<Modal showModal={modalOpen} onClose={closeModal} size="8xl">
          <SuccessMessage  closeModal={onDone}/>
</Modal>
    )
  }
    </div>


  );
};

export default LinkSocials;



const SuccessMessage = ({closeModal}) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <h1 className="text-2xl font-bold text-gray-800 mb-2">
        Account Successfully Linked
      </h1>
      <p className="text-gray-600 mb-6">
        Product published successfully on Kokorko and Instagram.
      </p>
      <div className="w-16 h-16 bg-green-500 rounded-full flex items-center justify-center mb-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-8 w-8 text-white"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <button onClick={closeModal} className="px-6 py-2 bg-gray-100 text-gray-700 rounded-md shadow hover:bg-gray-200">
        Continue
      </button>
    </div>
  );
};

import React from 'react';

const OrderSuccess = ({onContinue}) => {
  return (
    <div className="p-0 flex justify-center items-center">
      <div className="w-full max-w-xl rounded-lg text-center p-6">
        {/* Success Icon */}
        <div className="flex justify-center mb-4">
          <div className="w-24 h-24 bg-green-600 rounded-full flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="white"
              className="w-10 h-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
        </div>

        {/* Success Message */}
        <h1 className="text-sm font-medium mb-6 mt-6">
          Thank you for your purchase! Your order for 2 Samsung S22 Ultra phones
          has been successfully placed.
        </h1>

        {/* Unique Code Section */}
        <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 mb-6">
          <p className="text-sm font-medium mb-2">
          Here’s your unique code:</p>
          <p className="text-lg font-bold text-blue-600">[CODE1234]</p>
          <p className="text-sm text-gray-600 mt-2">
            <span className="font-medium">Note:</span> Please provide this code
            to the delivery agent upon receiving your order. This will authorize
            the release of funds to the seller. Keep it secure and do not share
            it with anyone until your order is delivered.
          </p>
        </div>

        {/* Continue Shopping Button */}
        <button className="w-full bg-blue-[#2857b0] text-white py-2 px-4 rounded-lg font-medium hover:bg-blue-700 transition">
          Continue Shopping
        </button>
      </div>
    </div>
  );
};

export default OrderSuccess;

import React from 'react'
import { IoMdHeartEmpty } from "react-icons/io";
import ShopIcon from '../../assets/icons/store-03-stroke-rounded.svg'
import HeroNav from '../../components/heroNav/HeroNav';

// Import all images
const images = [
    { src: require('../../assets/images/feed-images/feed-plates.jpeg'), alt: 'feed-plates' },
    { src: require('../../assets/images/feed-images/feed-glases.jpeg'), alt: 'feed-glasses' },
    { src: require('../../assets/images/feed-images/feed-glases-single.jpeg'), alt: 'feed-glasses-single' },
    { src: require('../../assets/images/feed-images/feed-mugs.jpeg'), alt: 'feed-mugs' },
    { src: require('../../assets/images/feed-images/feed-cookware.jpeg'), alt: 'feed-cookware' },
    { src: require('../../assets/images/feed-images/feed-lotion.jpeg'), alt: 'feed-lotion' },
    { src: require('../../assets/images/feed-images/feed-body-oil.jpeg'), alt: 'feed-body-oil' },
    { src: require('../../assets/images/feed-images/feed-tube.jpeg'), alt: 'feed-tube' },
    { src: require('../../assets/images/feed-images/feed-bath-oil.jpeg'), alt: 'feed-bath-oil' },
    { src: require('../../assets/images/feed-images/feed-white-bag.jpeg'), alt: 'feed-white-bag' },
    { src: require('../../assets/images/feed-images/feed-watch.jpeg'), alt: 'feed-watch' },
    { src: require('../../assets/images/feed-images/feed-black-bag.jpeg'), alt: 'feed-black-bag' },
    { src: require('../../assets/images/feed-images/feed-dark-glasses.jpeg'), alt: 'feed-dark-glasses' },
    { src: require('../../assets/images/feed-images/feed-incense.jpeg'), alt: 'feed-incense' },
    { src: require('../../assets/images/feed-images/feed-perfume.jpeg'), alt: 'feed-perfume' },
    { src: require('../../assets/images/feed-images/feed-bowls.jpeg'), alt: 'feed-bowls' },
    { src: require('../../assets/images/feed-images/feed-glassware.jpeg'), alt: 'feed-glassware' },
    { src: require('../../assets/images/feed-images/feed-tennis-bat.jpeg'), alt: 'feed-tennis-bat' },
    { src: require('../../assets/images/feed-images/feed-dark-perfume.jpeg'), alt: 'feed-dark-perfume' },
    { src: require('../../assets/images/feed-images/feed-nike.jpeg'), alt: 'feed-nike' },
    { src: require('../../assets/images/feed-images/feed-closset.jpeg'), alt: 'feed-closset' },
    { src: require('../../assets/images/feed-images/feed-football.jpeg'), alt: 'feed-football' },
    { src: require('../../assets/images/feed-images/feed-boots.jpeg'), alt: 'feed-boots' },
    { src: require('../../assets/images/feed-images/feed-sneakers.jpeg'), alt: 'feed-sneakers' }
];

const FeedItem = ({ image }) => (
    <div className="relative flex flex-col my-3 rounded-lg justify-between w-full h-fit ">
        <div className="w-full flex rounded-t-lg justify-between items-center gap-4 p-3 md:p-2 lg:p-3 bg-[#2752A5]">
            <h1 className="text-white font-inter text-[14px] font-[550] leading-5 tracking-[-0.02px] md:line-clamp-1 lg:line-clamp-2">
                Gadget Galaxy
            </h1>
            <img src={ShopIcon} alt="shop-icon" />
        </div>

        <div className="relative w-full h-52 sm:h-72 md:h-28 lg:h-48 flex bg-basewhite">
            <img
                src={image.src}
                alt={image.alt}
                className="w-full h-full object-cover"
            />
        </div>

        <div className="relative w-full h-[34%] flex flex-col justify-between px-3 py-[6px] bg-[#F1F6FF]">
            <p className="text-black text-base font-[475] leading-5 tracking-[-0.05px] md:line-clamp-1 lg:line-clamp-2">
                Sound Audio nest
            </p>
            <p className="text-black text-lg md:text-base lg:text-lg font-[600] leading-5 tracking-[-0.18px]">
                GH₵20,000.00
            </p>
            <div className="flex justify-center items-center py-2 px-4 mb-4 mt-3 md:py-1 md:text-xs md:mb-2 md:mt-1 lg:py-2 lg:text-base lg:mb-4 border border-kokorko-blue-primary-500 bg-basewhite text-kokorko-blue-primary-500 rounded-[4px] shadow-[0px_1.5px_4px_-1px_rgba(10,9,11,0.07)]">
                Buy Now
            </div>
            <div className="absolute z-10 -top-7 right-[1rem] size-10 md:size-8 lg:size-10 flex items-center justify-center bg-white rounded-full">
                <IoMdHeartEmpty size="1.5rem" />
            </div>
        </div>

    </div>
);

const Feed = () => {
    return (
        <div className="w-[95%] md:w-[93%] lg:w-4/5 mx-auto mb-[15rem] mt-8 pt-10">
            <div className="flex justify-end mb-6 pt-[3.2rem]">
                <HeroNav />
            </div>

            <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-2 lg:gap-8">
                {images.map((image, index) => (
                    <FeedItem key={index} image={image} />
                ))}
            </div>
        </div>
    )
}

export default Feed
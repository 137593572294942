import React from 'react'
import './HeroSection.css'
import HeroTop from '../heroTop/HeroTop'
import HeroBottom from '../heroBottom/HeroBottom'
import Search from '../../components/search/Search'

const HeroSection = () => {
    return (
        <div>
            <HeroTop />
            <div className='flex sm:hidden pb-4 items-center h-full w-full'>
                <Search products={[]} />
            </div>
            <HeroBottom />
        </div>
    )
}

export default HeroSection

import './Main.css'
import React from 'react'
import Stores from '../containers/stores/Stores'
import TopPicks from '../containers/topPicks/TopPicks'
import BestDeals from '../containers/bestDeals/BestDeals'
import FlashDeals from '../containers/flashDeals/FlashDeals'
import BestSeller from '../containers/bestSeller/BestSeller'
import PopularCats from '../containers/popularCats/PopularCats'
import HeroSection from '../containers/heroSection/HeroSection'

function Main() {
    return (
        <div className='flex flex-col w-[93%] md:w-4/5 mx-auto mt-[0.1rem]'>
            <div className='sm:mt-[7.5rem] md:mt-[5.5rem]'>
                <HeroSection />
            </div>
            <div className='popularCats'>
                <h1>Popular Categories</h1>
                <PopularCats />
            </div>
            <BestDeals />
            <Stores fullList={false} />
            <TopPicks />
            <BestSeller />
            <FlashDeals />
        </div>
    )
}

export default Main;
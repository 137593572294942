import React from 'react'
import "./SideBanner.css"
import sideBanner1 from '../../assets/images/side-banner1.png'
import sideBanner2 from '../../assets/images/side-banner2.png'

export const TopSideBanner = () => {
    return (
        <div className='h-full md:h-[11.5rem] lg:gap-[1.125rem] side-banner1 lg:px-4'>
            <div className='side-banner1-text flex-1 md:text-lg lg:text-xl pl-8'>
                <p>Best Sellers in Phones</p>
            </div>
            <img className='w-[8rem] lg:w-[10rem] h-[8.75rem] md:h-24 object-contain' src={sideBanner1} alt="top side banner" />
        </div>
    )
}

export const BottomSideBanner = () => {
    return (
        <div className='h-full md:h-[11.5rem] lg:gap-[1.02575rem] side-banner2'>
            <img className='w-[10rem] h-[8.75rem] md:h-24 object-contain' src={sideBanner2} alt="bottom side banner" />
            <div className='side-banner2-text md:text-lg lg:text-xl pr-10'>
                <p>Beauty on Budget</p>
                <p className='effect md:py-2 md:px-2 lg:py-[0.4rem] lg:px-[0.75rem]'><span className='medium-text'>Up to</span> <span className='bold-text'>50% off</span></p>
            </div>
        </div>
    )
}

const SideBanner = () => {
    return (
        <div className=' h-[23.6rem] lg:w-[23rem] xl:w-96 side-banner-container'>
            <TopSideBanner />
            <BottomSideBanner />
        </div>
    )
}

export default SideBanner

// import React, { useState } from 'react';
// import { FiCheckCircle } from 'react-icons/fi';
// import { MdOutlineInfo } from "react-icons/md";
// import ArrowRightIcon from '../../assets/icons/chevron-right.svg';
// import TruckIcon from '../../assets/icons/truck.svg';
// import VbuyIcon from '../../assets/icons/vbuy-icon.png';
// import CheckoutModal from '../../components/checkoutModal/checkoutModal';

// const DeliveryInformation = ({onContinue}) => {
//     const [showModal, setShowModal] = useState(false);
//     const [showOTPModal, setShowOTPModal] = useState(false);
//     const [verificationCode, setVerificationCode] = useState("");
//     const [isVerified, setIsVerified] = React.useState(false);

//     const verifyPhoneNumber = () => {
//       setIsVerified(true);
//       handleCloseModal();
//     }
//     const showVerifyOTPModal = () => {
//         setShowOTPModal(true);
//     };

//     const handleCloseModal = () => {
//         setShowModal(false);    
//         setShowOTPModal(false);
//     };


//     const handleOpenModal = () => {
//         setShowModal(true);
//     };

//   return (
//   <>
                
//                 <div className='checkout-main-container'>
//                 <form className='checkout-form-container'>
//                     <div className='name-fields'>
//                         <div className='form-group'>
//                             <label>First Name</label>
//                             <input type="text" placeholder='John' />
//                         </div>
//                         <div className='form-group'>
//                             <label>Last Name</label>
//                             <input type="text" placeholder='Doe' />
//                         </div>
//                     </div>
//                     <div className='form-group'>
//                         <label>Address</label>
//                         <input type="text" placeholder='Enter full address' />
//                     </div>
//                     <div className='form-group'>
//                         <label>City</label>
//                         <input type="text" placeholder='Enter city' />
//                     </div>
//                     <div className='form-group'>
//                         <label>Region</label>
//                         <select>
//                             <option value=''>Select region</option>
//                             <option value=''>Greater Accra Region</option>
//                             <option value=''>Ashante Region</option>
//                             <option value=''>Central Region</option>
//                             <option value=''>Eastern Region</option>
//                             <option value=''>Volta Region</option>
//                             <option value=''>Western Region</option>
//                             <option value=''>Western North Region</option>
//                             <option value=''>Upper East Region</option>
//                             <option value=''>Upper West Region</option>
//                             <option value=''>Northern Region</option>
//                             <option value=''>Savannah Region</option>
//                             <option value=''>Bono Region</option>
//                             <option value=''>Ahafo Region</option>
//                             <option value=''>Bono East Region</option>
//                             <option value=''>Oti Region</option>
//                             <option value=''>Savannah Region</option>
//                         </select>
//                     </div>
//                     <div className="form-group">
//                         <label className="block text-sm font-medium text-gray-700 mb-1">Phone Number</label>
//                         <div className="relative">
//                             <input
//                             type="text"
//                             placeholder="Enter phone number"
//                             className="w-full px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
//                             />
//                             {isVerified && (
//                             <div className="absolute inset-y-0 right-3 flex items-center">
//                                 <div className="flex items-center justify-center w-6 h-6">
//                                 <FiCheckCircle size={20} className="text-green-600" />
//                                 </div>
//                             </div>
//                             )}
//                         </div>
//                         </div>
//                 </form>
//                 <div className='checkout-summary-container'>
//                     <div className='delivery-method'>
//                         <img src={TruckIcon} alt='truck icon' />
//                         <p>Delivery Method</p>
//                     </div>
//                     <div className='delivery-options'>
//                         <div className='checkout-door-option'>
//                             <label>Door Delivery</label>
//                             <div className='door-option-price'>
//                                 <p>GH₵ 30.00</p>
//                                 <input type="radio" name='delivery-option' />
//                             </div>
//                         </div>
//                         <div className='checkout-pickup-option'>
//                             <label>Pick Up</label>
//                             <input type="radio" name='delivery-option' />
//                         </div>
//                     </div>
//                     <div className='checkout-order-summary'>
//                         <div className='checkoutOrder-summary-details'>
//                             <p className='checkoutOrder-summary-title'>Summary</p>
//                             <p className='checkoutOrder-summary-titleDetails'>See details
//                                 <img src={ArrowRightIcon} alt='arrow right icon' />
//                             </p>
//                         </div>
//                         <div className='checkoutOrder-summary-details'>
//                             <p className='checkoutOrder-summary-label'>Products</p>
//                             <p className='checkoutOrder-summary-amount'>GH₵ 24,000.00</p>
//                         </div>
//                         <div className='checkoutOrder-summary-details'>
//                             <p className='checkoutOrder-summary-label'>Delivery</p>
//                             <p className='checkoutOrder-summary-amount'>GH₵ 30.00</p>
//                         </div>
//                         <div className='checkoutOrder-summary-details'>
//                             <p className='checkoutOrder-summary-label'>Sub Total</p>
//                             <p className='checkoutOrder-summary-total'>GH₵ 24,030.00</p>
//                         </div>
//                         <button className='checkoutOrder-summary-button'
//                         onClick={() => handleOpenModal()}

//                          >Continue to Payment</button>
//                     </div>
//                     <div className='vbuy-payment-info'>
//                         <MdOutlineInfo size='1rem'/>
//                         <p>Secure payment via </p>
//                         <img src={VbuyIcon} alt='Vbuy Icon' />
//                     </div>
//                 </div>
//             </div>


//             <CheckoutModal 
//         showModal={showModal} 
//         onClose={handleCloseModal} 
//         title="Phone Number Verification Required" 
//         description="To ensure the security of your account, we’ll need to verify your phone number."
//         size="md"
//       >
//         <div className="mb-4">
//           <label className="block text-xs font-medium text-gray-700">Phone Number</label>
//           <input 
//             type="text" 
//             className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
//             placeholder="0203456789"
//           />
//         </div>
//         <div className="flex pb-10 pt-0 bg-white">
//           <button className="w-full py-2 text-white bg-[#2752A5] rounded hover:bg-blue-600" onClick={()=>showVerifyOTPModal()}>
//             Confirm & Proceed
//           </button>
//         </div>
//     </CheckoutModal>

//     <CheckoutModal 
//             showModal={showOTPModal} 
//             onClose={handleCloseModal} 
//             title="Verify Your Phone Number" 
//             description="We have sent you a code on +233 435 343 353. Enter it below to verify your phone number"
//             size="md"
//             >
//         <div>
//             <div className="flex justify-center gap-2">
//             {[...Array(4)].map((_, idx) => (
//                 <input
//                 key={idx}
//                 type="text"
//                 maxLength="1"
//                 className={`w-12 h-12 text-center text-xl border rounded-md ${
//                     verificationCode[idx] ? 'border-black' : 'border-gray-300'
//                 }`}
//                 value={verificationCode[idx] || ''}
//                 onChange={(e) => {
//                     const value = e.target.value;
//                     if (/[^0-9]/.test(value)) return; // Allow only numbers
//                     const newCode = [...verificationCode];
//                     newCode[idx] = value;
//                     setVerificationCode(newCode.join(''));
//                 }}
//                 />
//             ))}
//             </div>
//         </div>
//         <div className="flex pb-5 pt-5 bg-white">
//             <button className="w-full py-2 text-white bg-[#2752A5] rounded hover:bg-blue-600" 
//             onClick={verifyPhoneNumber}

//             >
//             Verify Number
//             </button>
//         </div>
//         <div>
//             <div className="flex pb-5 text-xs justify-center gap-2 text-gray-700">
//             Didn't receive code? <span className="text-[#2752A5] cursor-pointer">Resend Code</span>
//             </div>
//         </div>
//     </CheckoutModal>
    
//     { isVerified && 

//     <CheckoutModal
//     showModal={showModal}
//     onClose={handleCloseModal}
//     title=""
//     description=""
//     size="lg"
//     >
//         <center>
//             <div className="items-center gap-2">
//                     <img src={VbuyIcon} alt="VerifiBuy Logo" className="h-6 flex justify-center" />
//                     <p className="text-lg font-semibold text-gray-800">Your Payment is Secured with VerifiBuy Escrow</p>
//                     <p className="text-gray-600 text-xs">
//                     A secure escrow service that holds your payment until you confirm receiving your order, ensuring your money is safe.
//                     </p>
//             </div>
//         </center>

//     <h4 className="text-md text-center font-semibold mb-2 pt-5">Benefits</h4>

//     <div className="p-4 rounded-md shadow-sm border bg-blue-50 border-blue-100 mb-6">
//     <ul className="text-sm text-gray-700 space-y-3">
//         <li>
//         <strong className="font-semibold">Payment Protection:</strong> We prioritize your security. Your payment will
//         be held in escrow until you confirm the goods have arrived as expected. Only then will payment be released to
//         the seller.
//         </li>
//         <li>
//         <strong className="font-semibold">Dispute Resolution:</strong> In case of any issues with your order, we’ll
//         step in to help resolve disputes before the funds are released.
//         </li>
//         <li>
//         <strong className="font-semibold">Trustworthy Transactions:</strong> Ensure a safe and reliable transaction
//         with sellers, knowing your money is protected until the deal is complete.
//         </li>
//         <li>
//         <strong className="font-semibold">Stress-Free Shopping:</strong> Enjoy peace of mind knowing that your money
//         is safeguarded until you’re fully satisfied with your purchase.
//         </li>
//     </ul>
//     </div>
//     <div className="flex gap-4 pt-10 pb-10">
//     <button
//         className="flex-1 py-2 px-4 text-gray-800 bg-gray-100 border border-gray-300 rounded-md hover:bg-gray-200"
//         onClick={() => {}}
//     >
//         Learn More
//     </button>
//     <button
//         className="flex-1 py-2 px-4 text-white bg-[#2752A5] rounded-md hover:bg-blue-600"
//         onClick= {onContinue}
//     >
//         Continue
//     </button>
  
//     </div>
     
    
//     </CheckoutModal>
//     }

//   </>
//   );
// };

// export default DeliveryInformation;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FiCheckCircle } from 'react-icons/fi';
import { MdOutlineInfo } from "react-icons/md";
import ArrowRightIcon from '../../assets/icons/chevron-right.svg';
// import TruckIcon from '../../assets/icons/truck.svg';
import VbuyIcon from '../../assets/icons/vbuy-icon.png';
import CheckoutModal from '../../components/checkoutModal/checkoutModal';

const DeliveryInformation = ({onContinue}) => {
    const [showModal, setShowModal] = useState(false);
    const [showOTPModal, setShowOTPModal] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");
    const [isVerified, setIsVerified] = React.useState(false);

    const { total } = useSelector((state) => state.cart);
    const deliveryFee = 30.00;
    const subTotal = total + deliveryFee;

    const verifyPhoneNumber = () => {
      setIsVerified(true);
      handleCloseModal();
    }
    const showVerifyOTPModal = () => {
        setShowOTPModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);    
        setShowOTPModal(false);
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    return (
        <>
            <div className='checkout-main-container'>
            <form className='checkout-form-container'>
                <div className='name-fields'>
                    <div className='form-group'>
                        <label>First Name</label>
                        <input type="text" placeholder='John' />
                    </div>
                    <div className='form-group'>
                        <label>Last Name</label>
                        <input type="text" placeholder='Doe' />
                    </div>
                </div>
                <div className='form-group'>
                    <label>Address</label>
                    <input type="text" placeholder='Enter full address' />
                </div>
                <div className='form-group'>
                    <label>City</label>
                    <input type="text" placeholder='Enter city' />
                </div>
                <div className='form-group'>
                    <label>Region</label>
                    <select>
                        <option value=''>Select region</option>
                        <option value=''>Greater Accra Region</option>
                        <option value=''>Ashante Region</option>
                        <option value=''>Central Region</option>
                        <option value=''>Eastern Region</option>
                        <option value=''>Volta Region</option>
                        <option value=''>Western Region</option>
                        <option value=''>Western North Region</option>
                        <option value=''>Upper East Region</option>
                        <option value=''>Upper West Region</option>
                        <option value=''>Northern Region</option>
                        <option value=''>Savannah Region</option>
                        <option value=''>Bono Region</option>
                        <option value=''>Ahafo Region</option>
                        <option value=''>Bono East Region</option>
                        <option value=''>Oti Region</option>
                        <option value=''>Savannah Region</option>
                    </select>
                </div>
                <div className="form-group">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Phone Number</label>
                    <div className="relative">
                        <input
                        type="text"
                        placeholder="Enter phone number"
                        className="w-full px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        />
                        {isVerified && (
                        <div className="absolute inset-y-0 right-3 flex items-center">
                            <div className="flex items-center justify-center w-6 h-6">
                            <FiCheckCircle size={20} className="text-green-600" />
                            </div>
                        </div>
                        )}
                    </div>
                </div>
            </form>
                <div className='checkout-summary-container'>
                    <div className='checkout-order-summary'>
                        <div className='checkoutOrder-summary-details'>
                            <p className='checkoutOrder-summary-title'>Summary</p>
                            <p className='checkoutOrder-summary-titleDetails'>See details
                                <img src={ArrowRightIcon} alt='arrow right icon' />
                            </p>
                        </div>
                        <div className='checkoutOrder-summary-details'>
                            <p className='checkoutOrder-summary-label'>Products</p>
                            <p className='checkoutOrder-summary-amount'>
                                GH₵ {total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </p>
                        </div>
                        <div className='checkoutOrder-summary-details'>
                            <p className='checkoutOrder-summary-label'>Delivery</p>
                            <p className='checkoutOrder-summary-amount'>
                                GH₵ {deliveryFee.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </p>
                        </div>
                        <div className='checkoutOrder-summary-details'>
                            <p className='checkoutOrder-summary-label'>Sub Total</p>
                            <p className='checkoutOrder-summary-total'>
                                GH₵ {subTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </p>
                        </div>
                        <button 
                            className='checkoutOrder-summary-button'
                            onClick={() => handleOpenModal()}
                        >
                            Continue to Payment
                        </button>
                    </div>
                    <div className='vbuy-payment-info'>
                        <MdOutlineInfo size='1rem'/>
                        <p>Secure payment via </p>
                        <img src={VbuyIcon} alt='Vbuy Icon' />
                    </div>
                </div>
            </div>
            <CheckoutModal 
        showModal={showModal} 
        onClose={handleCloseModal} 
        title="Phone Number Verification Required" 
        description="To ensure the security of your account, we’ll need to verify your phone number."
        size="md"
      >
        <div className="mb-4">
          <label className="block text-xs font-medium text-gray-700">Phone Number</label>
          <input 
            type="text" 
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="0203456789"
          />
        </div>
        <div className="flex pb-10 pt-0 bg-white">
          <button className="w-full py-2 text-white bg-[#2752A5] rounded hover:bg-blue-600" onClick={()=>showVerifyOTPModal()}>
            Confirm & Proceed
          </button>
        </div>
    </CheckoutModal>

    <CheckoutModal 
            showModal={showOTPModal} 
            onClose={handleCloseModal} 
            title="Verify Your Phone Number" 
            description="We have sent you a code on +233 435 343 353. Enter it below to verify your phone number"
            size="md"
            >
        <div>
            <div className="flex justify-center gap-2">
            {[...Array(4)].map((_, idx) => (
                <input
                key={idx}
                type="text"
                maxLength="1"
                className={`w-12 h-12 text-center text-xl border rounded-md ${
                    verificationCode[idx] ? 'border-black' : 'border-gray-300'
                }`}
                value={verificationCode[idx] || ''}
                onChange={(e) => {
                    const value = e.target.value;
                    if (/[^0-9]/.test(value)) return; // Allow only numbers
                    const newCode = [...verificationCode];
                    newCode[idx] = value;
                    setVerificationCode(newCode.join(''));
                }}
                />
            ))}
            </div>
        </div>
        <div className="flex pb-5 pt-5 bg-white">
            <button className="w-full py-2 text-white bg-[#2752A5] rounded hover:bg-blue-600" 
            onClick={verifyPhoneNumber}

            >
            Verify Number
            </button>
        </div>
        <div>
            <div className="flex pb-5 text-xs justify-center gap-2 text-gray-700">
            Didn't receive code? <span className="text-[#2752A5] cursor-pointer">Resend Code</span>
            </div>
        </div>
    </CheckoutModal>
    
    { isVerified &&
    <CheckoutModal
    showModal={showModal}
    onClose={handleCloseModal}
    title=""
    description=""
    size="lg"
    >
        <center>
            <div className="items-center gap-2">
                    <img src={VbuyIcon} alt="VerifiBuy Logo" className="h-6 flex justify-center" />
                    <p className="text-lg font-semibold text-gray-800">Your Payment is Secured with VerifiBuy Escrow</p>
                    <p className="text-gray-600 text-xs">
                    A secure escrow service that holds your payment until you confirm receiving your order, ensuring your money is safe.
                    </p>
            </div>
        </center>

    <h4 className="text-md text-center font-semibold mb-2 pt-5">Benefits</h4>

    <div className="p-4 rounded-md shadow-sm border bg-blue-50 border-blue-100 mb-6">
    <ul className="text-sm text-gray-700 space-y-3">
        <li>
        <strong className="font-semibold">Payment Protection:</strong> We prioritize your security. Your payment will
        be held in escrow until you confirm the goods have arrived as expected. Only then will payment be released to
        the seller.
        </li>
        <li>
        <strong className="font-semibold">Dispute Resolution:</strong> In case of any issues with your order, we’ll
        step in to help resolve disputes before the funds are released.
        </li>
        <li>
        <strong className="font-semibold">Trustworthy Transactions:</strong> Ensure a safe and reliable transaction
        with sellers, knowing your money is protected until the deal is complete.
        </li>
        <li>
        <strong className="font-semibold">Stress-Free Shopping:</strong> Enjoy peace of mind knowing that your money
        is safeguarded until you’re fully satisfied with your purchase.
        </li>
    </ul>
    </div>
    <div className="flex gap-4 pt-10 pb-10">
    <button
        className="flex-1 py-2 px-4 text-gray-800 bg-gray-100 border border-gray-300 rounded-md hover:bg-gray-200"
        onClick={() => {}}
    >
        Learn More
    </button>
    <button
        className="flex-1 py-2 px-4 text-white bg-[#2752A5] rounded-md hover:bg-blue-600"
        onClick= {onContinue}
    >
        Continue
    </button>
  
    </div>
     
    
    </CheckoutModal>
    }
        </>
    )
}

export default DeliveryInformation;
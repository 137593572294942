import React, { useRef } from 'react'
import './Products.css'
import { useEffect, useState } from 'react'
// import { v4 as uuidv4 } from 'uuid'
import ProductCard from '../../components/productsCard/ProductCard'
import HeroNav from '../../components/heroNav/HeroNav'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import FilterPane from '../../components/filterPane/FilterPane'
import { RiExpandUpDownLine } from 'react-icons/ri'
import { FaCaretDown } from 'react-icons/fa6'
import CategoriesModal from '../../components/categoriesModal/CategoriesModal'
import { IoMenu } from 'react-icons/io5'
import Search from '../../components/search/Search'
// import axios from 'axios'

// const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Products = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [filterCriteria, setFilterCriteria] = useState({
        category: null,
        subCategory: null,
        priceRange: [0, Infinity], // Allow all prices by default
        discountRange: [0, 100],  // Allow all discounts by default
    });

    const [isModalOpen, setModalOpen] = useState(false);
    const [showMobileFilterModal, setShowMobileFilterModal] = useState(false);

    // A helper function to toggle the modal open state
    const toggleModal = () => {
        setModalOpen(prevState => !prevState)
    };

    // A helper function to toggle the mobile filter modal open state
    const toggleMobileFilterModal = () => {
        setShowMobileFilterModal(prevState => !prevState)
    };

    useEffect(() => {
        const getProduct = async () => {
            setLoading(true);
            try {
                // const response = await axios.get(`${BASE_URL}api/products/`);
                // const data = await response.data;
                const response = await fetch('/data.json');
                const data = await response.json();

                // setProducts(data);
                console.log('data:', data);

                if (Array.isArray(data.products)) {
                    setProducts(data.products);
                    console.log('products:', data.products);
                } else {
                    setError('Unexpected data format');
                }
            } catch (error) {
                setError('Error loading data');
            }
            setLoading(false);
        };
        getProduct();
    }, []);

    // Filtering Logic
    useEffect(() => {
        let filtered = products;

        // Category Filter
        if (filterCriteria.category) {
            filtered = filtered.filter((product) => product.category === filterCriteria.category.id);
        }
        // Price Range Filter
        filtered = filtered.filter(
            (product) => product.price >= filterCriteria.priceRange[0] && product.price <= filterCriteria.priceRange[1]
        );

        // Discount Range Filter
        filtered = filtered.filter(
            (product) =>
                ((product.discount / product.price) * 100) >= filterCriteria.discountRange[0] &&
                ((product.discount / product.price) * 100) <= filterCriteria.discountRange[1]
        );

        setFilteredProducts(filtered);
    }, [filterCriteria, products]);

    const handleFilterChange = (newCriteria) => {
        setFilterCriteria(newCriteria);
    };

    const FilterHeader = () => {
        const [sortBy, setSortBy] = useState("relevance");
        const [isDropdownOpen, setIsDropdownOpen] = useState(false);
        const dropdownRef = useRef(null);

        const handleSortChange = (value) => {
            setSortBy(value);
            setIsDropdownOpen(false); // Close dropdown after selection
        };

        const toggleDropdown = () => {
            setIsDropdownOpen((prev) => !prev);
        };

        // Close dropdown on outside click
        useEffect(() => {
            const handleClickOutside = (event) => {
                if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                    setIsDropdownOpen(false);
                }
            };

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, []);

        return (
            <div className='flex items-center justify-between w-full pb-4'>
                <h2 className='hidden md:block'>Clothing</h2>
                <button
                    onClick={toggleMobileFilterModal}
                    className='flex md:hidden items-center gap-3 border px-4 py-1 rounded-md'
                >
                    Filters
                    <FaCaretDown />
                </button>

                <div className='flex items-center gap-2 w-fit relative'>
                    <p>Sort By:</p>

                    <div ref={dropdownRef} className="relative w-fit">
                        <button
                            onClick={toggleDropdown}
                            className="flex items-center justify-between w-full h-12 px-4 py-1 bg-transparent cursor-pointer"
                        >
                            {sortBy === "relevance"
                                ? "Relevance"
                                : sortBy === "recent"
                                    ? "Most Recent"
                                    : sortBy === "price_asc"
                                        ? "Price: Low to High"
                                        : sortBy === "price_desc"
                                            ? "Price: High to Low"
                                            : "Most Popular"}
                            <RiExpandUpDownLine className="ml-2" />
                        </button>
                        {isDropdownOpen && (
                            <ul className="absolute top-14 right-0 min-w-44 w-full bg-white border shadow-md rounded-md z-10">
                                <li
                                    onClick={() => handleSortChange("relevance")}
                                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                >
                                    Relevance
                                </li>
                                <li
                                    onClick={() => handleSortChange("recent")}
                                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                >
                                    Most Recent
                                </li>
                                <li
                                    onClick={() => handleSortChange("price_asc")}
                                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                >
                                    Price: Low to High
                                </li>
                                <li
                                    onClick={() => handleSortChange("price_desc")}
                                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                >
                                    Price: High to Low
                                </li>
                                <li
                                    onClick={() => handleSortChange("popular")}
                                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                >
                                    Most Popular
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    if (loading) {
        return <div>Loading...</div>
    }

    if (!products) {
        return (
            <div>
                {error &&
                    <p>Sorry, an error occured loading data. Please reload page.</p>
                }
            </div>
        )
    } else return (
        <div className='w-[93%] pt-24 lg:w-4/5 products-page'>
            <div className='hidden md:flex items-center justify-between products-page-breadcrumb'>
                <div>
                    <Breadcrumb />
                </div>
                <div>
                    <HeroNav />
                </div>
            </div>

            <div className='flex md:hidden gap-2 w-full'>
                <div className='flex items-center justify-between heroCatsMenu' onClick={toggleModal}>
                    <IoMenu size='1.4rem' />
                </div>
                <div>
                    <Breadcrumb />
                </div>
            </div>

            <div className='flex sm:hidden pb-4 items-center h-full w-full'>
                <Search products={[]} />
            </div>

            {/* Filter Header */}
            <FilterHeader />
            <CategoriesModal isModalOpen={isModalOpen} toggleModal={toggleModal} />

            <div className='md:gap-6 products-page-container'>
                <div className=' md:flex w-0 md:w-[30%] h-full overflow-y-auto hideScrollbar'>
                    <FilterPane
                        categories={products}
                        onFilterChange={handleFilterChange}
                        showMobileFilterModal={showMobileFilterModal}
                        toggleMobileFilterModal={toggleMobileFilterModal}
                    />
                </div>
                <div className='grid grid-cols-2 w-full gap-4 md:grid-cols-3 mx-auto md:w-[70%]'>
                    {
                        filteredProducts.map((product) => {
                            return (
                                <ProductCard
                                    key={product.id}
                                    id={product.id}
                                    name={product.name}
                                    description={product.description}
                                    price={product.price}
                                    discount={product.discount}
                                    image={product.image[0]}
                                    category={product.category}
                                    rating={product.rating}
                                    reviews={product.reviews}
                                    showName={true}
                                    showDescription={false}
                                    showPrice={true}
                                    showDiscount={false}
                                    showDiscountPercentage={false}
                                    showRating={false}
                                    showReview={false}
                                    showCategory={false}
                                    showSeller={false}
                                    showButton={false}
                                    showCartIcon={true}
                                    showHeartIcon={true}
                                    className='product-page-card'
                                />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )

}

export default Products;